export const environment = {
  logo:"https://rgcdynamics-logos.s3.ap-south-1.amazonaws.com/ahalan-logo.svg",
  discriptiion:"Top brands for Phones and Electronics. Latest trends in Fashion. Free Shipping available* No Cost EMI Available. Great Offers. Huge Selection. Easy & Fast Delivery. Low Prices. Best Deals. Top Brands",
  author:"RGC Dynamics",
  keyword:`marketplace, onlineshope, shope, shopee, offer, lowprice,best ecommerce website in uae, best ecommerce sites in uae, best ecommerce website name
  ,top ecommerce website
  ,top 10 ecommerce website
  ,most popular ecommerce site in uae
  ,most exclusive ecommerce website in uae
  ,most successful ecommerce websites in uae
  ,most faimous ecommerce websites in uae
  ,number one ecommerce website in uae
  ,best offer providing ecommerce websites in uae
  ,ecommerce website list in the world
  ,best online ecommerce websites
  ,best online shoping sites in uae
  ,best online market place in uae
  ,best online shopping app in uae
  ,best online store website
  ,top offfer ecommerce websites"`,
  specialGroups:"groceries",
  title:"Hilal Cart",
  production: true,
  api_rating: "https://api-rating-review-uat.ahlancart.com",
  api_user: "https://api-user-uat.ahlancart.com",
  api_inventory: "https://api-inventory-uat.ahlancart.com",
  api_cart_checkout: "https://api-cart-checkout-uat.ahlancart.com",
  api_design_layout: "https://api-design-layout-uat.ahlancart.com",
  api_promotion: "https://api-promotion-uat.ahlancart.com",
  api_payment: "https://api-payments-uat.ahlancart.com",
  api_partner: "https://api-newpartner-uat.ahlancart.com",
  api_system_arch: "https://api-system-archi-uat.ahlancart.com",
  footer_credit_text:"hilalcart.com",
  footer_credit_link : "https://ahlancart.com/#/",
  social_media:{
    facebook:"https://www.facebook.com/profile.php?id=100077601077533",
    instagram:"https://www.instagram.com/ahlan_cart/",
    twiter:"",
    linkedin:"https://www.linkedin.com/in/ahlan-cart-31b971230/",
    youtube:""
  },
  apps:{
    playstore:"https://play.google.com/store/apps/details?id=com.rgcahlancart",
    appstore:""
  }
};
