import { Injectable, OnInit } from '@angular/core';
import { MessengerService } from './messenger.service';
import { ToastrService } from 'ngx-toastr';
import { Cart } from '../models/cart';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WishlistService implements OnInit {
  getRefreshData() {
    throw new Error('Method not implemented.');
  }
  api_cart_checkout = environment.api_cart_checkout
  wishlist:Cart[]=[]
  wishlistCreate = "/wishlist/create-wish-list"
  wishlistGet = "/wishlist/list-wish-list-by-customer/"
  wishistGetWithoutGroup = "/wishlist/list-wish-list-by-customer-without-group/"
  checkWishlist="/wishlist/check-whishlist-customer/"
  wishlistData:any = []
  wishwithoutgroup:any 
  constructor(
    private messageService: MessengerService,
    private toastr: ToastrService,
    private http: HttpClient,
  ){
    this.getWishListData()
  }
  ngOnInit(): void {

  }
  getWishListData(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    if(userDetails == null) return
    let wish = this.http.get<any>(this.api_cart_checkout+this.wishistGetWithoutGroup+userDetails.customer_id)
    wish.subscribe((data:any)=>{
      this.wishwithoutgroup = data.data
    })
  }
  getWithoutData(){
    return this.wishwithoutgroup
  }
  getWishList(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    
    return this.http.get<any>(this.api_cart_checkout+this.wishlistGet+userDetails.customer_id)
  }
  getCheckWishList(id:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    
    return this.http.get<any>(this.api_cart_checkout+this.checkWishlist+userDetails?.customer_usercode+'/'+id)
  }
  addToWishList(id:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userData = JSON.parse(user)
    let wishData = {
      variant_id : id, 
      customer_id : userData.customer_id, 
      customer_code : userData.customer_usercode, 
      group_id :null
    }
    this.http.post(this.api_cart_checkout+this.wishlistCreate,wishData).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        this.toastr.success(data.message)
        this.messageService.dontRefresh()
      }
    })
  }
  
}
