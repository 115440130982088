<div class="container px-md-0 p-lg-5">
  <div class="row m-0">
    <!-- <form action=""> -->
    <h2 class="text-center mb-2"><b>Enter OTP</b></h2>
    <p class="text-center w-75 mx-auto fz-14">
        Enter the verification code sent to you
    </p>
    <div class="mx-auto col-md-6 col-sm-12 login ps-md-0 px-lg-5 mt-lg-3 mt-0">
      <!-- Email input -->
      <div class="form-outline mb-4 w-100 position-relative">
          <input type="text" class="form-control"name='email' placeholder="----" [(ngModel)]="otpNumber"/>
          <button class="resend-otp" title="Resend Otp" *ngIf="!checkRoute(otpRoute.name)">
            <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
            <i class="ms-2 fw-14 fw-bold" (click)="resendOtpNew()" *ngIf="subscribeTimer ==0">Resend</i>
          </button>
          <button class="resend-otp" title="Resend Otp" *ngIf="checkRoute(otpRoute.name)" >
            <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
            <i class="ms-2 fw-14 fw-bold" (click)="resendOtpForgot()" *ngIf="subscribeTimer ==0">Resend </i>
          </button>
      </div>
      <div class=" mb-2 pb-1">
        <button class="btn btn-primary mb-3 w-100 d-flex justify-content-center" *ngIf="!checkRoute(otpRoute.name)" type="submit" (click)="submitOtp()">
          CONFIRM OTP
        </button>
        <button class="btn btn-primary mb-3 w-100 d-flex justify-content-center" *ngIf="checkRoute(otpRoute.name)" type="submit" (click)="submitOtpForForgot()">
          CONFIRM OTP
        </button>
      </div>
    </div>
    <!-- </form> -->
    <a [routerLink]="['/auth']" routerLinkActive="router-link-active"  class="text-center mb-4 fz-14" style="color: black;">Back to <i style="color: #086db5;">LOGIN</i></a>
  </div>
</div>