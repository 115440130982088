<div class="card deals mx-2 position-relative">
  <div class=" flex-wrap d-flex justify-content-end m-3">
    <div class="d-flex">
      <i 
          class="bi pointer fz-18 text-bulk" 
          [ngClass]="wishListed === true ? 'bi-heart-fill' : 'bi-heart'"
          (click)="changeWishlist(products)"
      ></i>
    </div>    
  </div>
  <div class="product-offer-bulk bulk-bg">
    x10
  </div>
  <img [src]="products.image1" [alt]="products.display_name" class="card-img-top mx-auto" style="width: 150px;" (click)="navigateProductPage(products.id)">
  <div class="card-body">
    <span class="fw-500 product-cardtwo-name mb-2 text-dark text-center">{{products.display_name}}</span>
    <select class="form-select qty-drop bg-gray-200 border-0 fz-13 mb-2" aria-label="Default select example">
      <option selected >Qty 12</option>
      <option value="1">Qty 24</option>
      <option value="2">Qty 36</option>
      <option value="3">Qty 48</option>
    </select>
    <div class="mb-3 product-card-price d-block text-center">
        <span class="card-text fw-bold fz-13 me-2 text-dark"><b>{{ products.selling_price | currency : 'AED' }}</b>
        </span>
        <span class="fz-13">
            <s> {{products.selling_price | currency: 'AED'}}</s><br >
        </span>
    </div>
    <div class="w-100">
      <button class="btn btn-primary mx-auto d-block" (click)="addCart(products)">
        ADD TO CART
      </button>
    </div>
  </div>
</div>