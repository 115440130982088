<div class="container px-md-0 px-lg-5 py-5">
    <div class="row m-0 pt-2">
        <!-- <form action=""> -->
            <h2 class="text-center mb-0"><b>Welcome Back</b></h2>
            <p class="text-center w-75 mx-auto fz-14"> 
                Sign into your account 
            </p>
            <div class="col-md-6 col-sm-12 login ps-md-0 ps-lg-5 pe-lg-5 pb-lg-5 pt-lg-2 mt-0  mt-0 mx-auto">
                <form [formGroup]="loginForm" (ngSubmit)="OnLoginSubmit()" autocomplete="off">
                    <!-- Email input -->
                    <div class="form-outline mb-3 w-100">
                        <input type="email" 
                            class="form-control"
                            name='ahlanCartEmail' 
                            placeholder="Email address" 
                            formControlName="email" autocomplete="email" autocomplete="false"/>
                    </div>

                    <!-- Password input -->
                    <div class="form-outline  w-100 position-relative">
                        <input [type]="passwordFeild" class="form-control" name="ahlanCartPass" autocomplete="false" placeholder="Password" formControlName="password" autocomplete="password" />
                        <i (mouseover)="passwordFeild = 'text'" (mouseleave)="passwordFeild = 'password'" (click)="showPassword()"  class="show-password bi bi-eye-fill"></i>
                    </div>
                    <div class="d-flex justify-content-end mt-2 mb-2">
                        <a [routerLink]="['/auth/forgot_password']" routerLinkActive="router-link-active"  class="text-center mb-4 fz-14" style="color: black;">Forgot Password?</a>
                    </div>
                    <div class="pb-1 mb-3">
                        <button class="btn btn-primary mb-0 w-100" [disabled]="loginForm.invalid" type="submit">
                            LOGIN
                        </button>
                        <div class="d-flex justify-content-center mt-4">
                            <span class="fz-14 ">New to AhlanCart </span>
                            <span class="fz-14 ms-2 signUp" [routerLink]="['/auth/signup']" routerLinkActive="router-link-active" href="#!" role="button">SIGN UP</span>
                        </div>
                    </div>
                    <!-- <div class="form-outline mb-2">

                        <button class="btn  btn-lg btn-block w-100" [routerLink]="['/auth/signup']" routerLinkActive="router-link-active"  style="border-color: black; text-align: left"  href="#!" role="button">
                            <i class="bi bi-box-arrow-in-right me-3"></i>Signup here
                        </button>
                    </div> -->
                </form>
            </div>
            <div class="col-md-6 col-sm-12 pe-md-0 ps-lg-5 pe-lg-5 pb-lg-5 pt-lg-2 mt-0 mt-lg-3 mt-0 d-none">
                <div class="form-outline mb-4">
                    <button class="btn  btn-lg btn-block w-100" style="border-color: black; text-align: left"  href="#!" role="button">
                        <i class="bi bi-google me-3"></i>Sign in with Google
                    </button>
                </div>
                <div class="form-outline mb-4">
                    <button class="btn  btn-lg btn-block w-100" style="border-color: black; text-align: left"  href="#!" role="button">
                        <i class="bi bi-facebook me-3"></i>Sign in with Facebook
                    </button>
                </div>
                <div class="form-outline mb-4">
                    <button class="btn  btn-lg btn-block w-100" [routerLink]="['/auth/signup']" routerLinkActive="router-link-active"  style="border-color: black; text-align: left"  href="#!" role="button">
                        <i class="bi bi-box-arrow-in-right me-3"></i>Signup here
                    </button>
                </div>
            </div>
        <!-- </form> -->
    </div>
</div>