import { Component,AfterViewInit, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { AuthService } from 'src/app/services/auth.service';
import { OrderService } from 'src/app/services/order.service';
import { CouponsService } from 'src/app/services/coupons.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  ActivatedRoute
} from '@angular/router';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  mode="card"
  showProductLess = 2
  currency = 'AED'
  originalPrice=""
  discount=0
  couponDiscount :any
  discountAmount=""
  couponCode=""
  isFree = false
  loader = false
  cardName = ""
  cardNumberValid = ""
  cardYearExValid= ""
  cvvValid = ""
  cardNumber =""
  cardExMonth:any
  cardExYear:any
  cardCVV:any
  checkoutArray:any[] = []
  userDetailAdress:any
  order:any
  orderSlot:any
  orderSlotId:any
  orderSlotDate:any
  totalCart:any=0
  savedTotal=0
  deliveryCharge:any = 0
  totalWithDelivery:any
  deliver_notes:any
  constructor(private router:Router,private toaster: ToastrService,private _coupon:CouponsService,private route: ActivatedRoute,private orderService:OrderService,private cartItems: CartService,private msg:MessengerService,private auth:AuthService) { 
    
    this.msg.getAddress().subscribe((data:any)=>{
      this.userDetailAdress = data.customer_user_data
      
    }) 
    this.msg.getFailedData().subscribe((data:any)=>{
      this.loader = false
      this.toaster.warning(data.message)
      return
    })
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    console.log(userDetails);
    console.log(this.order,"____  order  ____");
    console.log(this.order?.id,'%_______%');
    this.order = this.orderService.getOrderResponse()
    this.totalWithDelivery=this.order?.total_amount
    console.log(this.totalWithDelivery,"this.order");
    // this.orderService.deliveryPrice(this.order?.id,userDetails.customer_usercode,this.orderSlotId).subscribe((data:any)=>{
    //   console.log(data,"_______ this is deliverydata");
    //   this.deliveryCharge = data.data?.delivery?.delivery_charge
    //   this.totalWithDelivery = (this.totalCart + this.deliveryCharge) - this.discount
    //   this.deliver_notes=data.data?.order_lines[0]?.order_lines_meta?.expected_date?.delivery_notes
    //   console.log(this.deliver_notes,"this.deliver_notes");
      
    // })
    this.orderService.order_data(this.order?.id,userDetails.customer_usercode).subscribe((data:any)=>{
      console.log(data.data,"order-data**************");
      this.deliver_notes=data.data?.order_lines[0]?.order_lines_meta?.expected_date?.delivery_notes
    })
    this.orderService.orderSlot(this.order?.delivery_address_id,this.order?.id).subscribe((data:any)=>{
      this.orderSlot = data.data?.results
      console.log(this.orderSlot,"orderSlot");
      // this.orderSlotId = this.orderSlot[0]?.id
      //   this.deliveryCharge = this.orderSlot[0]?.delivery_charge
      //   this.totalWithDelivery = (this.totalCart + this.deliveryCharge) - this.discount
      if(this.orderSlot.length!=0){
        this.orderSlotId = this.orderSlot[0]?.id
        this.deliveryCharge = this.orderSlot[0]?.delivery_charge
        this.totalWithDelivery = (this.totalCart + this.deliveryCharge) - this.discount
      }
      
    })
    
    this.order = this.orderService.getOrderResponse()
    console.log(this.order,"this.order");
    
    this.msg.processComplete().subscribe((data:any)=>{
      this.loader = data
    })
    
    if(this.orderSlot!=undefined){
      this.orderService.deliveryPrice(this.order?.id,userDetails.customer_usercode,this.orderSlotId).subscribe((data:any)=>{
        console.log(data,"_______ this is deliverydata");
        this.deliveryCharge = data.data?.delivery?.delivery_charge
        this.totalWithDelivery = (this.totalCart + this.deliveryCharge) - this.discount
        this.deliver_notes=data.data?.order_lines[0]?.order_lines_meta?.expected_date?.delivery_notes
        console.log(this.deliver_notes,"this.deliver_notes")
      })
    }
  
  }
  viewAll(){
    this.showProductLess = this.checkoutArray?.length
  }
  viewLess(){
    this.showProductLess = 2
  }
  ngAfterViewInit(){
    window.scroll(0,0)
  }
  ngOnInit(): void {
    
    setTimeout( () => { 
      this.order = this.orderService.getOrderResponse()
      if(this.checkoutArray.length==0){
        this.router.navigate(['/cart'])
      }
      console.log(this.order);
      
    }, 1000);
    this.userDetailAdress = this.auth.getProperAddress()
    this.route.params.subscribe(() => {
      this.msg.getAddress().subscribe((data:any)=>{
        data.customer_user_data.filter((data:any)=>{
          if(data.is_default){
            this.userDetailAdress = data
          }
        })
      })
      this.checkoutArray = this.cartItems.getCheckoutData()
      this.checkoutArray.forEach((data:any) => {
        this.totalCart += data.total_price 
        this.totalWithDelivery = this.totalCart
        // this.totalCart = this.totalCart.toFixed(1)
      })
    });
  }

  changeSlot(id:any,deliveryCharge:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user) 
    this.orderSlotId = id
    console.log(this.orderSlotId);
    
    this.deliveryCharge = deliveryCharge
    this.totalWithDelivery = (this.totalCart + this.deliveryCharge) - this.discount
    if(this.orderSlotId!=null){
      this.orderService.deliveryPrice(this.order.id,userDetails.customer_usercode,this.orderSlotId).subscribe((data:any)=>{
        console.log(data,"_______ this is deliverydata");
        this.deliveryCharge = data.data.delivery.delivery_charge
        this.totalWithDelivery = (this.totalCart + this.deliveryCharge) - this.discount
        })
    }
   
  }
  changeMonth(){
    if(this.cardExMonth>12){
      this.cardExMonth = 12
    }
  }
  applyCoupon(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user) 
    console.log(userDetails,"userDetails");
    
    if(userDetails == null)return
    let coupon = {
      user_code:userDetails.customer_usercode,
      price:this.totalCart ,  
      code: this.couponCode,  
    }
    this._coupon.applyCoupon(coupon)
    this.msg.getCoupons().subscribe((data:any)=>{
      console.log(data);
      this.totalWithDelivery=data.new_price
      console.log(this.totalWithDelivery,"this.totalWithDelivery");
      
      // if(data!=null){
      //   this.discountAmount = data?.new_price
      //   this.originalPrice = data?.new_price
      //   this.discount = JSON.parse(this.originalPrice) - JSON.parse(this.discountAmount)
      //   this.totalWithDelivery = (this.totalCart + this.deliveryCharge) - this.discount
      // }
    })
    
  }
  onyearchange(){
    if(this.cardExYear < 21){
      this.cardYearExValid = "Card Expired"
    }else{
      this.cardYearExValid = ""
    }
  }
  
  cardNumberChange(){
    let trimedValue = this.cardNumber.replace(/\s+/g,'')
    if(!trimedValue.match(/^\d+$/)){
      this.cardNumberValid = "please enter number"
    }else{
      this.cardNumberValid = ""
    }
    let d:any = trimedValue.match(/.{1,4}/g)
    this.cardNumber = d.join(' ')
    console.log(this.cardNumber);
    
  }
  payNow(){
    if(this.mode == "cod"){
      this.codDelivery()
    }else{
      // let trimedValue = this.cardNumber.replace(/\s+/g,'')
      // if(this.cardExYear < 22 || !trimedValue.match(/^\d+$/))return
      // let month = ''
      // if(this.cardExMonth < 10){
      //   month = '0'+this.cardExMonth
      // }else{
      //   month = ''+this.cardExMonth+''
      // }
      let user:any = localStorage.getItem('marketplaceUser')
      let userData = JSON.parse(user)
      let user_name = userData.username
      let userName = user_name.split(' ') 
      // let paymentByCard = {
      //   user:userData.customer_id,
      //   user_code:userData.customer_usercode,
      //   order:this.order.id,
      //   billing_info: {
      //     action:"SALE",
      //     amount:{
      //       currencyCode:"AED",
      //       value:this.totalWithDelivery
      //     },
      //     emailAddress:userData.email_id,
      //     billingAddress:{
      //       firstName: userName[0],
      //       lastName: userName[1]
      //     }
      //   },
      //   payment_info:{
      //     pan: this.cardNumber.replace(/\s+/g,''),
      //     expiry: '20'+this.cardExYear+'-'+month,
      //     cvv: this.cardCVV,
      //     cardholderName: this.cardName
      //   }
      // } 
      // this.loader = true
      // this.orderService.orderPayment(paymentByCard)
      let amount={
        value:this.totalWithDelivery
      }
      let newPayment={
        user:userData.customer_id,
        order:this.order.id.toString(),
        redirect_url:"https://ahlancart.com/#/order-success",
        amount:amount
    }
    this.orderService.newOrderPayment(newPayment)
    }
  }
  codDelivery(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userData = JSON.parse(user)
    let user_name = userData.username
    let userName = user_name.split(' ') 
    let cod ={
      user:userData.customer_id,
      user_code:userData.customer_usercode,
      order:this.order.id,
      billing_info: {
        action:"SALE",
        amount:{
          currencyCode:"AED",
          value:this.totalWithDelivery
        },
        emailAddress:userData.email_id,
        billingAddress:{
          firstName: userName[0],
          lastName: userName[1]
        }
      },
      payment_info:{
        user_name: "RGC User 3"
      }
    }
    this.loader = true
    this.orderService.orderPaymentCod(cod)
  }
  changeMode(mode:any){
    this.mode = mode
  }
}
