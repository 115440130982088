import { Component, OnInit } from '@angular/core';
import { MessengerService } from 'src/app/services/messenger.service';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  productRoute= {name:String};
  next:any
  totalCount =0
  pageCount:any = []
  linkToPost = ""
  activePage = 1
  searchResultList:any[] =[]
  searchBackResultList:any[] =[]
  searchValue = ""
  filter = "all"

  constructor(private http:HttpClient,private route: ActivatedRoute,private categoryService:CategoryService,private messageService: MessengerService,private _productService: ProductService,) { 
    this.messageService.getsearchValue().subscribe((data:any)=>{
      // this.ngOnInit()
      this.pageCount = []
      this.totalCount = this.next.count/26
      this.totalCount = Math.ceil(this.totalCount)
      for(let i=0; i<this.totalCount; i++){
        this.pageCount.push(i+1)
      }
      this.productRoute ={
        name: this.route.snapshot.params['name'],
      }
      let demo:any = this.productRoute?.name
      console.log(demo,"demodemodemo");
      // setTimeout(() => {
      //   this.ngOnInit()
      // }, 2000);
    })
  }

  ngOnInit(): void { 
    this.pageCount = []
    this.searchValue = ""
    this.route.params.subscribe((data:any)=>{
      this.searchValue = ""
      this.productRoute ={
        name: this.route.snapshot.params['name'],
      }
      let demo:any = this.productRoute?.name
      console.log(demo,"demodemodemo");
      let split = demo.split('_')
      console.log(split,"split");
      
      let categoryCode = split[0]
      let searchData = split[0]
      let searchValueSplit = searchData.split('%20')
      console.log(searchValueSplit,"searchValueSplit");
      searchValueSplit.filter((data:any)=>{
        let word = data+' '
        this.searchValue = this.searchValue+word 
        console.log(this.searchValue);
        
      })
      this.categoryService.getSearchProduct(data.name).subscribe((data:any)=>{
        this.searchResultList = data?.data?.results
        console.log(this.searchResultList,"this.searchResultList");
        
        this.searchBackResultList = data?.data?.results
        this.next=data.data.next
      }) 
    })
     
  }

  changeFilter(){
    if(this.filter == 'low-high'){
      this.searchResultList = this.searchResultList.sort((a, b) => parseFloat(a.selling_price) - parseFloat(b.selling_price));
    }
    if(this.filter == 'high-low'){
      this.searchResultList = this.searchResultList.sort((a, b) => parseFloat(b.selling_price) - parseFloat(a.selling_price));
    }
    if(this.filter == 'all'){
      this.searchResultList = this.searchBackResultList
    }
  }
  addWishList(product: any) {
    this.messageService.RequestAddToWishList(product)
  }

  loadMore(next:any){
    this.http.get<any>(next).subscribe((data:any)=>{
      let products = data?.data?.results
      products.filter((data:any)=>{
        this.searchResultList.push(data)
      })
      this.next = data?.data.next
      if(data?.data.next === null){
        this.next = null
      }
    })
  }

  NextCahange(next:any){
    console.log(next,"any")
    this.http.get<any>(next).subscribe((data:any)=>{
      this.searchResultList=data?.data?.results
      this.next=data?.data?.next
    })
  }
  
  Previous(previous:any){  
    this.http.get<any>(previous).subscribe((data:any)=>{
      this.searchResultList=data?.data?.results
      this.next=data?.data
      let split = this.next.next.split('=')  
      let pagecout = split[1].split('&')      
      this.activePage = pagecout[0] - 1
    })  
  }

  getThisPage(page:any){
    let splitGetLink = this.next.next.split('&') 
    this.http.get<any>(this.linkToPost+'='+page+'&'+splitGetLink[1]).subscribe((data:any)=>{
      this.searchResultList=data?.data?.results
      this.next=data?.data
      let split = this.next.next.split('=')  
      let pagecout = split[1].split('&')      
      this.activePage = pagecout[0] - 1
    }) 
  }
}
