<div class="container-fluid p-3 mt-2 category-page">
    <div class="row gx-3 category-page">
      <div class="col category-body px-0">
        <div class="h-100 w-100 bg-white py-3">
            <div class="d-flex d-flex w-100">
                <div class="fz-18 fw-bold pb-3 px-3 w-100 d-flex flex-wrap align-items-center justify-content-between">
                    <label for=""><span class="text-uppercase">{{searchValue}} </span>({{searchResultList?.length}} Results)</label>
                    <label class="d-flex align-items-center" for="">
                        <label class="fz-15 w-110px me-3">Sort By:</label>
                        <select class="form-select bg-gray-200 border-0" aria-label="Default select example" [(ngModel)]="filter" (change)="changeFilter()">
                            <option value="all">All</option>
                            <option value="low-high">Price low to high</option>
                            <option value="high-low">Price high to low</option>
                        </select>
                    </label>
                </div>
            </div>
            <div class="container-fluid">
                <div class="row gx-2">
                    <div class="w-100 text-center mt-4" *ngIf="searchResultList?.length === 0">
                        <img src="../../../assets/assets/buyitagain.png" alt="" style="width:200px">
                        <div class="w-100 text-center my-4">
                            No product in this category!
                            <p class="fz-13 fw-normal mt-3">
                                Please come again later.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4 col-6 mb-3" *ngFor="let product of searchResultList">
                        <app-product-card-two
                        [products]="product" 
                        ></app-product-card-two>
                    </div>
                    <div class="w-100 my-5 d-flex align-itemcenter justify-content-center" *ngIf="searchResultList?.length != 0">
                      <button class="btn btn-light" *ngIf="next" (click)="loadMore(next)">
                        Load More
                      </button>
                    </div>
                    <!-- <div class="pagination-block d-flex justify-content-center align-items-center p-0 m-0 mt-5 mb-4" *ngIf="pageCount.length >0">
                        <nav aria-label="Page navigation example mb-0">
                          <ul class="pagination mb-0">
                            <li class="page-item">
                              <a class="page-link"aria-label="Previous" (click)="Previous(next?.previous)">
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            <li class="page-item" *ngFor="let page of pageCount | slice:0:10" [ngClass]="page == activePage ? 'active': ''"><a class="page-link" (click)="getThisPage(page)">{{page}}</a></li>
                            <li class="page-item" *ngIf="pageCount.length > 10"><a class="page-link">...</a></li>
                            <li class="page-item">
                              <a class="page-link" aria-label="Next" (click)="NextCahange(next?.next)">
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                        <span class="ms-3 fz-13">( {{next.count}} products )</span>
                    </div> -->
                </div>
            </div>
        </div>  
      </div>
    </div>
</div>