import { Component, OnInit, Input } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { Banner, BannerCategory } from 'src/app/models/banner';
import { Router } from '@angular/router';
@Component({
  selector: 'app-essential',
  templateUrl: './essential.component.html',
  styleUrls: ['./essential.component.scss']
})
export class EssentialComponent implements OnInit {

  count = 9
   
  @Input() specialGroups = "groceries"
  productList:any=[]
  popular_category:any = []
  mainSlot_3:any = []
  mainSlot_4:any = []
  mainSlot_5:any = []
  center_slot:any = []
  mainSlot_6:any = []
  mainSlot_7:any = []
  mainSlot_8:any = []
  mainSlot_9_title:any = ""
  mainSlot_9:Banner[] = []
  mainSlot_10:any = []
  bannerList:any = []
  deals:any=[]

  addType_5 = [
    { 
      src:'../../../assets/assets/offer.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/offer1.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/offer.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/offer1.png',
      alt:'demo'

    },
    { 
      src:'../../../assets/assets/offer1.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/offer.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/offer1.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/offer.png',
      alt:'demo'
    },
  ]
  addType_2 = [
    { 
      src:'../../../assets/assets/add-12.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/add-12.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/add-12.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/add-12.png',
      alt:'demo'
    },
  ]
  addType_1 = [
    { 
      src:'../../../assets/assets/add-13.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/add-13.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/add-13.png',
      alt:'demo'
    },
    { 
      src:'../../../assets/assets/add-13.png',
      alt:'demo'
    },
  ]
  type_1="1"
  type_2="2"
  type_3="3"
  type_4="4"
  type_5="5"
  divisionLink="/division/"
  groupLink="/productGroup/"
  constructor(private _router:Router,private _productService: ProductService, private _messageService:MessengerService) {
    this._messageService.getSegments().subscribe((data:any)=>{  
      this.callApi(data)
      
    })
  }

  ngOnInit(): void {
    this.bannerList = this._productService.getHomeBanner()

    // this.popular_category = this._productService.getPopularCategory()
    this.callApi(this.specialGroups)   
    // this.personal_store = this._productService.getPersonalStore()
    
    
  }
  callApi(specialGroup:any){
    this._productService.getHomeMainSlotOne(specialGroup).subscribe((data:any)=>{
      this.popular_category = data?.data
      console.log(this.popular_category,"this.popular_category");
      
      this.popular_category.products = this.popular_category.products.sort((a:any, b:any) => parseFloat(a.Priorty) - parseFloat(b.Priorty))
    })
   
    this._productService.getDeals(specialGroup).subscribe((data:any)=>{
      this.deals = data?.data.banner
    })
    this._productService.getHomeMainSlotThree(specialGroup).subscribe((data:any)=>{
      this.mainSlot_3 = data?.data
      console.log(this.mainSlot_3);
    })   
    this._productService.getHomeMainSlotFour(specialGroup).subscribe((data:any)=>{
      this.mainSlot_4 = data?.data
      console.log(this.mainSlot_4);
      
    })  
    this._productService.getHomeMainSlotFive(specialGroup).subscribe((data:any)=>{
      this.mainSlot_5 = data?.data
      console.log(this.mainSlot_5);
    }) 
    this._productService.getHomeCenterSlot(specialGroup).subscribe((data:any)=>{
      this.center_slot = data?.data
      console.log(this.center_slot);
    })   
    this._productService.getHomeMainSlotSix(specialGroup).subscribe((data:any)=>{
      this.mainSlot_6 = data?.data
    })    
    this._productService.getHomeMainSlotSeven(specialGroup).subscribe((data:any)=>{
      this.mainSlot_7 = data?.data
    })  
    this._productService.getHomeMainSlotEight(specialGroup).subscribe((data:any)=>{
      this.mainSlot_8 = data?.data
    })  
    this._productService.getHomeMainSlotNine(specialGroup).subscribe((data:any)=>{
      this.mainSlot_9 = <Banner[]>data.data.banner
      this.mainSlot_9_title = data.data.Display_name
    })  
    this._productService.getHomeMainSlotSeven(specialGroup).subscribe((data:any)=>{
      this.mainSlot_7 = data?.data
    })  
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    if(userData != null){
      this._productService.getHomeMainSlotTen(specialGroup,userData.customer_usercode).subscribe((data:any)=>{
        this.mainSlot_10 = data?.data
      }) 
    }else{
      this._productService.getHomeMainSlotTen(specialGroup,false).subscribe((data:any)=>{
        this.mainSlot_10 = data?.data
      }) 
    }
  }

  routeTo(type:string,name:string, id:number, section:any, code:any){
    if(type === "Category"){
      // this._router.navigate(['division/'+ name+'_$_'+ code+'_$_'+section+'_$_'+id+'_$_'+'category'])
      return `#${this.divisionLink}${name}_$_${code}_$_${section}_$_${id}_$_category`
    }else{
      if(type == 'Category'){
        // this._router.navigate(['productGroup/'+code+'_$_'+ name +'_$_'+section+'_$_'+id+'_$_'+'category'])
        return `#${this.groupLink}${code}_$_${name}_$_${section}_$_${id}_$_category`
      }else{
        // this._router.navigate(['productGroup/'+code+'_$_'+ name +'_$_'+section+'_$_'+id+'_$_'+'group'])
        return `#${this.groupLink}${code}_$_${name}_$_${section}_$_${id}_$_group`
      }
    }
  }

}
