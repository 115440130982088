<!-- Banner -->
<div class="container-fluid d-flex p-0 w-100 banner">
    <div id="carouselExampleControls" class="carousel slide w-100" data-bs-ride="carousel">
        <div class="carousel-inner w-100">
            <div class="carousel-item" *ngFor="let banner of bannerList; let i = index" [ngClass]="{active: i==0}">
               <a class="m-0 p-0" [href]="routeTo(banner.redirect_type,banner.redirecturl,banner.redirect_id,banner.section_type,banner.redirect_code,banner.redirect_type)">
                   <img [src]="banner.image" class="d-block w-100 object-fit_fill banner-img" [alt]="banner.route_type">
               </a>
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon cursol_btn" aria-hidden="false"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon cursol_btn" aria-hidden="false"></span>
            <span class="visually-hidden">Next</span>
        </button>
        <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleControls" *ngFor="let banner of bannerList; let i = index" [attr.data-bs-slide-to]="i" [ngClass]="{active: i==0}" aria-current="true" aria-label="Slide 1"></button>
        </div>
    </div>
</div>
