<!-- Section -->
    <div class="container-fluid py-3 p-4 mt-3 bg-white">
        <h4 class="fw-500 min-fit-width col-12 mb-3 ms-2 ms-md-0" *ngIf="review.length !=0">Rating & Reviews</h4>
        <div class="row">
            <div class="col-5">
                <!-- <div class="w-100 text-center mt-4" *ngIf="review.length !=0">
                    <img src="../../../../assets/assets/nodata.png" alt="" style="width:100px">
                    <div class="w-100 text-center mt-4">
                      no reviews yet 
                    </div>
                </div> -->
                <div class="mb-3" *ngIf="this.isReviewable">
                    <label for="headline" class="form-label fz-14">Headline</label>
                    <input type="text" class="form-control" id="headline" [(ngModel)]="headline" placeholder="">
                </div>
                <div class="mb-3" *ngIf="this.isReviewable">
                    <label for="exampleFormControlTextarea1" class="form-label fz-14">Review writing</label>
                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" [(ngModel)]="reviewComment"></textarea>
                </div>
                <div class="d-flex addimg-block" *ngIf="this.isReviewable">
                    <div class="addimg-block__block" *ngFor="let item of imageArray;let i = index">
                        <input type="file" name="image1" [(ngModel)]="item.image1" (change)="onUploadImageOne($event,item.image,i)">
                        <img *ngIf="item.image1 !=''" [src]="item.image" alt="">
                        <i *ngIf="item.image1 ==''" class="bi bi-plus-lg"></i>
                    </div>
                </div>
                <button class="btn btn-primary mt-4 px-4" *ngIf="this.isReviewable" (click)="createPost()">
                    post
                </button>
                <!-- custamer review -->
                <!-- <h4 class="fw-500 min-fit-width col-12 mb-3 mt-5 ms-2 ms-md-0 pb-2 border-bottom">Customer review ({{review.length}})</h4> -->
                <div class="d-flex flex-wrap justify-content-between mb-5" *ngFor="let item of review">
                    <div class="fit-content">
                        <label class="fw-bold fz-13 me-2" for="">{{item.title |titlecase}}</label>
                        <label for="" class="fz-14">{{item.created_at}}</label>
                    </div>
                    <!-- <div class="fit-content">
                        <div class="star-rating">
                            <i class="bi small" [ngClass]="item.customer_rating >= 1 ? 'bi-star-fill' : 'bi-star'"></i>
                            <i class="bi small" [ngClass]="item.customer_rating == 1.5 ? 'bi-star-half' : 'd-none'"></i>
                            <i class="bi small" [ngClass]="item.customer_rating >= 2 ? 'bi-star-fill' : 'bi-star'"></i>
                            <i class="bi small" [ngClass]="item.customer_rating == 2.5 ? 'bi-star-half' : 'd-none'"></i>
                            <i class="bi small" [ngClass]="item.customer_rating >= 3 ? 'bi-star-fill' : 'bi-star'"></i>
                            <i class="bi small" [ngClass]="item.customer_rating == 3.5 ? 'bi-star-half' : 'd-none'"></i>
                            <i class="bi small" [ngClass]="item.customer_rating >= 4 ? 'bi-star-fill' : 'bi-star'"></i>
                            <i class="bi small" [ngClass]="item.customer_rating == 4.5 ? 'bi-star-half' : 'd-none'"></i>
                            <i class="bi small" [ngClass]="item.customer_rating >= 5 ? 'bi-star-fill' : 'bi-star'"></i>
                        </div>
                    </div> -->
                    <div class="w-100">
                        <label for="" class="fz-12 ps-2 pt-3 ">{{item.review}}</label>
                    </div>
                    <div class="col-12 mt-3 d-flex flex-wrap justify-content-between align-items-center">
                        <!-- <div class="fit-content d-flex align-items-center">
                            <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                            <label class="fz-15 ms-2" for="">{{item?.customer_details?.f_name}}</label>
                        </div> -->
                        <!-- <div class="fit-content d-flex align-items-center">
                            <span class="material-icons-outlined fz-15">
                                thumb_up
                            </span>
                            <span class="fz-14 me-3 ms-1">{{item.no_likes}}</span>
                            <span class="material-icons-outlined fz-15">
                                thumb_down
                            </span>
                            <span class="fz-14 me-3 ms-1">{{item.no_dislikes}}</span>
                            <span class="fz-12">Report</span>
                        </div> -->
                    </div>
                </div>
                <div class="w-100 mb-4 d-none">
                    <a href="" class="fz-14 w-100">More details</a>
                </div>
                <!-- custamer review -->
                <h4 class="d-none fw-500 min-fit-width col-12 mb-3 ms-2 ms-md-0">Discussions? (12,117)</h4>
                <input type="text" class="d-none form-control fz-13" placeholder="Have a question?">
                
                <div class="d-none d-flex flex-wrap mt-4">
                    <label for="" class="w-100 fw-bold fz-14">
                        Question : Does it have both input and output display ? In some models as i know you dont have
                    </label>
                    <div class="d-flex align-items-center mt-2">
                        <div class="fit-content d-flex align-items-center border-end pe-3 me-3 ">
                            <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                            <label class="fz-15 ms-2" for="">Amar Salih</label>
                        </div>
                        <label for="" class="fz-12"> on 30 September, 2020</label>
                    </div>
                    <div class="d-flex flex-wrap w-100">
                        <label for="" class="fit-content fw-bold fz-14 mt-3">
                            Answer :  
                        </label>
                        <div class="col-sm col-12 ms-sm-4">
                            <div class="mb-2">
                                <label for="" class="w-100 fw-bold fz-14 mt-3 mb-1">
                                    Yes Working  Properly ...
                                </label>
                                <div class="d-flex align-items-center border-bottom pb-2">
                                    <div class="fit-content d-flex align-items-center border-end pe-3 me-3 ">
                                        <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                                        <label class="fz-15 ms-2" for="">Amar Salih</label>
                                    </div>
                                    <label for="" class="fz-12"> on 30 September, 2020</label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <label for="" class="w-100 fz-14 mt-2 mb-1">
                                    Yes Working  Properly ...
                                </label>
                                <div class="d-flex align-items-center border-bottom pb-2">
                                    <div class="fit-content d-flex align-items-center border-end pe-3 me-3 ">
                                        <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                                        <label class="fz-15 ms-2" for="">Sandra George</label>
                                    </div>
                                    <label for="" class="fz-12"> on 30 September, 2020</label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <label for="" class="w-100 fz-14 mt-2 mb-1">
                                    Yes Working  Properly ...
                                </label>
                                <div class="d-flex align-items-center border-bottom pb-2">
                                    <div class="fit-content d-flex align-items-center border-end pe-3 me-3 ">
                                        <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                                        <label class="fz-15 ms-2" for="">Sandra George</label>
                                    </div>
                                    <label for="" class="fz-12"> on 30 September, 2020</label>
                                </div>
                            </div>
                            <a href="" class="fz-14 mt-3 me-3">See all answers</a>
                            <a href="" class="fz-14 mt-3 me-3">Answer this question</a>
                        </div>
                    </div>
                </div>

                <div class="d-none d-flex flex-wrap mt-4">
                    <label for="" class="w-100 fw-bold fz-14">
                        Question : Does it have both input and output display ? In some models as i know you dont have
                    </label>
                    <div class="d-flex align-items-center mt-2">
                        <div class="fit-content d-flex align-items-center border-end pe-3 me-3 ">
                            <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                            <label class="fz-15 ms-2" for="">Amar Salih</label>
                        </div>
                        <label for="" class="fz-12"> on 30 September, 2020</label>
                    </div>
                    <div class="d-flex flex-wrap w-100">
                        <label for="" class="fit-content fw-bold fz-14 mt-3">
                            Answer :  
                        </label>
                        <div class="col-sm col-12 ms-sm-4">
                            <div class="mb-2">
                                <label for="" class="w-100 fw-bold fz-14 mt-3 mb-1">
                                    Yes Working  Properly ...
                                </label>
                                <div class="d-flex align-items-center border-bottom pb-2">
                                    <div class="fit-content d-flex align-items-center border-end pe-3 me-3 ">
                                        <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                                        <label class="fz-15 ms-2" for="">Amar Salih</label>
                                    </div>
                                    <label for="" class="fz-12"> on 30 September, 2020</label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <label for="" class="w-100 fz-14 mt-2 mb-1">
                                    Yes Working  Properly ...
                                </label>
                                <div class="d-flex align-items-center border-bottom pb-2">
                                    <div class="fit-content d-flex align-items-center border-end pe-3 me-3 ">
                                        <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                                        <label class="fz-15 ms-2" for="">Sandra George</label>
                                    </div>
                                    <label for="" class="fz-12"> on 30 September, 2020</label>
                                </div>
                            </div>
                            <div class="mb-2">
                                <label for="" class="w-100 fz-14 mt-2 mb-1">
                                    Yes Working  Properly ...
                                </label>
                                <div class="d-flex align-items-center border-bottom pb-2">
                                    <div class="fit-content d-flex align-items-center border-end pe-3 me-3 ">
                                        <img class="user-pic" src="../../../assets/assets/avatar 1.png" alt="">
                                        <label class="fz-15 ms-2" for="">Sandra George</label>
                                    </div>
                                    <label for="" class="fz-12"> on 30 September, 2020</label>
                                </div>
                            </div>
                            <a href="" class="fz-14 mt-3 me-3">See all answers</a>
                            <a href="" class="fz-14 mt-3 me-3">Answer this question</a>
                        </div>
                    </div>
                </div>

                <!-- <div class="w-100 mt-4">
                    <a href="" class="fz-14 mt-3 me-3">See all discussions</a>
                </div> -->
            </div>
            <div class="col-md-4 col-12 mx-md-5 px-sm-5 px-3" *ngIf="review.length !=0">
                <div class="d-flex w-100 justify-content-start">
                    <div class="star-rating">
                        <i class="bi small" [ngClass]="ratingValue >= 1 ? 'bi-star-fill' : 'bi-star'"></i>
                        <i class="bi small" [ngClass]="ratingValue == 1.5 ? 'bi-star-half' : 'd-none'"></i>
                        <i class="bi small" [ngClass]="ratingValue >= 2 ? 'bi-star-fill' : 'bi-star'"></i>
                        <i class="bi small" [ngClass]="ratingValue == 2.5 ? 'bi-star-half' : 'd-none'"></i>
                        <i class="bi small" [ngClass]="ratingValue >= 3 ? 'bi-star-fill' : 'bi-star'"></i>
                        <i class="bi small" [ngClass]="ratingValue == 3.5 ? 'bi-star-half' : 'd-none'"></i>
                        <i class="bi small" [ngClass]="ratingValue >= 4 ? 'bi-star-fill' : 'bi-star'"></i>
                        <i class="bi small" [ngClass]="ratingValue == 4.5 ? 'bi-star-half' : 'd-none'"></i>
                        <i class="bi small" [ngClass]="ratingValue >= 5 ? 'bi-star-fill' : 'bi-star'"></i>
                    </div>
                    <span class="rating ms-2 px-2 text-white small bg-info">{{ratingValue}}</span>
                </div>
                <div class="d-flex align-items-center justify-content-start pb-2">
                    <div class="fit-content d-flex align-items-center ">
                        <label for="" class="fz-13 text-smooth">{{ratingValueCustomerCount}} ratings</label>
                    </div>
                    <!-- <label for="" class="fz-13 text-smooth">12 Discussions</label> -->
                </div>
                <div class="d-flex flex-wrap w-100 my-3 mb-4">
                    <div class="col-12 d-flex rating-percentage mb-2">
                        <div class="rating-percentage__rate">
                            <span class="me-2">5</span>
                            <i class="bi bi-star-fill small"></i>
                        </div>
                        <div class="rating-percentage__analysis col">
                            <div class="rating-percentage__analysis--percent" [ngStyle]="{'width': rating_five_percentage}">
                            
                            </div>
                        </div>
                        <div class="rating-percentage__count" *ngIf="rating_five_star">
                            <span>{{rating_five_star}}</span>
                        </div>
                    </div>
                    <div class="col-12 d-flex rating-percentage mb-2">
                        <div class="rating-percentage__rate">
                            <span class="me-2">4</span>
                            <i class="bi bi-star-fill small"></i>
                        </div>
                        <div class="rating-percentage__analysis col">
                            <div class="rating-percentage__analysis--percent" [ngStyle]="{'width': rating_four_percentage}">
                            
                            </div>
                        </div>
                        <div class="rating-percentage__count" *ngIf="rating_four_star">
                            <span>{{rating_four_star}}</span>
                        </div>
                    </div>
                    <div class="col-12 d-flex rating-percentage mb-2">
                        <div class="rating-percentage__rate">
                            <span class="me-2">3</span>
                            <i class="bi bi-star-fill small"></i>
                        </div>
                        <div class="rating-percentage__analysis col">
                            <div class="rating-percentage__analysis--percent" [ngStyle]="{'width': rating_three_percentage}">
                            
                            </div>
                        </div>
                        <div class="rating-percentage__count" *ngIf="rating_three_star">
                            <span>{{rating_three_star}}</span>
                        </div>
                    </div>
                    <div class="col-12 d-flex rating-percentage mb-2">
                        <div class="rating-percentage__rate">
                            <span class="me-2">2</span>
                            <i class="bi bi-star-fill small"></i>
                        </div>
                        <div class="rating-percentage__analysis col">
                            <div class="rating-percentage__analysis--percent" [ngStyle]="{'width': rating_two_percentage}">
                            
                            </div>
                        </div>
                        <div class="rating-percentage__count" *ngIf="rating_two_star">
                            <span>{{rating_two_star}}</span>
                        </div>
                    </div>
                    <div class="col-12 d-flex rating-percentage ">
                        <div class="rating-percentage__rate">
                            <span class="me-2">1</span>
                            <i class="bi bi-star-fill small"></i>
                        </div>
                        <div class="rating-percentage__analysis col">
                            <div class="rating-percentage__analysis--percent" [ngStyle]="{'width': rating_one_percentage}">
                            
                            </div>
                        </div>
                        <div class="rating-percentage__count" *ngIf="rating_one_star">
                            <span>{{rating_one_star}}</span>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-wrap mb-3" *ngIf="this.isReviewable">
                    <label for="" class="fw-500 fz-16 w-100">How do your rate this product?</label>
                    <div class="star-rating">
                        <i class="bi small me-1" [ngClass]="rating.starOne === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeRating('one')"></i>
                        <i class="bi small me-1" [ngClass]="rating.starTwo === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeRating('two')"></i>
                        <i class="bi small me-1" [ngClass]="rating.starThree === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeRating('three')"></i>
                        <i class="bi small me-1" [ngClass]="rating.starFour === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeRating('four')"></i>
                        <i class="bi small me-1" [ngClass]="rating.starFive === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeRating('five')"></i>
                    </div>
                </div>
                <div class="d-flex flex-wrap mb-3" *ngIf="this.isReviewable">
                    <label for="" class="fw-500 fz-16 w-100">Value for money?</label>
                    <div class="star-rating">
                        <i class="bi small me-1" [ngClass]="valueForMoney.starOne === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeValueForMoneyRate('one')"></i>
                        <i class="bi small me-1" [ngClass]="valueForMoney.starTwo === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeValueForMoneyRate('two')"></i>
                        <i class="bi small me-1" [ngClass]="valueForMoney.starThree === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeValueForMoneyRate('three')"></i>
                        <i class="bi small me-1" [ngClass]="valueForMoney.starFour === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeValueForMoneyRate('four')"></i>
                        <i class="bi small me-1" [ngClass]="valueForMoney.starFive === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeValueForMoneyRate('five')"></i>
                    </div>
                </div>
                <div class="d-flex flex-wrap mb-3" *ngIf="this.isReviewable">
                    <label for="" class="fw-500 fz-16 w-100">Ease of use?</label>
                    <div class="star-rating">
                        <i class="bi small me-1" [ngClass]="easeOfUse.starOne === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeEaseOfUseRate('one')"></i>
                        <i class="bi small me-1" [ngClass]="easeOfUse.starTwo === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeEaseOfUseRate('two')"></i>
                        <i class="bi small me-1" [ngClass]="easeOfUse.starThree === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeEaseOfUseRate('three')"></i>
                        <i class="bi small me-1" [ngClass]="easeOfUse.starFour === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeEaseOfUseRate('four')"></i>
                        <i class="bi small me-1" [ngClass]="easeOfUse.starFive === true ? 'bi-star-fill' : 'bi-star'"
                        (click)="changeEaseOfUseRate('five')"></i>
                    </div>
                </div>
                <button class="btn btn-primary fz-13" *ngIf="this.isReviewable" (click)="ratingSubmit()">Submit</button>
            </div>
        </div>
    </div>
    <!-- Ends section -->