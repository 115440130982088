import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm!: FormGroup;
  forgotPassword=""
  is_valid:any
  public barLabel: string = "Password strength:";
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public thresholds = [90, 75, 45, 25];
  constructor(private toaster: ToastrService,private _router:Router,private messenger:MessengerService,private _auth:AuthService) { }

  ngOnInit(): void {
    this.forgotForm = new FormGroup({
      email: new FormControl('',[Validators.required, Validators.email])
    })
    this.is_valid = true
  }
  forgotSubmit(){
    if(this.forgotForm.invalid) {
      console.log('sdfsdsdc');
      this.toaster.warning('please enter a valid email Id')
      return
    }
    let email =this.forgotForm.value.email
    this._auth.forgotPassword(email).subscribe((data:any)=>{
      console.log(data,"data");
      if(data.status == 'success'){
        this.toaster.success(data.message)
        console.log(data.message);
        
        this.messenger.sendForgot(this.forgotForm.value.email)
        this._router.navigate(['/auth/otp/forgot'])
        localStorage.removeItem('forgotmail')
        localStorage.setItem('forgotmail',this.forgotForm.value.email,)
      }else{
        this.toaster.warning(data.message)
      }
      setTimeout(() => {
      
      }, 1000);
    }) 
  }
}
