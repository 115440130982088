<!-- header menu -->
<div class="container-fluid d-flex flex-wrap px-4 py-2 w-100 header align-items-center justify-content-between bg-secondary text-white" id="top">
    <div class="header__left-control order-1 order-xl-1 d-flex align-items-center">
        <button class="openbtn d-md-none" (click)="openNav()">☰</button> 
        <div class="header__logo"><a href="http://hilalcart.com/#/"><img src="../../../assets/test/hilal-05 1 (1).svg" alt=""[routerLink]="['/']" routerLinkActive="router-link-active" ></a></div> 
        <div class="userlocation d-md-flex align-items-center ms-md-4 d-none">
            <!-- <img class="uk-icon uk-icon-image" src="../../../assets/assets/UAE-Flag.svg"> -->
            <i class="fz-27 me-2 bi bi-geo-alt"></i>
            <div class="userlocation__details d-flex flex-column col" uk-toggle="target: #location">
                <span class="fz-12">Deliver to</span>
                <label class="fz-14 text-truncate-1 w-100 pointer fw-500" for="" *ngIf="currentLocation">{{currentLocation}}</label>
                <label class="fz-10 mb-0 w-100" for="" *ngIf="!currentLocation">waiting..</label>
                <!-- <label class="fz-13" for="">Edavetty post office</label> -->
            </div>
        </div>   
        <div class="search-box position-relative d-xl-block d-none ms-4">
            <div class="overflow-hidden position-relative h-100"  >
                <input class="form-control bg-light fz-13 text-truncate" name="ahlancartsearch"
                type="text"  [(ngModel)]="search" (input)="onSeachRoute(search)" 
                (keyup.enter)="onSearch(search)" placeholder="Search products..">
                <button class="pointer" (click)="onclickSearch(search)"><i class="bi bi-search"></i></button>
            </div>
            <div class="search-result d-flex" *ngIf="searchSuggesion?.length!=0 && search!='' && isClose " (clickOutside)="OnclickOutside($event)">
                <div class="col-12 d-flex flex-column">
                    
                    <div class="py-2 px-3 d-flex flex-wrap" *ngFor="let item of searchSuggesion">
                        <div class="d-flex flex-wrap">
                            <!-- <div class="search-box-icon">
                                <img class="uk-icon uk-icon-image pointer" (click)="routeProduct(item.id)" [src]="item.image1">
                            </div> -->
                            <div class="col d-flex flex-column ms-3 justify-content-start search-result__text pointer" (click)="routeProduct(item.id)">
                                <span class="text-dark my-auto font-1 ">{{item.name}}</span>
                            </div>
                        </div>
                    </div>
                </div>     
            </div>  
        </div>  
    </div>
    
    <div class="header__right-control order-2 order-xl-3 d-flex justify-content-end align-items-center mb-xl-0 mb-md-2">
        
        <div class="fit-width pe-3 order-2 order-md-2 d-none d-md-flex">
            <!-- <button class="btn dropdown-toggle d-flex align-items-center justify-content-start w-100 text-white" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <img src="../../../assets/assets/uae flag.svg" alt="">
                <label class="ms-1 fz-10" for="">UAE</label>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li><a class="dropdown-item active" href="#">UAE</a></li>
                <li><a class="dropdown-item" href="#">IND</a></li>
                <li><a class="dropdown-item" href="#">USA</a></li>
            </ul> -->
            <!-- <label class="fz-14 pointer" for="" (click)="switchLang(language)">{{language}}</label> -->
        </div>
        <div class="user-sec order-4 order-md-3 d-none d-md-flex align-items-center justify-content-end pe-2 mx-3 border-end" >
            <span *ngIf="user.profile_pic != ''">
                <img [src]="user?.profile_pic" alt="" *ngIf="user?.profile_pic !=null">
                <img class="profilepic rounded-circle" src="../../../assets/assets/user-icon.png" alt="" accept="image/x-png,image/gif,image/jpeg" *ngIf="user?.profile_pic ==null" >
            </span>
            <!-- <span *ngIf="user.profile_pic == ''" (click)="onUserNavigate()">
                <img class="ms-2" src="../../../assets/assets/guest.png" >
            </span> -->
            <div class="d-flex flex-wrap ms-2 multiline-detail">
                <label class="fz-14 pointer" for="" *ngIf="user.username != 'Sign in'" (click)="onUserNavigate()">
                    {{user?.username}}  
                </label>  
                <label class="fz-15" for="" *ngIf="user.username === 'Sign in'">
                     <span class="pointer" [routerLink]="['/auth']" routerLinkActive="router-link-active">{{'Login' | translate }}</span>
                      /<span class="pointer" [routerLink]="['/auth/signup']" routerLinkActive="router-link-active" >{{'Register' | translate }}</span>
                </label>
            </div>
        </div>
        <button class="btn dropdown-toggle order-1 order-md-4 d-flex d-md-none text-white me-2 pointer" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <span *ngIf="user.profile_pic != ''">
                <img class="user-toppic" [src]="user?.profile_pic" alt="" style="border-radius: 0.25rem;">
            </span>
            <span *ngIf="user.profile_pic == ''">
                <img class="user-toppic ms-2" src="../../../assets/assets/guest.png" >
            </span>
            <!-- <span>
                <img class="ms-2" src="../../../assets/assets/guest.png" >
            </span> -->
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li><a class="dropdown-item" *ngIf="user.username != 'Sign in'" [routerLink]="['/profile']" routerLinkActive="router-link-active" >Profile</a></li>
            <li><a class="dropdown-item" uk-toggle="target: #location">Location</a></li>
            <!-- <li><a class="dropdown-item" (click)="switchLang(language)">{{language}}</a></li> -->
            <li>
                <a class="dropdown-item" *ngIf="user.username === 'Sign in'"> 
                    <span [routerLink]="['/auth']" routerLinkActive="router-link-active" class="fz-16">Login</span>
                    / 
                    <span [routerLink]="['/auth/signup']" routerLinkActive="router-link-active" class="fz-16">Register</span>
                </a>
            </li>
            <li><a class="dropdown-item"  *ngIf="user.username != 'Sign in'" (click)="logOut()">Logout</a></li>
        </ul>
        <div class="col d-flex order-2 order-md-5 align-items-center pointer" (click)="onCartNavigate()">
            <div class="position-relative me-md-0">
                <i class="bi bi-cart3 fz-27"></i>
                <span class="cart-count" *ngIf="cartCount!=0">{{cartCount}}</span>
            </div>
        </div>
    </div>
    <div class="search-box position-relative order-4 d-xl-none d-block my-3 searchbox-tab">
        <div class="overflow-hidden position-relative h-100">
            <input class="form-control bg-light fz-13 text-truncate" 
            type="text" (input)="onSeachRoute(search)" 
            (keyup.enter)="onSearch(search)" [(ngModel)]="search" placeholder="Search products..">
            <button class="pointer" (click)="onclickSearch(search)"><i class="bi bi-search"></i></button>
        </div>
        <div class="search-result d-flex" *ngIf="searchSuggesion?.length!=0 && search!=''" (clickOutside)="OnclickOutside($event)">
            <div class="col-12 d-flex flex-column">
                <!-- <div class="py-2 px-3 d-flex flex-colum">
                    <div class="search-box-icon">
                        <span uk-icon="icon:history; ratio:0.7"></span>
                    </div>
                    <div class="col search-result__text">
                        <span class="text-dark my-auto ms-3 font-1">demo</span>
                    </div>
                </div>  
                <div class="py-2 px-3 d-flex flex-colum">
                    <div class="search-box-icon">
                        <span uk-icon="icon:search;ratio:0.7"></span>
                    </div>
                    <div class="col search-result__text">
                        <span class="text-dark my-auto ms-3 font-1">demo</span>
                    </div>
                </div> -->
                <div class="py-2 px-3 d-flex flex-wrap" *ngFor="let item of searchSuggesion">
                    <div class="d-flex flex-wrap">
                        <!-- <div class="search-box-icon">
                            <img class="uk-icon uk-icon-image" [src]="item.image1">
                        </div> -->
                        <div class="col d-flex flex-column ms-3 justify-content-start search-result__text">
                            <span class="text-dark my-auto font-1 ">{{item.name}}</span>
                        </div>
                    </div>
                </div>
            </div>     
        </div>  
    </div>  
    <div class="d-flex d-md-none order-5 w-100 mt-2 mb-2 fz-13" uk-toggle="target: #location">
        <i class="bi bi-geo-alt me-3"></i>
        <span class="text-truncate-1">Deliver to {{currentLocation}}</span>
    </div>
</div>

<!-- category menu -->
<div class="container-fluid d-md-flex d-none p-0 py-lg-0 py-2 w-100 top-menu align-items-center justify-content-between">
    <div class="col h-100">
        <nav class="navbar navbar-expand-lg navbar-light category-menu h-100">
            <div class="container-fluid h-100">
                <button class="navbar-toggler menu-toggle" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse collapse-menu h-100 justify-content-start" id="navbarNav">
                    <ul class="navbar-nav menu-header col-4 col-lg-12 py-lg-0 h-100">
                        <li class="nav-item category-menu-item all-category me-4" >
                            <a class="nav-link h-100 ps-lg-3">
                                <i class="bi bi-grid-3x2-gap grid"></i>
                                <span class="allcategory-menu text-uppercase pointer fw-bold ms-2" id="allCategory_filed" (mouseover)="changeAllCategory($event)" (mouseout)="allCategoryDrop = !allCategoryDrop" >{{"All Category" | translate}}</span>
                                <!-- <select class="form-select" aria-label="Default select example" [(ngModel)]="Categorytype" (change)="ontypeChange()">
                                    <option value="smart">{{"smart"| translate }}</option>
                                    <option value="groceries">{{"groceries" | translate}}</option>
                                    <option value="bulk">{{"bulk"|translate}}</option>
                                    <option value="home_living">{{"homeandliving"|translate}}</option>
                                </select> -->
                            </a>
                        </li>
                        <!-- (mouseover)="onMenuHover(i)" (mouseleave)="hoverMenu = false" -->
                        <li class="nav-item category-menu-item" *ngIf="divisionMenuList?.length !=0">
                            <a class="nav-link h-100 text-uppercase pointer fw-bolder deals_text " [routerLink]="['/deals/FRUIT AND VEGETABLES_bulk_63']" routerLinkActive="router-link-active" >{{"deals"|translate}}</a>
                        </li>
                        <!-- <li class="division-list division-list-holder nav-item category-menu-item nav_link_over "*ngFor="let menu of divisionAllCategory;let i = index"  (mouseover)="divisionCategory(menu.id)" (mouseout)="divisionDrops = !divisionDrops" (click)="divisionRoute(menu.name,menu.id,menu.code)">
                            <a class="nav-link h-100 text-uppercase pointer  fw-medium fz-13" routerLinkActive="router-link-active" >{{menu.name}}</a>
                        </li> -->
                        <div class="division-list">
                            <div class="division-list-holder">
                                <label class="nav-item category-menu-item  nav_link_over pb-0" *ngFor="let menu of divisionAllCategory;let i = index" (mouseover)="divisionCategory(menu.id)" (mouseout)="divisionDrops = !divisionDrops" (click)="divisionRoute(menu.name,menu.id,menu.code)">
                                    <a class="nav-link h-100 text-uppercase pointer  fw-medium fz-13 px-0" >{{menu.name}}</a>
                                </label>
                            </div>
                        </div>
                        <!-- <li class="nav-item category-menu-item" *ngFor="let menu of divisionMenuList|slice:0:showMenu; let i = index" >
                            <a class="nav-link h-100 text-uppercase pointer" (click)="divisionRoute(menu.name,menu.id,menu.code)">{{menu.name}}</a>
                        </li>
                        <li class="nav-item category-menu-item" *ngIf="showMoreDivision">
                            <div class="uk-inline h-100" *ngIf="divisionMenuList.length !=0">
                                <a class="nav-link h-100 text-uppercase pointer" type="button">
                                    More <span class="uk-margin-small-left" uk-icon="chevron-down"></span>
                                </a>
                                <div uk-dropdown>
                                    <ul class="nav flex-column" >
                                        <li class="nav-item" *ngFor="let menu of divisionMenuList|slice:showMenu:divisionMenuList?.length; let i = index">
                                          <a class="nav-link" (click)="divisionRoute(menu.name,menu.id,menu.code)">{{menu.name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>
    </div>
</div>
<!-- Ends header menu -->
<!--  -->
<div class="all_category d-flex" [ngClass]=" allCategoryDrop == true ? 'd-flex': 'd-none'" (mouseover)="allCategoryDrop = true" (mouseout)="allCategoryDrop = false">
    <div class="all_category__main py-3 px-3">
        <ul class="nav flex-column">
            <li class="nav-item" *ngFor="let category of allCategory;let i = index">
                <a class="nav-link text-dark category-menu__item" (mouseover)="selectSubcategory(i,category.name)" (click)="routeCategory(category.name,category.id,category.code)" >{{category.name | titlecase}}</a>
            </li>
        </ul>
    </div>
    <div class="all_category__sub" *ngFor="let category of allCategory;let c = index">
        <div class="all_category__sub--header" [ngClass]="c == subCategoryIndex ? 'd-flex': 'd-none'"  *ngIf="c == subCategoryIndex">
            {{category.name | titlecase}}
        </div>
        <div class="all_category__sub--body" *ngIf="c == subCategoryIndex" [ngClass]="c == subCategoryIndex ? 'd-flex': 'd-none'" >
            <div class="col subcategory-menu border-end h-100">
                <div class="col-12 d-flex flex-wrap align-items-start fit-height category-subCategory">
                    <div class="col-12 py-2 fit-height" *ngFor="let item of category?.subcategories;let s = index">
                        <label for="" class="category-menu__item pointer" (mouseover)="subcategoryIndex(s)"  (click)="routeSubCategory(item.name,item.code,item.id)">{{item.name | titlecase}}</label>
                    </div>
                </div>
            </div>
            <div class="col h-100 d-flex">
                <div class="col h-100 px-3">
                    <div class="col-12 d-flex flex-wrap align-items-start fit-height category-subCategory">
                        <div class="col-6 py-2"  *ngFor="let item of category.subcategories[productGroupIndex]?.groups;let s = index">
                            <label for="" class="category-menu__item" (click)="routeSubCategory(category.name,category.code,category.id)">{{item.name | titlecase}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-4 py-2">
                    <div class="col-12 row gx-2">
                        <div class="col-12 image_brand_scroll">
                            <div class="images_Brand py-0 my-0" *ngFor="let brand of category.subcategories[productGroupIndex]?.brands;let s = index" >
                                <img *ngIf="brand?.image!=null" [src]="brand.image" alt="" class="my-0 py-0" style="max-width: 45%;"> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="all_category d-flex" [ngClass]=" divisionDrops == true ? 'd-flex': 'd-none'" (mouseover)="divisionDrops = true" (mouseleave)="divisionDrops = false">
    <div class="all_category__main py-3 px-3">
        <ul class="nav flex-column">
            <li class="nav-item" *ngFor="let category of divisionCateArray;let i = index">
                <a class="nav-link text-dark category-menu__item" (mouseover)="selectDivisionSubcategory(i)" (mouseleave)="mouseLeave(category.name)" (click)="routeCategory(category.name,category.id,category.code)" >{{category.name | titlecase}}</a>
            </li>
        </ul>
    </div>
    <div class="all_category__sub" *ngFor="let category of divisionCateArray;let c = index">
        <div class="all_category__sub--header" [ngClass]="c == divisionSubcateIndex ? 'd-flex': 'd-none'"  *ngIf="c == divisionSubcateIndex">
            {{category.name | titlecase}}
        </div>
        <div class="all_category__sub--body" *ngIf="c == divisionSubcateIndex" [ngClass]="c == divisionSubcateIndex ? 'd-flex': 'd-none'" >
            <div class="col subcategory-menu border-end h-100">
                <div class="col-12 d-flex flex-wrap align-items-start fit-height category-subCategory">
                    <div class="col-12 py-2 fit-height" *ngFor="let item of category.subcategories;let s = index">
                        <label for="" class="category-menu__item pointer" (mouseover)="selectDivisionGroups(s)"  (click)="routeSubCategory(item.name,item.code,item.id)">{{item.name | titlecase}}</label>
                    </div>
                </div>
            </div>
            <div class="col h-100 d-flex">
                <div class="col h-100 px-3">
                    <div class="col-12 d-flex flex-wrap align-items-start fit-height category-subCategory">
                        <div class="col-6 py-2"  *ngFor="let item of category.subcategories[divisionGroupIndex]?.groups;let s = index">
                            <label for="" class="category-menu__item" (click)="routeGroup(category.name,category.id,category.code)">{{item.name | titlecase}}</label>
                        </div>
                    </div>
                </div>
                <div class="col-4 py-2">
                    <div class="col-12 row gx-2">
                        <div class="col-12 image_brand_scroll">
                            <div class="images_Brand py-0 my-0" *ngFor="let brand of category.subcategories[divisionGroupIndex]?.brands;let s = index">
                                <img *ngIf="brand.image!=null" [src]="brand.image" alt="" class="my-0 py-0" style="max-width: 45%;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- mobile menu -->
<div id="mySidebar" class="sidebar" [ngClass]="{'w-100': sidenav == true}">
    <a href="javascript:void(0)" class="closebtn" (click)="openNav()">×</a>
    <a *ngFor="let menu of divisionMenuList; let i = index">
        <span class="pointer" (click)="divisionRoute(menu.name,menu.id,menu.code)" >{{menu.name}}</span>
    </a>
    <a>
        <span class="pointer" [routerLink]="['/deals/FRUIT AND VEGETABLES_bulk_63']" routerLinkActive="router-link-active" >{{"deals"|translate}}</span>
    </a>
    <a class="border-bottom pb-4">
        <!-- <span class="allcategory-menu text-uppercase pointer text-dark" (mouseover)="changeAllCategory()" (mouseleave)="allCategoryDrop = !allCategoryDrop">{{"All Category" | translate}}</span> -->
        <!-- <select class="form-select" aria-label="Default select example" [(ngModel)]="Categorytype" (change)="ontypeChange()">
            <option value="smart">{{"smart"| translate }}</option>
            <option value="groceries">{{"groceries" | translate}}</option>
            <option value="bulk">{{"bulk"|translate}}</option>
            <option value="premium">{{"premium"|translate}}</option>
        </select> -->
    </a>
    <a class="fw-500 mb-2 text-dark pt-3 text-decoration-none fz-15" for="">Categories</a>
    <a class="" *ngFor="let category of allCategory;let i = index">
        <span class="d-block fz-13 text-dark text-uppercase pointer" (mouseover)="selectSubcategory(i,category.name)" (click)="routeCategory(category.name,category.id,category.code)">{{category.name}}</span>
    </a>
    
</div>
<!-- mobile menu -->


<div id="category-menu" class="category-onhover p-4" [ngClass]=" hoverMenu == true ? 'd-flex': 'd-none'" (mouseover)="hoverMenu = true" (mouseleave)="hoverMenu = false">
    <div class="row m-0 w-100 p-0">
        <div class="col-md-6 col-12 d-flex p-0">
            <div class="col-4 p-0">
                <div class="menu-column all_category__main bg-white">
                    <nav class="nav flex-column">
                        <label class="fw-500 mb-2" for="">Categories</label>
                        <a class="nav-link p-0" *ngFor="let menu of menuList[menuId]?.category;let i = index">
                            <span class="py-1 d-block fz-13">{{menu.item}}</span>
                        </a> 
                    </nav>     
                </div>
            </div>
            <div class="col">
                <div class="container-fluid">
                    <label class="fw-500 mb-3" for="">Brands</label>
                    <div class="row gx-1 w-100">
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                        <div class="col-md-4 mb-1">
                            <img class="category-menu-brand border" src="../../../assets/assets/hp-brand.jpeg" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-6 d-md-flex d-none overflow-hidden h-100" *ngFor="let item of menuList[menuId]?.addImage">
            <div class="d-flex w-100 flex-wrap" *ngIf="item.type === 'one'">
                <div class="col me-2">
                    <img class="w-100" [src]="item.image[0].image" alt="">
                </div>
                <div class="col-4">
                    <img class="w-100 h-100" [src]="item.image[1].image" alt="">
                </div>
            </div>
            <div class="d-flex w-100 flex-wrap" *ngIf="item.type === 'two'">
                <div class="col me-2" *ngFor="let item of item.image">
                    <img class="w-100" [src]="item.image" alt="">
                </div>
            </div>
            <div class="d-flex w-100 flex-wrap" *ngIf="item.type === 'three'">
                <div class="col me-2" *ngFor="let image of item.image">
                    <img class="w-100" [src]="image.image" alt="">
                </div>
            </div>
        </div>
    </div>
</div>




<div id="location"  class="uk-flex-top"  uk-modal="stack:true">
    <div class="uk-modal-dialog uk-modal-body p-0 uk-margin-auto-vertical location-changer">
        <button class="uk-modal-close-outside" type="button" uk-close></button>
        <div class="d-flex flex-wrap location-changer__body">
            <div class="location-changer__left " *ngIf="showAvailbaleAddress">
                <div class="d-flex justify-content-bwtween">
                    <label for="" class="location-changer__left--title fw-500 w-100" *ngIf="newAddress">Delivery Address</label>
                    <label for="" class="location-changer__left--title fw-500 w-100" *ngIf="!newAddress">Create new address</label>
                    <div class="d-flex flex-wrap justify-content-end w-100">
                        <label class="f-13 text-primary ms-1" (click)="showHideAddress()">
                            <span *ngIf="newAddress" (click)="addNew()">+ Add Address</span>
                            <span *ngIf="!newAddress" (click)="addNewMy()">Show my address</span>
                        </label>
                    </div>
                </div>
                <div class="location-changer__left--address" *ngIf="newAddress">
                    <div class="location-changer__left--addressitem" *ngFor="let address of userDetailAdress;let i=index" >
                        <div class="item" [ngClass]="{active: defualtAddressId ==address?.id}">
                            <div class="address-item w-100">
                                <label class="form-check-label w-100" [for]="addressHolder+address.id" *ngIf="deleteAddressId != address.id">
                                    {{address?.full_name}}, 
                                    <br>
                                    {{address?.building_no}}, {{address?.building_name}}, {{address?.landmark}},
                                    <br>
                                    {{address?.street_name}}, {{address?.city}}, {{address?.country}},
                                    <br>
                                    {{address?.state}}, {{address?.contact}}
                                </label>
                                <div class="address-control" *ngIf="deleteAddressId != address.id">
                                    <label (click)="deleteConfirm(address.id)">
                                        <i class="bi bi-trash me-2"></i>Remove
                                    </label>
                                    <label (click)="EditAddress(address,address.id)"><i class="bi bi-pencil me-2"></i>Edit</label>
                                </div>
                                <div class="delete-address d-flex flex-column" *ngIf="deleteAddressId == address.id">
                                    <label for="">Are you sure you want to delete this address?</label>
                                    <div class="d-flex flex-wrap w-100 mt-4">
                                        <button type="button" class="btn btn-primary px-3 me-3" (click)="deleteAddressId = 00">Cancel</button>
                                        <button type="button" class="btn btn-primary" (click)="deleteThisAddress(address.id)">Confirm</button>
                                    </div>
                                </div>
                            </div>
                            <div class="form-check m-0" *ngIf="deleteAddressId != address.id">
                                <input class="form-check-input m-0" type="radio" name="flexRadioDefault" [id]="addressHolder +address?.id" [checked]="defualtAddressId == address?.id" (click)="changeAddress(address.id,address)">
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap align-items-center justify-content-center p-5" *ngIf="userDetailAdress.length == 0">
                        no address found
                    </div>
                </div>
                <div class="location-changer__left--address" [ngClass]="{'h-100': !showMap}" *ngIf="!newAddress">
                    <div class="container-fluid p-0">
                        <div class="col-12 row mt-3 mx-0">
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12">Full name</label>
                                <input type="text" class="form-control"   [(ngModel)]="addressList.full_name" required placeholder="" aria-label="City">
                                <span class="text-danger error-message" *ngIf="addressListError.full_name">please enter full name</span>
                            </div>
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12">Building name</label>
                                <input type="text" [(ngModel)]="addressList.building_name" class="form-control" placeholder="" aria-label="First name">
                                <span class="text-danger error-message" *ngIf="addressListError.building_name">please enter Buliding name</span>
                            </div>
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12">Building no</label>
                                <input type="text" class="form-control" [(ngModel)]="addressList.building_no" placeholder="" aria-label="">
                                <span class="text-danger error-message" *ngIf="addressListError.building_no">please enter building number</span>
                            </div>
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12">Land mark</label>
                                <input type="text" [(ngModel)]="addressList.landmark" class="form-control" placeholder="" aria-label="Last name">
                                <span class="text-danger error-message" *ngIf="addressListError.landmark">please enter landmark</span>
                            </div>
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12">Street</label>
                                <input type="text" class="form-control" [(ngModel)]="addressList.street_name" placeholder="" aria-label="">
                                <span class="text-danger error-message" *ngIf="addressListError.street_name">please enter street name</span>
                            </div>
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12">City</label>
                                <input type="text" class="form-control" [(ngModel)]="addressList.city" required placeholder="" aria-label="City">
                                <span class="text-danger error-message" *ngIf="addressListError.city">please enter city</span>
                            </div>
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12">Country</label>
                                <input type="text" [(ngModel)]="addressList.country" class="form-control" placeholder="" aria-label="First name" disabled="true">
                            </div>
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12"> Emirates</label>
                                <select class="form-select" [(ngModel)]="addressList.state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                                <span class="text-danger error-message" *ngIf="addressListError.state">please select emirites</span>
                            </div>
                            <div class="col-md-6 mb-4 position-relative">
                                <label for="fname" class="form-label  mb-1 fz-12">Contact</label>
                                <input type="number" class="form-control"  maxlength="10" [(ngModel)]="contactMobile"  placeholder="number" aria-label="Fax" (change)="changeNumBtn()">
                                <!-- <span class="text-danger error-message" *ngIf="contactMobile">please enter phone number</span> -->
                            </div>
                        </div>  
                        <div class="col-12 row mt-3 mx-0 px-3 justify-content-between">
                            <button class="btn fz-13 p-0 border-bottom text-uppercase cw btn_w" (click)="chooseCurrentLocationEdit()">LOCATE ME</button> 
                            <div class="col d-flex justify-content-end" *ngIf="editAddress">
                                <button type="button" class="btn btn-primary px-3 me-3" (click)="cancelAddress()">CANCEL</button>
                                <button type="button" *ngIf="changeNumber==false" class="btn btn-primary" (click)="updateAddress(editAddressId)">UPDATE ADDRESS</button>
                                <button type="button" *ngIf="changeNumber==true" class="btn btn-primary" (click)="updateSendotp()">UPDATE ADDRESS</button>
                            </div>    
                            <div class="col d-flex justify-content-end" *ngIf="!editAddress">   
                                <button type="button" class="btn btn-primary px-3 me-3" (click)="cancelAddress()">CANCEL</button>
                                <button type="button" class="btn btn-primary" (click)="addressSendotp()">ADD ADDRESS</button>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>
            <div class="location-changer__right col d-flex flex-row ps-1" *ngIf="showMap">
                <div class="d-flex align-items-center" *ngIf="user.username != 'Sign in'">
                    <button class="location-changer__right--btn" (click)="showAvailbaleAddress = !showAvailbaleAddress">
                        <label for="" *ngIf="showAvailbaleAddress">
                            <i class="bi bi-arrow-left-circle-fill me-2"></i> 
                        </label>
                        <label for="" *ngIf="!showAvailbaleAddress">
                            <i class="bi bi-arrow-right-circle-fill me-2"></i>
                        </label>
                    </button>
                </div>
                <!-- <div class="my-4 w-100" *ngIf="user.username === 'Sign in'">
                    
                </div> -->
                <div class="position-relative body-location" >
                    <div>
                        <div class="search-box mx-auto position-relative" >
                            <div class="overflow-hidden position-relative h-100">
                                <input class="form-control bg-light fz-13 text-truncate" type="text" placeholder="Search location ..." [(ngModel)]="searchLocation" (keyup.enter)="requestLocation(searchLocation)">
                                <button (click)="requestLocation(searchLocation)"><i class="bi bi-search"></i></button>
                            </div>
                        </div>  
                        <google-map
                            [height]="mapHeight" width="100%" [zoom]="zoom" [center]="center" [options]="options" (mapClick)="mapLocation($event)">
                            <map-marker
                                *ngFor="let marker of markers"
                                [position]="marker.position"
                                [options]="marker.options"
                            >
                            </map-marker>
                        </google-map>
                        <div id="map"></div>
                        <div class="footer-location" *ngIf="newAddress">
                            <!-- <button class="btn btn-primary uk-modal-close pointer" (click)="createAddress()"> 
                                Confirm Location
                            </button> -->
                        </div>
                        <div class="footer-location" *ngIf="is_login==true">
                            <!-- <button class="btn btn-primary pointe uk-modal-closer"> 
                                Confirm Location
                            </button> -->
                        </div>
                        <label class="current-location-btn" (click)="currentLocationFinder()"><i class="bi bi-geo-alt me-2"></i>Locate Me</label>
                    </div>
                </div>
            </div>
            <p-dialog header="" [(visible)]="display">
                <div class="row col-12">
                    <div class="col-lg-12 col-12 mt-lg-0 mt-2 mt-lg-0 mt-2 form-outline w-100 position-relative">
                        <input type="text" class="form-control"name='email' placeholder="----" [(ngModel)]="addressCreateOtp"/>
                        <!-- <span class="fz-12 text-danger fw-bold" *ngIf="optWarnning==true">check the opt</span> -->
                        <button class="resend-otp" title="Resend Otp" >
                          <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
                          <i class="ms-2 fw-14 fw-bold" (click)="resendOtpNew()" *ngIf="subscribeTimer ==0">Resend</i>
                        </button>
                        <!-- <span class="fz-12 text-danger fw-bold" *ngIf="optWarnning==true">check the opt</span> -->
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <button type="button" class="btn btn-primary"  (click)="addressOtpVarify()">Send</button>
                    </div>
                </div> 
            </p-dialog>
            <p-dialog header="" [(visible)]="updateAddressOtp">
                <div class="row col-12">
                    <div class="col-lg-12 col-12 mt-lg-0 mt-2 mt-lg-0 mt-2 form-outline w-100 position-relative">
                        <input type="text" class="form-control"name='email' placeholder="----" [(ngModel)]="addressUpdateOtp"/>
                        <!-- <span class="fz-12 text-danger fw-bold" *ngIf="optWarnning==true">check the opt</span> -->
                        <button class="resend-otp" title="Resend Otp" >
                          <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
                          <i class="ms-2 fw-14 fw-bold" (click)="resendUpdateOtpNew()" *ngIf="subscribeTimer ==0">Resend</i>
                        </button>
                    </div>
                    <div class="col-12 d-flex justify-content-center mt-3">
                        <button type="button" class="btn btn-primary" (click)="updateAddressOtpVarify(editAddressId)">Send</button>
                    </div>
                </div> 
            </p-dialog>
        </div>
    </div>
</div>



