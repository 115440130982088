import { Injectable } from '@angular/core';
import { Cart } from '../models/cart';
import { MessengerService } from './messenger.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment'; 
import { ThrowStmt } from '@angular/compiler';
@Injectable({
  providedIn: 'root'
})
export class CartService {
  api_cart_checkout = environment.api_cart_checkout
  createCart ="/order/create-cart"
  getCartData = "/order/list-cart-by-customer/"
  removeCartData = "/order/RemovecartbyCustomer"
  saveForLaterData = "/order/create-SaveforLater"
  removeSaveForLater = "/order/cancel-SaveforLater"
  moveToCart = "/order/movetocart"
  buyItAgain = "/order/list-buy-it-again/"
  getSaveForLaterData = "/order/list-save-for-later/"
  cartCount="/order/check-cart-variant/"
  inCart="/order/check-cart-variant/"
  cartData:any = [] 
  localCart:any = {
    user_id: 0,
    cart_products: <any>[] 
  }


  cart:Cart[] = []
  checkoutArray:any = []
  constructor(private msg: MessengerService, private router:Router, private http: HttpClient,private toaster: ToastrService,) { 
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user) 
    if(userDetails == null){
      return
    } else{
      let cart = this.http.get<any>(this.api_cart_checkout+this.getCartData+userDetails.customer_usercode)
      cart.subscribe((data:any)=>{
        this.cartData = data.data
      })
    }
    
  }

  getCartCount(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user) 
    return this.http.get<any>(this.api_cart_checkout+this.cartCount+userDetails?.customer_usercode)
  }
  checkInCart(variant_id:any,inventory:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user) 
    return this.http.get<any>(this.api_cart_checkout+this.inCart+userDetails?.customer_usercode+'?variant_id='+variant_id+'&inventory='+inventory)
  }

  getCartValue(){
    return this.cartData
  }
  getCart(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user) 
    return this.http.get<any>(this.api_cart_checkout+this.getCartData+userDetails?.customer_usercode)
    
  }
  
  addToCart(product:any,qty:any){
    console.log(product,"product in Cart Service");
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user) 
    
    let cartData = {
      user_id: userDetails.customer_usercode,
      variant_id: product.id,
      inventory_id: product.inventory_id,
      image: product.image1,
      name: product.name,
      quantity: parseInt(qty),
    }
    this.http.post(this.api_cart_checkout+this.createCart,cartData).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        // this.toaster.success(data.message)
        this.msg.dontRefresh()
        this.http.get<any>(this.api_cart_checkout+this.getCartData+userDetails.customer_usercode).subscribe((data:any)=>{
          // console.log(data.data,"[][][][][][][][]");
          this.msg.cartCount(data.data.length)
          let cartList:any=[]
          let cartArray:any =[]
          let totalCart=0
          let savedTotal=0
    
           this.getCart().subscribe((cartList:any)=>{
             cartList = cartList.data
             cartArray = [...cartList]
             cartList.forEach((data:any) => {
             totalCart += data.total_price 
             console.log(totalCart,"totalCart");
             this.msg.cartPrice(totalCart)
             })
            cartList.forEach( (data:any)=> {
               savedTotal += ((data.total_price * data.quantity) - (data.total_price * data.quantity))
               console.log(savedTotal,"savedTotal");
   
             })  
   
           })
        })
      }else{
        this.toaster.warning(data.message)
        setTimeout(() => {
          window.location.reload()
        }, 200);
      }
      console.log(data, "____");  
    })
  }

  saveForLater(e:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let saveForLater = {
      user_id: userDetails.customer_usercode,
      variant_id: e, 
      is_active: true 
    }
    this.http.post(this.api_cart_checkout+this.saveForLaterData,saveForLater).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        this.toaster.success(data.message)
        this.msg.dontRefresh()
        this.http.get<any>(this.api_cart_checkout+this.getCartData+userDetails.customer_usercode).subscribe((data:any)=>{
          // console.log(data.data,"[][][][][][][][]");
          this.msg.cartCount(data.data.length)
        })
        let cartList:any=[]
        let cartArray:any =[]
        let totalCart=0
        let savedTotal=0
  
         this.getCart().subscribe((cartList:any)=>{
           cartList = cartList.data
           cartArray = [...cartList]
           cartList.forEach((data:any) => {
           totalCart += data.total_price 
           console.log(totalCart,"totalCart");
           this.msg.cartPrice(totalCart)
           })
          cartList.forEach( (data:any)=> {
             savedTotal += ((data.total_price * data.quantity) - (data.total_price * data.quantity))
             console.log(savedTotal,"savedTotal");
 
           })  
 
         })
      }else{
        this.toaster.warning(data.message)
      }
    })
  }

  removeSaveForLaterData(e:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let saveForLater = {
      user_id: userDetails.customer_usercode,
      variant_id: e, 
    }
    this.http.post(this.api_cart_checkout+this.removeSaveForLater,saveForLater).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        this.toaster.success("Removed")
        this.msg.dontRefresh()
        this.http.get<any>(this.api_cart_checkout+this.getCartData+userDetails.customer_usercode).subscribe((data:any)=>{
          this.msg.cartCount(data.data.length)
        })
        let cartList:any=[]
       let cartArray:any =[]
       let totalCart=0
       let savedTotal=0
 
        this.getCart().subscribe((cartList:any)=>{
          cartList = cartList.data
          cartArray = [...cartList]
          cartList.forEach((data:any) => {
          totalCart += data.total_price 
          console.log(totalCart,"totalCart");
          this.msg.cartPrice(totalCart)
          })
         cartList.forEach( (data:any)=> {
            savedTotal += ((data.total_price * data.quantity) - (data.total_price * data.quantity))
            console.log(savedTotal,"savedTotal");

          })  

        })
      }else{
        this.toaster.warning(data.message)
      }
    })
  }

  moveToCartFromSaveForLater(e:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let saveForLater = {
      user_id: userDetails.customer_usercode,
      variant_id: e, 
    }
    this.http.post(this.api_cart_checkout+this.moveToCart,saveForLater).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        this.toaster.success("Moved to Cart")
        this.msg.dontRefresh()
        let cartList:any=[]
        let cartArray:any =[]
        let totalCart=0
        let savedTotal=0
  
         this.getCart().subscribe((cartList:any)=>{
           cartList = cartList.data
           cartArray = [...cartList]
           cartList.forEach((data:any) => {
           totalCart += data.total_price 
           console.log(totalCart,"totalCart");
           this.msg.cartPrice(totalCart)
           })
          cartList.forEach( (data:any)=> {
             savedTotal += ((data.total_price * data.quantity) - (data.total_price * data.quantity))
             console.log(savedTotal,"savedTotal");
 
           })  
 
         })
      }
    })
  }

  removeCart(e:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let removeCartItem = {
      user_id:userDetails.customer_usercode, 
      variant_id:e
    }
    this.http.post(this.api_cart_checkout+this.removeCartData,removeCartItem).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        this.toaster.success("Removed Item")
        this.msg.dontRefresh()
        this.http.get<any>(this.api_cart_checkout+this.getCartData+userDetails.customer_usercode).subscribe((data:any)=>{
          this.msg.cartCount(data.data.length)
        })
        let cartList:any=[]
        let cartArray:any =[]
        let totalCart=0
        let savedTotal=0
  
         this.getCart().subscribe((cartList:any)=>{
           cartList = cartList.data
           cartArray = [...cartList]
           cartList.forEach((data:any) => {
           totalCart += data.total_price 
           console.log(totalCart,"totalCart");
           this.msg.cartPrice(totalCart)
           })
          cartList.forEach( (data:any)=> {
             savedTotal += ((data.total_price * data.quantity) - (data.total_price * data.quantity))
             console.log(savedTotal,"savedTotal");
 
           })  
 
         })
        // setTimeout( () => { 
        //   window.location.reload()
        // }, 2000);
    
      }
      console.log(data, "____");
    })
    console.log(e,"Cart Removed");
  }

  getSaveForLater(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    return this.http.get<any>(this.api_cart_checkout+this.getSaveForLaterData+userDetails.customer_usercode)
  }

  getBuyItAgain(){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    
    return this.http.get<any>(this.api_cart_checkout+this.buyItAgain+userDetails.customer_usercode)
  }

  addCheckout(data:any){
    this.checkoutArray = data
    console.log(data);
    
    // this.router.navigate(['/checkout'])
  }

  getCheckoutData(){
    return this.checkoutArray
  }
  
  removeLocalCart(e:any){
    let CartData = localStorage.getItem('CartData')
    let index:any
    if(CartData != null){
      let cart = JSON.parse(CartData)
      this.localCart = cart
      let i = 0
      this.localCart.cart_products.filter((data:any)=>{
        if(data.variant_id === e){
          console.log(i,"----");
          
          this.localCart.cart_products.splice(i,1)
          localStorage.removeItem('CartData')
          let cartData = JSON.stringify(this.localCart)
          localStorage.setItem('CartData',cartData)
          return
        }
        i++
      })
      
    }
  }

  addToLocalCart(product:any,qty:any){
    let cartData:any = localStorage.getItem('CartData')
    console.log(product,"product");
    if(cartData != null){
      let cart = JSON.parse(cartData)
      this.localCart = cart
    }
    let cartItem:any = {
      variant_id: product.id,
      inventory_id: product.inventory_id,
      image: product.image1,
      name: product.name,
      quantity: qty,
      price:product.price,
      total_price: product.price * qty,
      max_order_limit:product.max_order_limit,
      min_order_limit:product.min_order_limit,
      stock_count:product.stock_count
    }
    if(this.localCart.cart_products.length != 0){
      this.localCart.cart_products.filter((data:any)=>{
        console.log(data.variant_id, cartItem.variant_id,"demo123");
        console.log('entereed 1');
        let demo=localStorage.getItem('CartData')
          console.log(demo,"demo");
          let test:any=[]
          test.push(demo)
          console.log(test.length,"<<<<<<<<<<<<<<");
          let CartData:any = localStorage.getItem('CartData')
          let cartCount
          let cart:any = JSON.parse(CartData)
          this.cartData = cart?.cart_products
          cartCount = this.cartData?.length
          // cart_products
          this.msg.cartCount(cartCount)
        if(data.variant_id == cartItem.variant_id){
          console.log('entereed 2');
          data.quantity = cartItem.quantity
          data.total_price = cartItem.price * qty
          localStorage.removeItem('CartData')
          let cartData = JSON.stringify(this.localCart)
          localStorage.setItem('CartData',cartData)
          let demo=localStorage.getItem('CartData')
          console.log(demo,"demo");
          let test:any=[]
          test.push(demo)
          console.log(test.length,"<<<<<<<<<<<<<<");
          let CartData:any = localStorage.getItem('CartData')
          let cartCount
          let cart:any = JSON.parse(CartData)
          this.cartData = cart.cart_products
          cartCount = this.cartData?.length
          // cart_products
          this.msg.cartCount(cartCount)

        }else{
          if(this.ProductExist(cartItem.variant_id)){
            return
          }else{
            this.localCart.cart_products.push(cartItem)
            localStorage.removeItem('CartData')
            let cartData = JSON.stringify(this.localCart)
            localStorage.setItem('CartData',cartData)
            let demo=localStorage.getItem('CartData')
            console.log(demo,"demo");
            let CartData:any = localStorage.getItem('CartData')
            let cartCount
            let cart:any = JSON.parse(CartData)
            this.cartData = cart.cart_products
            cartCount = this.cartData?.length
            // cart_products
            this.msg.cartCount(cartCount)
          }
        }
      })
    }else{
      console.log('entereed 5');
      localStorage.removeItem('CartData')
      this.localCart.cart_products.push(cartItem)
      let cartData = JSON.stringify(this.localCart)
      localStorage.setItem('CartData',cartData)
      let CartData:any = localStorage.getItem('CartData')
          let cartCount
          let cart:any = JSON.parse(CartData)
          this.cartData = cart.cart_products
          cartCount = this.cartData?.length
          // cart_products
          this.msg.cartCount(cartCount)
    }
  }

  ProductExist(variant_id:any) {
    return this.localCart.cart_products.some(function(el:any) {
      return el.variant_id === variant_id;
    }); 
  }
  getLocalCart(){
    let CartData = localStorage.getItem('CartData')
    if(CartData != null){
      let cart = JSON.parse(CartData)
      this.localCart = cart
    }
    return this.localCart
  }

}
