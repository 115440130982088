<!-- section -->
<div class="conatainer-fluid d-flex flex-wrap p-3 pb-0 bg-white w-100">
    <h4 class="fw-500 min-fit-width d-flex justify-content-between col-12 mb-2 mt-3">
        12 Branches near by you
        <div class="uk-inline">
            <button class="uk-button uk-button-default filter border-0 px-0 me-md-3" type="button"><span class="d-md-inline d-none">Apply more filters</span> <i class="bi bi-funnel ms-2"></i></button>
            <div class="filter-section" uk-dropdown="mode: click">
                <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="form-check me-ms-3">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                        <label class="form-check-label" for="flexCheckChecked">
                            Opened
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                        <label class="form-check-label" for="flexCheckDefault">
                            Closed
                        </label>
                    </div>
                    
                </div>
                <label class="my-2 fw-500" for="">Customer rating</label>
                <div class="d-flex flex-column">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                        <label class="form-check-label d-flex w-100" for="flexCheckChecked">
                            <div class="star-rating me-2">
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star small"></i>
                            </div>
                            & above
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                        <label class="form-check-label d-flex w-100" for="flexCheckChecked">
                            <div class="star-rating me-2">
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star small"></i>
                                <i class="bi bi-star small"></i>
                            </div>
                            & above
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                        <label class="form-check-label d-flex w-100" for="flexCheckChecked">
                            <div class="star-rating me-2">
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star small"></i>
                                <i class="bi bi-star small"></i>
                                <i class="bi bi-star small"></i>
                            </div>
                            & above
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                        <label class="form-check-label d-flex w-100" for="flexCheckChecked">
                            <div class="star-rating me-2">
                                <i class="bi bi-star-fill small"></i>
                                <i class="bi bi-star small"></i>
                                <i class="bi bi-star small"></i>
                                <i class="bi bi-star small"></i>
                                <i class="bi bi-star small"></i>
                            </div>
                            & above
                        </label>
                    </div>
                    <button class="btn btn-main btn-sm mt-2">Apply filter</button>
                </div>
            </div>
        </div>
    </h4>
    <div class="container-fluid p-0 d-flex flex-wrap">
        <div class="featured-shop d-none d-xl-block">
            <div class="card p-0 border-0">
                <div class="d-flex flex-wrap  justify-content-between mb-2">
                    <div class="d-flex align-items-center justify-content-between w-100">
                        
                        <h4 class="my-0 fw-500" for=""><i class="bi bi-geo-alt me-2 fw-bold"></i>Kalayikkavila</h4>
                        <div class="d-flex justify-content-between align-items-center mt-2">
                            <div class="d-flex align-items-center" for="">
                                
                                <div class="star-rating">
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-fill"></i>
                                    <i class="bi bi-star-half"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-2">
                        <span class="bg-success text-white px-2 me-2">opened</span>
                        <label for="">Today 8 am - 11 pm</label>
                    </div>
                    
                </div>
                <div uk-slider>
                    <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1">
                        <ul class="uk-slider-items uk-child-width-1">
                            <li [routerLink]="['/branch']" routerLinkActive="router-link-active" >
                                <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            </li>
                            <li>
                                <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            </li>
                            <li>
                                <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            </li>
                            <li>
                                <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            </li>
                        </ul>
                    </div>
                
                    <ul class="uk-slider-nav uk-dotnav uk-flex-center uk-margin"></ul>
                
                </div>
            </div>
        </div>
        <div class="col-md col-12  multi-slider">
            <div class="uk-position-relative uk-visible-toggle uk-dark ps-md-3 pb-5" tabindex="-1" uk-slider>

                <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-4@m">
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                </ul>
                <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-4@m">
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                    <li class="pb-3 border-0 d-flex" [routerLink]="['/branch']" routerLinkActive="router-link-active">
                        <div class="card p-2 w-90 mx-auto">
                            <img class="w-100" src="../../../assets/assets/shop.png" alt="">
                            
                            <div class="d-flex justify-content-between align-items-center mt-2">
                                <div class="d-flex align-items-center" for="">
                                    <span class="rating me-2 px-2 bg-success text-white small">Open</span>
                                    <div class="star-rating">
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-fill small"></i>
                                        <i class="bi bi-star-half small"></i>
                                    </div>
                                    
                                    <div class="rating-details"></div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-2 text-dark">
                                <label for=""><i class="bi bi-geo-alt me-2"></i>Kalayikkavila</label>
                                
                            </div>
                        </div>
                    </li>
                </ul>
            
                <a class="uk-position-bottom-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"><label class="previous-text" for="">Previous</label></a>
                <a class="uk-position-bottom-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"><label class="next-text" for="">Next</label></a>
            
            </div>
        </div>
    </div>
</div>
<!-- End Section -->