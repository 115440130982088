<div class="d-flex flex-wrap align-items-start w-100 p-4 bg-sidebar cart-body position-relative">
    <div class="loader loader--style2" title="1" *ngIf="loader">
        <!-- <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">
            <path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
            <animateTransform attributeType="xml"
            attributeName="transform"
            type="rotate"
            from="0 25 25"
            to="360 25 25"
            dur="0.6s"
            repeatCount="indefinite"/>
            </path>
        </svg>
        <span>please wait ...</span> -->
        <!-- <div class="lds-dual-ring"></div> -->
    </div>  
    <div class="col d-flex flex-wrap pe-md-4">
        <!-- <h4 class="fw-500 d-flex fz-18 w-100 col-12 mb-3 justify-content-between align-items-center ms-md-0 border-bottom pb-3">
            <div class="d-flex align-items-center">
                <span class="fw-bold min-fit-width col-12 fz-16">Cart ( {{cartList.length}} items )</span>
            </div>
        </h4> -->
        <div class="w-100 cart-listbody mt-3">
            <div class="w-100 text-center mt-4 mb-5" *ngIf="cartList?.length == 0">
                <img src="../../../assets/assets/no-cart.png" alt="" style="width:150px">
                <div class="w-100 text-center mb-4 mt-4 text_empty" >
                    Oops. Your cart is empty !!
                    <!-- <p class="fz-13 fw-normal mt-3">
                        Looks like you have not added any items <br>
                        to your cart
                    </p> -->
                </div>
                <div class="d-flex justify-content-center">
                    <button class="btn_startnow" [routerLink]="['/']" routerLinkActive="router-link-active" >START SHOPPING</button>
                </div>
            </div>
            <div *ngIf="cartShow!=true">
                <app-cart-item
                *ngFor="let cart of cartList; let i = index"
                [productQty]="cart.quantity"
                [productName]="cart.name"
                [productImg]="cart.image"
                [estimateDate]="cart.delivery_time"
                [variant_id]="cart.variant_id"
                [inventory_id]="cart.inventory_id"
                [currentPrice]="cart?.price"
                [totalPrice]="cart?.price"
                [previousPrice]="cart.total_price"
                [type]="typeCart"
                [id]="i"
                [deliveryDate]="cart.estimated_delivery"
                (wishEvent)="addWishList(cart.variant_id)"
                (RemoveCartEvent)="removeCart(cart.variant_id)"
                [available_qty]="cart.available_qty"
                [virtual_stock]="cart.virtual_stock"
                [max_order_limit]="cart?.max_sales_order"
                [min_order_limit]="cart?.min_sales_order"
                [stock_count]="cart?.selling_quantity"
                >
            </app-cart-item>
            </div>
            <div *ngIf="cartShow==true">
                <app-cart-item
                *ngFor="let cart of cartList; let i = index"
                [productQty]="cart.quantity"
                [productName]="cart.name"
                [productImg]="cart.image"
                [estimateDate]="cart.delivery_time"
                [variant_id]="cart.variant_id"
                [inventory_id]="cart.inventory_id"
                [currentPrice]="cart?.price"
                [totalPrice]="cart?.price"
                [previousPrice]="cart.total_price"
                [type]="typeCart"
                [id]="i"
                [deliveryDate]="cart.estimated_delivery"
                (wishEvent)="addWishList(cart.variant_id)"
                (RemoveCartEvent)="removeCart(cart.variant_id)"
                [available_qty]="cart.available_qty"
                [virtual_stock]="cart.virtual_stock"
                [max_order_limit]="cart?.max_order_limit"
                [min_order_limit]="cart?.min_order_limit"
                [stock_count]="cart?.stock_count"
                >
            </app-cart-item>
            </div>
        </div>
    </div>
    <div class="cart-sidebar flex-wrap ms-lg-5 mt-md-0 mt-4" *ngIf="cartList?.length != 0">
        <div class="cart-total">
            <div class="cart-total__header">
                Cart Details
            </div>
            <div class="cart-total__body">
                <label for="" class="cart-total__body--item">
                    <span>Price ({{cartList.length}} items)</span>
                    <span>{{totalCart | currency:'AED'}}</span>
                </label>
                <div class="cart-total__control">
                    <label for="" class="cart-total__control--item">
                        <span>Total Amount</span>
                        <span>{{totalCart | currency:'AED'}}</span>
                    </label>
                    <!-- <span class="fz-12 text-success">You will save ₹00.00 on this order</span> -->
                    <!-- [disabled]="cartList?.length === 0" (click)="checkout()" -->
                    <button class="btn btn-primary text-uppercase"  *ngIf="!isLogin" [routerLink]="['/auth']" routerLinkActive="router-link-active" >Proceed to Buy</button>
                    <button class="btn btn-primary text-uppercase"  *ngIf="isLogin" href="#exampleModal" uk-toggle >Proceed to Buy</button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 my-2">
    </div>
    <div class="container-fluid d-flex flex-wrap mt-3 p-0 w-100">
        <h4 class="fw-bold min-fit-width col-12 mb-4 fz-22">{{recomandedProduct.Display_name}}</h4>
        <div class="col-12 multi-slider">
            <div class="uk-position-relative uk-visible-toggle uk-dark p-0" tabindex="-1" uk-slider>
                <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-3@s uk-child-width-1-5@m uk-child-width-1-6@l uk-child-width-1-6@xl">
                    <li class="pb-3 border-0 d-flex slider-item" *ngFor="let product of recomandedProduct.results">
                        <app-product-card-two
                            [products]="product"                  
                            (cartEvent)="addCart(product)"
                            (wishEvent)="addWishList(product)"
                            [wishListed]="isWishlisted(product.id)"
                            [qtyRefresh]="true"
                        ></app-product-card-two>
                    </li>
                </ul>
                <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
            </div>
        </div>
    </div>
    <ul class="nav nav-pills mb-3 border-bottom w-100 nav-tab-1 fit-height" id="pills-tab" role="tablist" *ngIf="isLogin">
        <li class="nav-item me-3" role="presentation">
        <button class="nav-link active" id="pills-savelater-tab" data-bs-toggle="pill" data-bs-target="#pills-savelater" type="button" role="tab" aria-controls="pills-savelater" aria-selected="true">Saved for later</button>
        </li>
        <li class="nav-item me-3" role="presentation">
        <button class="nav-link" id="pills-again-tab" data-bs-toggle="pill" data-bs-target="#pills-again" type="button" role="tab" aria-controls="pills-again" aria-selected="false">Buy it again</button>
        </li>
    </ul>
    <div class="tab-content w-100" id="pills-tabContent" *ngIf="isLogin">
        <div class="tab-pane fade show active w-md-75 w-0" id="pills-savelater" role="tabpanel" aria-labelledby="pills-savelater-tab">
            <div class="w-100 text-start pb-3" *ngIf="saveLater?.length == 0">
                <!-- <img src="../../../assets/assets/no-order.png" alt="" style="width:250px"> -->
                <div class="w-100 text-start text_empty">
                    Oops. No data here !!
                    <!-- <p class="fz-13 fw-normal mt-3">
                        Looks like you have not added any items <br>
                        to your cart
                    </p> -->
                </div>
            </div>
            <app-cart-item
                *ngFor="let later of saveLater; let i = index"
                [productName]="later.variant_name"
                [productImg]="later.image"
                [estimateDate]="later.estimateDate"
                [variant_id]="later.variant_id"
                [inventory_id]="later.inventory_id"
                [maxQty]="later.maxQty"
                [currency]="later.currency"
                [currentPrice]="later.selling_price"
                [previousPrice]="later.previousPrice"
                [avaliablestock]="later.available_qty"
                [Available_stock]="later?.is_stock"
                [available_qty]="later?.available_qty"
                [virtual_stock]="later?.virtual_stock"
                [max_order_limit]="later?.max_sales_order"
                [min_order_limit]="later?.min_sales_order"
                [stock_count]="later?.selling_quantity"
                type="SAVEFORLATER"
                [id]="i"
                (RemoveEvent)="remove()"
            >
            </app-cart-item>
        </div>
        <div class="tab-pane fade w-100" id="pills-again" role="tabpanel" aria-labelledby="pills-again-tab">
            <div class="w-100 text-start pb-3" *ngIf="buyAgainListStatus == true">
                <!-- <img src="../../../assets/assets/buyitagain.png" alt="" style="width:200px"> -->
                <div class="w-100 text-start text_empty">
                    No product purchased !
                    <!-- <p class="fz-13 fw-normal mt-3">
                        Purchase a product first then buy it again if you needed again
                    </p> -->
                </div>
            </div>
            <app-cart-item
                *ngFor="let wish of buyAgainList; let i = index"
                [productName]="wish?.variant_name"
                [category]="wish?.category_name"
                [productImg]="wish?.image"
                [estimateDate]="wish.estimateDate"
                [maxQty]="wish.available_qty"
                [currency]="currency"
                [currentPrice]="wish?.selling_price"
                [previousPrice]="wish?.selling_price"
                [variant_id]="wish.variant_id"
                [inventory_id]="wish.inventory_id"
                [type]="buy"
                [id]="wish?.variant_id"
                [available_qty]="wish.available_qty"
                [virtual_stock]="wish.virtual_stock"
                [max_order_limit]="wish?.max_sales_order"
                [min_order_limit]="wish?.min_sales_order"
                [stock_count]="wish?.selling_quantity"
                [Available_stock]="wish?.is_stock"
                (RemoveWishEvent)="removeWish(wish?.variant_id)"
                >
            </app-cart-item>
        </div>
    </div>
</div>
 
   
    <div id="exampleModal" class="uk-flex-top" uk-modal="stack:true">
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical px-2" style="width: 800px;">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="address-modal__body">
                <h4 class="fw-500 d-flex flex-wrap fz-18 w-100 col-12 mb-3 justify-content-between align-items-center ms-md-0" *ngIf="!addNew">
                    <div class="d-flex flex-wrap align-items-center justify-content-between border-bottom p-3 px-5 w-100">
                        Address
                        <button class="btn btn-primary fz-13 text-uppercase" (click)="addNew = !addNew" >Add address</button> 
                    </div>
                </h4>
                <h4 class="fw-500 d-flex flex-wrap fz-18 w-100 col-12 mb-3 justify-content-between align-items-center ms-md-0 px-4" *ngIf="addNew">
                    <div class="d-flex flex-wrap align-items-center justify-content-between border-bottom p-3  w-100">
                        Address
                        <button class="cb fz-15 btn-show p-0 border-bottom text-uppercase" (click)="showAvailableAddress()">Show Available Address</button> 
                    </div>
                </h4>
                <div class="col-12 d-flex flex-wrap p-3" *ngIf="!isAddress">
                    <div class="col-12 row mx-0 mb-3">
                        <span>No address found please create</span>
                    </div>
                    <div class="container-fluid p-5">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Full name</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="address" required placeholder="" aria-label="City">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Buliding Name</label>
                                <input type="text" [(ngModel)]="buildingname" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Building No</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingno" placeholder="" aria-label="">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Land mark</label>
                                <input type="text" [(ngModel)]="landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Street</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="street" placeholder="" aria-label="">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">City</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="city" required placeholder="city" aria-label="City">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Country</label>
                                <input type="text" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"  disabled="true" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Emarites</label>
                                <select class="form-select" [(ngModel)]="state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Contact</label>
                                <input type="number" maxlength="9"  class="form-control fz-14" [(ngModel)]="contact"  placeholder="eg:92967389" aria-label="Fax">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0 d-flex justify-content-between">
                            <button class="btn  fz-13 px-4 border-bottom  text-uppercase btn_width_250" (click)="chooseCurrentLocationNew()"><i class="px-2">Choose Current Location</i></button> 
                            <button type="button" class="btn btn-primary px-4 btn_width_100 "(click)="addressSendotp()">Create</button>
                        </div>
                    </div>
                </div>
                <div class="col-12" *ngIf="isAddress">
                    <div class="container-fluid p-5" *ngIf="addNew">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Full name</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="address" required placeholder="" aria-label="City" >
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Buliding Name</label>
                                <input type="text" [(ngModel)]="buildingname" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Building No</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingno" placeholder="" aria-label="">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Land mark</label>
                                <input type="text" [(ngModel)]="landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Street</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="street" placeholder="" aria-label="">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">City</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="city" required placeholder="" aria-label="City">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Country</label>
                                <input type="text" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"  disabled="true" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Emarites</label>
                                <select class="form-select" [(ngModel)]="state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                            </div>
                            <div class="col-md-6 col-sm-6 col-12 mb-3">
                                <label for="fname" class="form-label fz-14 mb-1 ">Contact</label>
                                <input type="number" class="form-control fz-14" [(ngModel)]="contact"   placeholder="eg:978678567" aria-label="Fax" maxlength="9" >
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0 d-flex justify-content-between">
                            <button class="btn fz-13 px-4 border-bottom text-uppercase btn_width_250" (click)="chooseCurrentLocationNew()">Choose Current Location</button> 
                            <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary-outlined px-4 btn_width_100 " (click)="addressSendotp()">Create</button>
                            </div>    
                        </div>
                    </div>
                    <div class="col-12 d-flex flex-wrap p-5" *ngIf="isEditAddress && !addNew">
                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label fz-14 mb-1 ">Full name</label>
                                    <input type="text" class="form-control fz-14"   [(ngModel)]="edit_address.full_name" required placeholder="" aria-label="City">
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label fz-14 mb-1 ">Buliding Name</label>
                                    <input type="text" [(ngModel)]="edit_address.building_name" class="form-control fz-14" placeholder="" aria-label="First name">
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label fz-14 mb-1 ">Building No</label>
                                    <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.building_no" placeholder="" aria-label="">
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label fz-14 mb-1 ">Land mark</label>
                                    <input type="text" [(ngModel)]="edit_address.landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label fz-14 mb-1 ">Street</label>
                                    <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.street_name" placeholder="" aria-label="">
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label fz-14 mb-1 ">City</label>
                                    <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.city" required placeholder="" aria-label="City">
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label fz-14 mb-1 ">Country</label>
                                    <input type="text" [(ngModel)]="edit_address.country" [ngModelOptions]="{standalone: true}"  disabled="true" class="form-control fz-14" placeholder="" aria-label="First name">
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label fz-14 mb-1 ">Emarites</label>
                                    <select class="form-select" [(ngModel)]="edit_address.state" aria-label="Default select example">
                                        <option value="Abu Dhabi">Abu Dhabi</option>
                                        <option value="Dubai">Dubai</option>
                                        <option value="Sharjah">Sharjah</option>
                                        <option value="Ajman">Ajman</option>
                                        <option value="Umm Al Quwain">Umm Al Quwain</option>
                                        <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                        <option value="Fujairah">Fujairah</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-6 col-12 mb-3">
                                    <label for="fname" class="form-label mb-1 ">Contact</label>
                                    <input type="number" class="form-control fz-14" [(ngModel)]="contactMobile"  placeholder="" aria-label="Fax" (change)="changeNumberChangeBtn()">
                                </div>
                            </div>
                            <div class="col-12 row mt-3 mx-0 justify-content-between">
                                <button class="btn btn_width_250 fz-13 px-3 border-bottom text-uppercase " (click)="chooseCurrentLocationEdit()">Choose Current Location</button> 
                                <div class="col d-flex justify-content-end">
                                    <button type="button" class="btn btn-primary px-3 me-3" (click)="showAvailableAddress()">Cancel</button>
                                    <button type="button" *ngIf="changeNumber==true" class="btn btn-primary-outlined" (click)="updateSendotp()">Update Address</button>
                                    <button type="button" *ngIf="changeNumber==false" class="btn btn-primary-outlined" (click)="updateAddress(edit_address.id)">Update Address</button>
                                </div>    
                            </div>
                        </div>
                    </div>
                    <div class="w-100 p-4 pb-2 bg-white d-flex align-items-start" *ngIf="!addNew && !isEditAddress">
                        <label class="col mb-0 d-flex flex-wrap" for="">
                            <label for="" class="fw-500 w-100">
                                Shipping Address
                            </label>
                            <div class="me-2 mt-3 position-relative address-box-small" *ngFor="let address of userDetailAdress;let i=index">
                                <span class="edit-address" (click)="editAddress(address)">Edit</span>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="shipping-address" [id]="shippingAddressIdPlaceholder+i" [checked]="i == selectAddress(address.is_default,i)" (change)="selectShippingAddress(address)">
                                    <label class="form-check-label" [for]="shippingAddressIdPlaceholder+i">
                                        <span class="fw-bold">{{address?.full_name}} </span>
                                        <p class="text-break m-0 p-0 scroll">
                                            <br>
                                        <span>{{address?.location}},</span> <span>{{address?.building_name}},</span>  <span>{{address?.landmark}},</span>
                                            <br>
                                            <span>{{address?.street_name}}</span>,<span>{{address?.city}},</span> <span>{{address?.country}},</span>
                                            <br>
                                            <span>{{address?.state}}, {{address?.contact}},</span>
                                        </p>
                                    </label>
                                </div>   
                            </div> 
                        </label>
                    </div>
                    <div class="w-100 p-4 bg-white d-flex flex-wrap align-items-start mb-3" *ngIf="!changeBillingAddress && !addNew && !isEditAddress">
                        <label class="col-12 mb-0 d-flex flex-wrap" for="">
                            <label for="" class="fw-500 w-100">Billing Address</label>
                            <div class="me-2 mt-3 address-box-small">
                                <label class="">
                                    {{billingAddress?.full_name}}, 
                                    <br>
                                    {{billingAddress?.location}}, {{billingAddress?.building_name}}, {{billingAddress?.landmark}},
                                    <br>
                                    {{billingAddress?.street_name}}, {{billingAddress?.city}}, {{billingAddress?.country}},
                                    <br>
                                    {{billingAddress?.state}}, {{billingAddress?.contact}}
                                </label>
                            </div>              
                        </label>
                        <a class="text-primary fz-13" (click)="changeBillingAddress = true"> Change billing address </a>
                    </div>
                    <div class="w-100 p-4 bg-white d-flex align-items-start mb-3" *ngIf="changeBillingAddress && !addNew && !isEditAddress">
                        <label class="col mb-0 d-flex flex-wrap" for="">
                            <label for="" class="fw-500 w-100">
                                Billing Address
                            </label>
                            <div class="me-2 mt-3 address-box-small" *ngFor="let address of userDetailAdress;let i=index">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="billing-address" [id]="billingAddressIdPlaceholder+i" [checked]="i == selectAddress(address.is_default,i)" (change)="selectBillingAddress(address)">
                                    <label class="form-check-label" [for]="billingAddressIdPlaceholder+i">
                                        {{address?.full_name}}, 
                                        <br>
                                        {{address?.location}}, {{address?.building_name}}, {{address?.landmark}},
                                        <br>
                                        {{address?.street_name}}, {{address?.city}}, {{address?.country}},
                                        <br>
                                        {{address?.state}}, {{address?.contact}}
                                    </label>
                                </div>   
                            </div> 
                        </label>
                    </div>
                </div>
                <div class="col-12 d-flex flex-wrap justify-content-end p-3 pb-4 px-sm-5 border-top" *ngIf="isAddress && !addNew && !isEditAddress">
                    <button class="btn btn-primary text-uppercase me-3 uk-modal-close">Cancel</button>
                    <button class="btn btn-primary text-uppercase uk-modal-close" [disabled]="cartList?.length === 0" (click)="checkout(cartList)" >Proceed to Pay</button>
                </div>
            </div>
        </div>
    </div>

    <p-dialog header="" [(visible)]="cartpageDisplay">
        <div class="row col-12">
            <div class="col-lg-12 col-12 mt-lg-0 mt-2 mt-lg-0 mt-2 form-outline w-100 position-relative">
                <input type="text" class="form-control" name='otpname' placeholder="----" [(ngModel)]="addressCreateOtp"/>
                <!-- <span class="fz-12 text-danger fw-bold" *ngIf="optWarnning==true">check the opt</span> -->
                <button class="resend-otp" title="Resend Otp" >
                <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
                <i class="ms-2 fw-14 fw-bold" (click)="resendOtpNew()" *ngIf="subscribeTimer ==0">Resend</i>
                </button>
                <!-- <span class="fz-12 text-danger fw-bold" *ngIf="optWarnning==true">check the opt</span> -->
            </div>
            <div class="col-12 d-flex justify-content-center mt-3">
                <button type="button" class="btn btn-primary" [disabled]="subscribeTimer==0" (click)="createAddress()">Send</button>
            </div>
        </div> 
    </p-dialog>

    <p-dialog header="" [(visible)]="updateAddressOtp">
        <div class="row col-12">
            <div class="col-lg-12 col-12 mt-lg-0 mt-2 mt-lg-0 mt-2 form-outline w-100 position-relative">
                <input type="text" class="form-control"name='email' placeholder="----" [(ngModel)]="addressUpdateOtp"/>
                <!-- <span class="fz-12 text-danger fw-bold" *ngIf="optWarnning==true">check the opt</span> -->
                <button class="resend-otp" title="Resend Otp" >
                  <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
                  <i class="ms-2 fw-14 fw-bold" (click)="resendUpdateOtpNew()" *ngIf="subscribeTimer ==0">Resend</i>
                </button>
            </div>
            <div class="col-12 d-flex justify-content-center mt-3">
                <button type="button" class="btn btn-primary" (click)="UpdateAddressWithOtp(edit_address.id)" [disabled]="subscribeTimer==0">Send</button>
            </div>
        </div> 
    </p-dialog>

