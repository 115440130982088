import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { CategoryService } from 'src/app/services/category.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';

declare var delay:any

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  productRoute= {name:String};
  showMoreDivision = true
  showAvailbaleAddress = false
  editAddress = false
  deleteAddress = false
  showMap = true
  deleteAddressId:any = 0
  editAddressId:any = 0
  addressList:any = {
    full_name:'',
    building_no:'',
    building_name:'',
    landmark:'',
    street_name:'',
    city:'',
    country:'UAE',
    state:'',
    contact:''

  }
  addressListError:any = {
    full_name:false,
    building_no:false,
    building_name:false,
    landmark:false,
    street_name:false,
    city:false,
    country:false,
    state:false,
    contact:false

  }
  mapHeight = "480px"
  addressHolder = "address_hold_"
  defualtAddressId:any
  newAddress = false
  shippingAddress:any
  billingAddress:any
  userDetailAdress:any 
  searchLocation =""
  showMenu = 4
  logo = environment.logo
  zoom = 20
  map!: google.maps.Map
  service!: google.maps.places.PlacesService;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    maxZoom: 15,
    minZoom: 8,
    mapTypeControl:false
  }
  markers:any =[]
  user:any={}
  allCategory:any =[] 
  subCategoryIndex = 0
  productGroupIndex = 0
  divisionGroupIndex=0
  divisionMenuList:any[] =[]
  currentLocation = ""
  locationDetails:any
  language = "Arabic"
  allCategoryDrop = false
  divisionDrops=false
  pincode = 0
  onSearchFocus = false
  searchSuggesion:any = []
  companyDetails={
    email:"marketplace@gmail.com",
    phone:"+971 2587 5487",
    logo:"../../../assets/images/logo.svg"
  }
  isLogin = false
  menuId = 0
  categoryId = 0
  hoverMenu = false
  search:any
  cartData:any
  cartCount= 0
  subCategory:any= [
   
  ]
  menuList:any=[
    
  ]
  sidenav = false;
  
  guest={
    profile_pic: "",
    username: "Sign in"
  }
  is_login=false
  greetMessage="Good morning";
  divisionAllCategory:any=[]
  Categorytype = environment.specialGroups
  divisionSubcateIndex=0
  divisionGroupIndes=0
  divisionId:any
  divisionCateId:any
  divisionSubCateId:any
  divisiongroupId:any
  divisionCateArray:any=[]
  subcategoryDivision:any=[]
  isClose=true
  contactMobile:any=""
  display=false
  timeLeft = 30
  subscribeTimer = 30
  addressCreateOtp=""
  addressUpdateOtp=""
  updateAddressOtp=false
  changeNumber=false
  constructor(
    private auth:AuthService,
    public translate: TranslateService,
    private _cartService: CartService,
    private productService:ProductService, 
    private messageService:MessengerService, 
    private router: Router, 
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private categoryService:CategoryService) {
      translate.addLangs(['en', 'arb']);
      translate.setDefaultLang('en');
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang.match(/en|arb/) ? browserLang : 'en');
      let user:any = localStorage.getItem("marketplaceUser")
      let userData = JSON.parse(user)
      this.messageService.getCartCount().subscribe((data:any)=>{
        console.log(data,"-------------------");
        this.cartCount=data
      })
        this.messageService.getSearchWord().subscribe((data:any)=>{
      this.search=data
    })
      
      if(JSON.parse(user) === null){
        this.user = this.guest
      }else{
        this.user = JSON.parse(user) 
      }
      if(userData != null){
        this._cartService.getCart().subscribe((data:any)=>{
          this.cartData = data.data
          console.log(this.cartData,"cartData");
          this.cartCount = this.cartData?.length
        })
      }else{
        this.is_login=true
      }
      let CartData = localStorage.getItem('CartData')
      if(CartData != null){
        let cart = JSON.parse(CartData)
        this.cartData = cart.cart_products
        this.cartCount = this.cartData?.length
        // cart_products
      }
      this.messageService.getSegments().subscribe((data:any)=>{
        this.Categorytype = data
        this.productService.getDivisionList(this.Categorytype).subscribe((data:any)=>{
          this.divisionMenuList = data.data?.products
          this.divisionMenuList= this.divisionMenuList.sort((a:any, b:any) => parseFloat(a.Priorty) - parseFloat(b.Priorty))
        })
        this.categoryService.getAllCategory(this.Categorytype).subscribe((data:any)=>{
          this.allCategory = data.data
          console.log(this.allCategory,"allCategory");
        })
      })
      if(userData != null){
        this.auth.getUserAddress().subscribe((data:any)=>{
          let is_default=data.data.customer_user_data.filter((data:any)=>data.is_default==true)
          if(data.data.customer_user_data.length == 0){
            this.showAvailbaleAddress = false
            
          }else{
            this.showAvailbaleAddress = true
            if(data.data.customer_user_data.length <= 1){
              this.billingAddress = data.data.customer_user_data[0]
              this.shippingAddress = data.data.customer_user_data[0]
              this.defualtAddressId = this.shippingAddress.id
              let current_address:any = localStorage.getItem('current-location')
              current_address = JSON.parse(current_address)
              console.log(current_address);
              
              if(current_address==null){
                this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
              }
              
            }else{
              if(is_default.length >0){
                this.billingAddress = is_default[0]
                this.shippingAddress = is_default[0]
                this.defualtAddressId = this.shippingAddress.id
                let current_address:any = localStorage.getItem('current-location')
                current_address = JSON.parse(current_address)
                console.log(current_address);
                
                if(current_address==null){
                  this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
                }
              }else{
                this.billingAddress = data.data.customer_user_data[0]
                this.shippingAddress = data.data.customer_user_data[0]
                this.defualtAddressId = data.data.customer_user_data[0].id
                let current_address:any = localStorage.getItem('current-location')
                current_address = JSON.parse(current_address)
                console.log(current_address);
                
                if(current_address==null){
                  this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
                }
              }
            }
            this.userDetailAdress = data.data.customer_user_data
          }
        })
        this.messageService.getRefreshData().subscribe((data:any)=>{
          this.auth.getUserAddress().subscribe((data:any)=>{
            let is_default=data.data.customer_user_data.filter((data:any)=>data.is_default==true)
            if(data.data.customer_user_data.length == 0){
              this.showAvailbaleAddress = false
              
            }else{
              this.showAvailbaleAddress = true
              if(data.data.customer_user_data.length <= 1){
                this.billingAddress = data.data.customer_user_data[0]
                this.shippingAddress = data.data.customer_user_data[0]
                this.defualtAddressId = this.shippingAddress.id
                let current_address:any = localStorage.getItem('current-location')
                current_address = JSON.parse(current_address)
                console.log(current_address);
                
                if(current_address==null){
                  this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
                }
                
              }else{
                if(is_default.length >0){
                  this.billingAddress = is_default[0]
                  this.shippingAddress = is_default[0]
                  this.defualtAddressId = this.shippingAddress.id
                  let current_address:any = localStorage.getItem('current-location')
                  current_address = JSON.parse(current_address)
                  console.log(current_address);
                  
                  if(current_address==null){
                    this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
                  }
                }else{
                  this.billingAddress = data.data.customer_user_data[0]
                  this.shippingAddress = data.data.customer_user_data[0]
                  this.defualtAddressId = data.data.customer_user_data[0].id
                  let current_address:any = localStorage.getItem('current-location')
                  current_address = JSON.parse(current_address)
                  console.log(current_address);
                  
                  if(current_address==null){
                    this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
                  }
                }
              }
              this.userDetailAdress = data.data.customer_user_data
            }
          })
          
        })
      }
      this.categoryService.getDivisionCategory().subscribe((data:any)=>{
        this.divisionAllCategory=data.data
        console.log(data.data,"divisionAllCategory");
      })
    }

  ngOnInit(): void {
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    console.log(this.defualtAddressId,'dsdsd');
    if(userData != null){
      this._cartService.getCartCount().subscribe((data:any)=>{
        console.log(data.data,"tttttttttttttttt");
        this.cartCount=data.data?.count
      })
      this.auth.getUserAddress().subscribe((data:any)=>{
        let is_default=data.data.customer_user_data.filter((data:any)=>data.is_default==true)
        if(data.data.customer_user_data.length == 0){
          this.showAvailbaleAddress = false
          
        }else{
          this.showAvailbaleAddress = true
          if(data.data.customer_user_data.length <= 1){
            this.billingAddress = data.data.customer_user_data[0]
            this.shippingAddress = data.data.customer_user_data[0]
            this.defualtAddressId = this.shippingAddress.id
            let current_address:any = localStorage.getItem('current-location')
              current_address = JSON.parse(current_address)
              console.log(current_address);
              
              if(current_address==null){
                this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
              }
          }else{
            if(is_default.length >0){
              this.billingAddress = is_default[0]
              this.shippingAddress = is_default[0]
              this.defualtAddressId = this.shippingAddress.id
              let current_address:any = localStorage.getItem('current-location')
              current_address = JSON.parse(current_address)
              console.log(current_address);
              
              if(current_address==null){
                this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
              }
            }else{
              this.billingAddress = data.data.customer_user_data[0]
              this.shippingAddress = data.data.customer_user_data[0]
              this.defualtAddressId = data.data.customer_user_data[0].id
              let current_address:any = localStorage.getItem('current-location')
              current_address = JSON.parse(current_address)
              console.log(current_address);
              
              if(current_address==null){
                this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
              }
            }
          }
          this.userDetailAdress = data.data.customer_user_data
          console.log(this.userDetailAdress,"userDetailAdress");
          
        }
      })
      this.messageService.getRefreshData().subscribe((data:any)=>{
        this.auth.getUserAddress().subscribe((data:any)=>{
          let is_default=data.data.customer_user_data.filter((data:any)=>data.is_default==true)
          if(data.data.customer_user_data.length == 0){
            this.showAvailbaleAddress = false
            
          }else{
            this.showAvailbaleAddress = true
            if(data.data.customer_user_data.length <= 1){
              this.billingAddress = data.data.customer_user_data[0]
              this.shippingAddress = data.data.customer_user_data[0]
              this.defualtAddressId = this.shippingAddress.id
              let current_address:any = localStorage.getItem('current-location')
              current_address = JSON.parse(current_address)
              console.log(current_address);
              
              if(current_address==null){
                this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
              }
              
            }else{
              if(is_default.length >0){
                this.billingAddress = is_default[0]
                this.shippingAddress = is_default[0]
                this.defualtAddressId = this.shippingAddress.id
                let current_address:any = localStorage.getItem('current-location')
                current_address = JSON.parse(current_address)
                console.log(current_address);
                
                if(current_address==null){
                  this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
                }
              }else{
                this.billingAddress = data.data.customer_user_data[0]
                this.shippingAddress = data.data.customer_user_data[0]
                this.defualtAddressId = data.data.customer_user_data[0].id
                let current_address:any = localStorage.getItem('current-location')
                current_address = JSON.parse(current_address)
                console.log(current_address);
                
                if(current_address==null){
                  this.requestLocation(this.billingAddress?.location+','+this.billingAddress?.building_name+','+this.billingAddress?.landmark)
                }
              }
            }
            this.userDetailAdress = data.data.customer_user_data
          }
        })
        
      })
    }
    if(this.defualtAddressId == undefined || userData == null){
      let current_address:any = localStorage.getItem('current-location')
      current_address = JSON.parse(current_address)
      console.log(current_address);
      
      if(current_address==null){
        
        navigator.geolocation.getCurrentPosition((position) => {
          this.center = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          
          if(this.center.lat == undefined) {
            this.center = {
              lat: 25.3937187,
              lng: 55.4660054,
            }
          }
        })
      }else{
        this.center = current_address.results[0].geometry
      }
      
    }
    this.messageService.sendSegments(this.Categorytype)

    this.categoryService.getAllCategory(this.Categorytype).subscribe((data:any)=>{
      this.allCategory = data.data
    })

    this.productService.getDivisionList(this.Categorytype).subscribe((data:any)=>{
      this.divisionMenuList = data.data.products
      this.divisionMenuList= this.divisionMenuList.sort((a:any, b:any) => parseFloat(a.Priorty) - parseFloat(b.Priorty))
    })
    
    setTimeout(() => {
      if(window.innerWidth <= 1200){
        if(this.divisionMenuList.length > 3){
          this.showMoreDivision = true
        }else{
          this.showMoreDivision = false
        }
        this.showMenu = 3
      }
      if(window.innerWidth <= 900){
        this.mapHeight = "300px"
      }
      if(window.innerWidth <= 992){
        if(this.divisionMenuList.length > 2){
          this.showMoreDivision = true
        }else{
          this.showMoreDivision = false
        }
        this.showMenu = 2
      }
      if(window.innerWidth > 1200){
        if(this.divisionMenuList.length > 4){
          this.showMoreDivision = true
        }else{
          this.showMoreDivision = false
        }
        this.showMenu = 4
      }
      if(window.innerWidth >= 1400){
        if(this.divisionMenuList.length > 5){
          this.showMoreDivision = true
        }else{
          this.showMoreDivision = false
        }
        this.showMenu = 5
      }
      if(window.innerWidth >= 1600){
        if(this.divisionMenuList.length > 6){
          this.showMoreDivision = true
        }else{
          this.showMoreDivision = false
        }
        this.showMenu = 6
      }
    }, 3500);
    
    let special = localStorage.getItem('specialGroup')
    if(special !=null){
      this.Categorytype = special
    }else{
      this.Categorytype = environment.specialGroups
    }

    if(JSON.parse(user) === null){
      this.user = this.guest
    }else{
      this.user = JSON.parse(user) 
    } 
    this.location()
  }

  chooseCurrentLocationEdit(){
    this.currentLocationFinder()
    setTimeout(() => {
      
      let user: any = localStorage.getItem('marketplaceUser');
      let userProfile = JSON.parse(user)
      if(userProfile !=null){
        this.auth.getUserProfile().subscribe((data:any)=>{
          this.addressList.full_name  = data.data.fname + ' ' + data.data.lname
          this.addressList.contact = data.data.alternative_mobile_no
        })
      }
    }, 2000);
    
  }

  onSeachRoute(e:any){
    this.categoryService.getSearchSuggesion(e).subscribe((data:any)=>{
      this.searchSuggesion = data.data
      this.isClose=true
      // this.messageService.sendSearchWord(e)
    })
  }

  // onSearch(e:any){
  //   this.messageService.searchSend(e)
  // }
  // getCart(){
  //   this.cartItems.getCart().subscribe((data:any)=>{
  //     this.cartCount = data.data.length
  //   })
    
  // }
  openNav() {
    this.sidenav = !this.sidenav
  }
  onCartNavigate(){
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    this.router.navigate(['/cart'])
    // if(userData == null) {
    //   this.router.navigate(['/auth'])
    // }else{
    //   this.router.navigate(['/cart'])
    // }
  }
  onUserNavigate(){
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    if(userData == null) {
      this.router.navigate(['/auth'])
      window.scrollTo(0, 0);
    }else{
      this.router.navigate(['/profile'])
      window.scrollTo(0, 0);
    }
  }
 
  menuRoute(route:any){
    this.router.navigate([route])
  }
  onMenuHover(id:number){
    this.hoverMenu = true
    this.menuId = id
    
  }
  categoryHover(id:any){
    this.categoryId = id
  }

  UpdateAddress(id:any){
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    let code = "+971"
    let addresss = {}
    addresss = {
      id : id,
      user_code : userProfile.customer_usercode,
      street_name: this.addressList.street_name,
      building_no: this.addressList.building_no,
      full_name : this.addressList.full_name,
      building_name : this.addressList.building_name,
      country : this.addressList.country,
      state : this.addressList.state,
      city : this.addressList.city,
      landmark : this.addressList.landmark,
      contact : '+971'+this.contactMobile,
      is_active : true,
      address_type : null,
    }
    this.auth.updateUserAddress(addresss,id)
    this.editAddressId = "",
    this.newAddress = false
    this.editAddress = false
    this.addressList.street_name = ""
    this.addressList.building_name = ""
    this.addressList.full_name = ""
    this.addressList.building_no =""
    this.addressList.city = ""
    this.addressList.landmark = ""
    this.contactMobile = ""
    // this.addressList.country = ""
    this.addressList.state = ""
  }

  EditAddress(address:any,id:any){
    console.log(address,"address.building_no");
    
    this.editAddressId = id
    this.newAddress = !this.newAddress
    this.editAddress = !this.editAddress
    this.addressList.street_name = address.street_name
    this.addressList.building_name = address.building_name 
    this.addressList.full_name = address.full_name
    this.addressList.building_no = address.building_no
    this.addressList.city = address.city
    this.addressList.landmark = address.landmark 
    var number = address?.contact.slice(4); 
    this.contactMobile = Number(number)
    this.contactMobile = this.contactMobile
    this.addressList.country = address.country
    this.addressList.state = address.state
  }

  deleteConfirm(id:any){
    this.deleteAddressId = id
  }

  deleteThisAddress(id:any){
    // setTimeout(() => {
    //   window.location.reload()
    // }, 500);
    this.auth.deleteUserAddress(id)
  }

  showHideAddress(){
    this.newAddress = !this.newAddress
    if(window.innerWidth <= 900){
      if(!this.newAddress){
        this.showMap = false
      }else{
        this.showMap = true
      }
    }
  }

  AddAddress(){
    // if(this.addressList.full_name == ''){
    //   this.addressListError.full_name = true
    // }else{
    //   this.addressListError.full_name = false
    // }
    // if(this.addressList.location == ''){
    //   this.addressListError.location = true
    // }else{
    //   this.addressListError.location = false
    // }
    // if(this.addressList.building_name == ''){
    //   this.addressListError.building_name = true
    // }else{
    //   this.addressListError.building_name = false
    // }
    // if(this.addressList.landmark == ''){
    //   this.addressListError.landmark = true
    // }else{
    //   this.addressListError.landmark = false
    // }
    // if(this.addressList.street_name == ''){
    //   this.addressListError.street_name = true
    // }else{
    //   this.addressListError.street_name = false
    // }
    // if(this.addressList.city == ''){
    //   this.addressListError.city = true
    // }else{
    //   this.addressListError.city = false
    // }
    // if(this.addressList.state == ''){
    //   this.addressListError.state = true
    // }else{
    //   this.addressListError.state = false
    // }
    // if(this.addressList.contact == ''){
    //   this.addressListError.contact = true
    // }else{
    //   this.addressListError.contact = false
    // }
    // if(this.addressListError.full_name || this.addressListError.location || this.addressListError.building_name || this.addressListError.landmark || this.addressListError.street_name || this.addressListError.city || this.addressListError.state || this.addressListError.contact)return
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    let addressDataAdd = {
      street_name: this.addressList.street_name,
      building_no: this.addressList.building_no,
      user_code: userProfile.customer_usercode, 
      full_name: this.addressList.full_name,
      building_name: this.addressList.building_name,
      city: this.addressList.city, 
      landmark: this.addressList.landmark,
      contact: '+971'+this.contactMobile,
      is_active: true,
      address_type: null,
      country: this.addressList.country,
      state: this.addressList.state
    }
    this.auth.createAddress(addressDataAdd)
    // this.contactMobile=""
  }

  cancelAddress(){
    this.newAddress =!this.newAddress
    this.addressList = {
      full_name:'',
      location:'',
      building_name:'',
      landmark:'',
      street_name:'',
      city:'',
      country:'UAE',
      state:'',
      contact:''
    }
    this.addressListError = {
      full_name:false,
      location:false,
      building_name:false,
      landmark:false,
      street_name:false,
      city:false,
      country:false,
      state:false,
      contact:false
    }
  }

  currentLocationFinder(){
    navigator.geolocation.getCurrentPosition((position:any)=>{
      this.auth.getLocation(position.coords.latitude, position.coords.longitude).subscribe((data: any) => {
        this.currentLocation = data.results[0].formatted
        this.pincode = data.results[0].components.postcode
        this.locationDetails = data
        localStorage.setItem('current-location',JSON.stringify(this.locationDetails))
        this.markers = []
        this.markers.push({
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          length:16,
          label: {
            color: 'red',
            text: this.currentLocation,
          },
          title: this.currentLocation + (this.markers.length + 3),
          options: { animation: google.maps.Animation.DROP },

        })
        this.center={
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }
        
      })
    })
  }

  location(){
    let current_address:any = localStorage.getItem('current-location')
      current_address = JSON.parse(current_address)
      console.log(current_address);
      
    if(current_address==null){
      this.currentLocationFinder
    }else{

      this.auth.getLocation(this.center.lat, this.center.lng).subscribe((data: any) => {
        this.currentLocation = data.results[0].formatted
        this.pincode = data.results[0].components.postcode
        this.locationDetails = data
        localStorage.setItem('current-location',JSON.stringify(this.locationDetails))
        this.markers = []
        this.markers.push({
          position: {
            lat: this.center.lat,
            lng: this.center.lng,
          },
          length:16,
          label: {
            color: 'red',
            text: this.currentLocation,
          },
          title: this.currentLocation + (this.markers.length + 3),
          options: { animation: google.maps.Animation.DROP },

        })
      })
    }
    
  }

  switchLang(lang: any) {
    if (lang === "Arabic") {
      this.translate.use('arb')
      this.language = "English"
      // this.direction = "rtl"
    } else {
      this.translate.use('en')
      this.language = "Arabic"
      // this.direction = "ltl"
    }
  }

  onSearch(e:any){   
    // this.onSearchFocus = true
    this.isClose=false
    this.messageService.sendsearchValue(e)
    this.router.navigate(['/search/' + e])
    // this.onFocusOut()
    this.messageService.sendSearchWord(e)
  }

  onclickSearch(e:any){
    this.isClose=false
    this.messageService.sendsearchValue(e)
    this.router.navigate(['/search/' + e])
    this.messageService.sendSearchWord(e)
  }

  onFocus(){
    this.onSearchFocus = true
  }

  onFocusOut(){
    setTimeout(() => {
      this.onSearchFocus = false
    }, 2000);
  }

  routeProduct(id:any){
    this.isClose=false
    this.router.navigate(['/product/'+id])
    // setTimeout(() => {
    //   window.location.reload()
    // }, 2000);
  }
  routeSubCategory(name:any,code:any,id:any){
    this.router.navigate(['/productGroup/' + code +'_$_'+ name +'_$_'+ this.Categorytype +'_$_'+ id+'_$_'+'category'])
    setTimeout(() => {
      window.location.reload()
    }, 500);
    this.messageService.getSearchWord().subscribe((data:any)=>{
      this.search=data
    })
  }
  routeGroup(name:any,code:any,id:any){
    this.router.navigate(['/productGroup/' + code +'_$_'+ name +'_$_'+ this.Categorytype +'_$_'+ id+'_$_'+'group'])
    setTimeout(() => {
      window.location.reload()
    }, 500);
    this.messageService.getSearchWord().subscribe((data:any)=>{
      this.search=data
    })
  }
  routeCategory(name:any,id:any,code:any){
    this.router.navigate(['/division/' + name+'_$_'+code + '_$_' + this.Categorytype +'_$_'+ id+'_$_'+'category'])
    setTimeout(() => {
      window.location.reload()
    }, 500);
  }
  routeProductGroup(name:any,id:any,code:any){
    this.router.navigate(['/division/' + name+'_$_'+code + '_$_' + this.Categorytype +'_$_'+ id+'_$_'+'group'])
    setTimeout(() => {
      window.location.reload()
    }, 500);
  }

  routeNavigate(page:any){
    this.router.navigate([page])
  }

  divisionRoute(name:any,id:any,code:any){
    this.router.navigate(['/division/' + name+'_$_'+code + '_$_' + 'division' +'_$_'+ id+'_$_'+'division'])
    setTimeout(() => {
      window.location.reload()
    }, 300);

  }

  addressSendotp(){
    // if(this.addAdress.value.mobile==''||this.addAdress.value.street==''||this.addAdress.value.city==''||this.addAdress.value.buildingname==''||this.addAdress.value.pincode==''||this.addAdress.value.buildingNo==''||this.addAdress.value.area==''||this.addAdress.value.state=='')return
    let otpVarify={
      contact:'+971' +this.contactMobile
    }
    this.auth.addresOtpSend(otpVarify)  
    this.messageService.getOtpValidateMessage().subscribe((data:any)=>{
      if(data=='success'){
        this.display=true
        this.observableTimer()
      }
    })
    
  }

  resendOtpNew(){
    let otpVarify={
      contact:'+971' +this.contactMobile
    }
    this.auth.addresOtpSend(otpVarify)  
    this.messageService.getOtpValidateMessage().subscribe((data:any)=>{
      if(data=='success'){
        this.display=true
        this.observableTimer()
      }
    })
  }

  addressOtpVarify(){
    let varify={
      contact:'+971' +this.contactMobile,
      otp:parseInt(this.addressCreateOtp)
    }
    this.auth.addresOtpVarify(varify)
    this.messageService.getOtpSuccsess().subscribe((data:any)=>{
      if(data=="success"){
        let user: any = localStorage.getItem('marketplaceUser');
        let userProfile = JSON.parse(user)
        let addressDataAdd = {
          street_name: this.addressList.street_name,
          building_no: this.addressList.building_no,
          user_code: userProfile.customer_usercode, 
          full_name: this.addressList.full_name,
          building_name: this.addressList.building_name,
          city: this.addressList.city, 
          landmark: this.addressList.landmark,
          contact: '+971'+this.contactMobile,
          is_active: true,
          address_type: null,
          country: this.addressList.country,
          state: this.addressList.state
        }
        this.auth.createAddress(addressDataAdd)
        this.messageService.getOtpValidateMessage().subscribe((data:any)=>{
          if(data=='success'){
            this.display=false
            // this.addAdress.reset()
            this.editAddressId = "",
            this.newAddress = false
            this.editAddress = false
            this.addressList.street_name = ""
            this.addressList.building_name = ""
            this.addressList.full_name = ""
            this.addressList.building_no =""
            this.addressList.city = ""
            this.addressList.landmark = ""
            this.contactMobile = ""
            // this.addressList.country = ""
            this.addressList.state = ""
          }
        })
      }
    })
  }

  resendUpdateOtpNew(){
    let otpVarify={
      contact:'+971'+this.contactMobile
    }
    this.auth.addresOtpSend(otpVarify)  
    this.observableTimer()
  }

  updateSendotp(){
    let otpVarify = {
      contact:'+971'+this.contactMobile
    }
    this.auth.addresOtpSend(otpVarify)  
    this.messageService.getOtpValidateMessage().subscribe((data:any)=>{
      if(data=='success'){
        this.updateAddressOtp=true
        this.observableTimer()
      }
    })
  }

  updateAddressOtpVarify(id:any){
    let varify={
      contact:'+971'+this.contactMobile,
      otp:parseInt(this.addressUpdateOtp)
    }
    this.auth.addresOtpVarify(varify)
    this.messageService.getOtpSuccsess().subscribe((data:any)=>{
      if(data=="success"){
        let user: any = localStorage.getItem('marketplaceUser');
        let userProfile = JSON.parse(user)
        let code = "+971"
        let addresss = {}
        addresss = {
          id : id,
          user_code : userProfile.customer_usercode,
          street_name: this.addressList.street_name,
          building_no: this.addressList.building_no,
          full_name : this.addressList.full_name,
          building_name : this.addressList.building_name,
          country : this.addressList.country,
          state : this.addressList.state,
          city : this.addressList.city,
          landmark : this.addressList.landmark,
          contact : '+971'+this.contactMobile,
          is_active : true,
          address_type : null,
        }
        this.auth.updateUserAddress(addresss,id)
        this.messageService.getOtpValidateMessage().subscribe((data:any)=>{
          if(data=='success'){
            this.updateAddressOtp=false
            setTimeout(() => {
              window.location.reload()
            }, 300);
          }
        })
      }
    })
  }

  updateAddress(id:any){
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    let code = "+971"
    let addresss = {}
    addresss = {
      id : id,
      user_code : userProfile.customer_usercode,
      street_name: this.addressList.street_name,
      building_no: this.addressList.building_no,
      full_name : this.addressList.full_name,
      building_name : this.addressList.building_name,
      country : this.addressList.country,
      state : this.addressList.state,
      city : this.addressList.city,
      landmark : this.addressList.landmark,
      contact : '+971'+this.contactMobile,
      is_active : true,
      address_type : null,
    }
    this.auth.updateUserAddress(addresss,id)
  }

  changeNumBtn(){
    this.changeNumber=true
  }

  observableTimer() {
    const source = timer(1000,1000);
    const abc = source.subscribe(val => {
      if(val > this.timeLeft) return 
      this.subscribeTimer = this.timeLeft - val;
    });
  }
  

  selectSubcategory(index:any,data:any){
    this.subCategoryIndex = index
    let demo:any=[]
   demo=this.divisionCateArray.filter((d:any)=>d.name==data)
   console.log(this.divisionCateArray,"-=-=-=---=--=======-============-------");
   
   for(let i of demo){
     this.subcategoryDivision=i.subcategories
   }
  

   console.log(this.subcategoryDivision,"this.subcategoryDivision");
  }
  selectDivisionSubcate(index:any,data:any){

  }

  mouseLeave(data:any){
    this.subcategoryDivision=[]
    console.log(this.subcategoryDivision,"hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
    
  }

  categoryIndex=0
  selectcategory(index:any){
    this.categoryIndex = index
  }
  
  subcategoryIndex(index:any){
    this.productGroupIndex = index
  }
  selectDivisionGroups(index:any){
    this.divisionGroupIndex=index
  }

  divisionAllGroups(name:any){
    let checkArray:any=[]
    checkArray=this.subcategoryDivision.filter((d:any)=>d.name==name)
    console.log(checkArray,"checkArray");
  }

  changeAllCategory(e:Event){
    this.allCategoryDrop = !this.allCategoryDrop
    this.subCategoryIndex = 0
  }

  divisionCategory(id:any){
    let demo:any=[]
     demo=this.divisionAllCategory.filter((data:any)=>data.id==id)
      for(let i of demo){
        this.divisionCateArray=i.categories
        }
        this.divisionSubcateIndex=0
        this.subCategoryIndex = 0
        this.divisionDrops=!this.divisionDrops 
  }

  selectDivisionSubcategory(index:any){
    this.divisionSubcateIndex = index
  }
  
  subcateDivisiongoryIndex(index:any){
    this.divisionSubcateIndex = index
  }

  ontypeChange(){
    localStorage.setItem('specialGroup',this.Categorytype)
    this.messageService.sendSegments(this.Categorytype)
    this.categoryService.getAllCategory(this.Categorytype).subscribe((data:any)=>{
      this.allCategory = data.data
    })
    this.productService.getDivisionList(this.Categorytype).subscribe((data:any)=>{
      this.divisionMenuList = data.data.products
      this.divisionMenuList= this.divisionMenuList.sort((a:any, b:any) => parseFloat(a.Priorty) - parseFloat(b.Priorty))
    })
  }

  mapLocation(event: google.maps.MapMouseEvent){
    this.auth.getLocation(event.latLng?.lat(), event.latLng?.lng()).subscribe((data: any) => {
      this.currentLocation = data.results[0].formatted
      this.pincode = data.results[0].components.postcode
      this.locationDetails = data
      localStorage.setItem('current-location',JSON.stringify(this.locationDetails))
    })
    let lat:any = event.latLng?.lat()
    let lng:any = event.latLng?.lng()
    this.center = {
      lat: lat,
      lng: lng,
    }
    this.markers = []
    this.markers.push({
      position: {
        lat: event.latLng?.lat(),
        lng: event.latLng?.lng(),
      },
      label: {
        color: 'red',
        text: this.currentLocation,
      },
      length:10,
      title: this.currentLocation,
      options: { animation: google.maps.Animation.DROP },
    })
  }

  changeAddress(id:any,address:any){
    this.defualtAddressId = id
    this.requestLocation(address?.location+','+address?.building_name+','+address?.landmark)
  }

  requestLocation(loc:any){
    console.log(loc);
    
    const request = {
      query: loc,
      fields: ["name", "geometry"],
    };
    const sydney = new google.maps.LatLng(this.center.lat, this.center.lng);
    this.map = new google.maps.Map(document.getElementById("map") as HTMLElement,{center: sydney,zoom: 15,})
    this.service = new google.maps.places.PlacesService(this.map);
    this.service.findPlaceFromQuery(
      request,
      (
        results: google.maps.places.PlaceResult[] | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && results) {
         
          let lat = results[0].geometry!.location!?.lat()
          let lng = results[0].geometry!.location!?.lng()
          this.center = {
            lat: lat,
            lng: lng,
          }
          this.auth.getLocation(lat, lng).subscribe((data: any) => {
            this.currentLocation = data.results[0].formatted
            this.pincode = data.results[0].components.postcode
            this.locationDetails = data
            localStorage.setItem('current-location',JSON.stringify(this.locationDetails))
          })
          this.markers = []
          this.markers.push({
            position: {
              lat: lat,
              lng: lng,
            },
            length:16,
            label: {
              color: 'red',
              text: this.currentLocation,
            },
            title: this.currentLocation + (this.markers.length + 3),
            options: { animation: google.maps.Animation.DROP },
  
          })
          this.map.setCenter(results[0].geometry!.location!);
        }
      }
    );
  }

  createAddress(){
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    let addressDataAdd = {
      user_code: userProfile.customer_usercode,  
      full_name: this.currentLocation,
      location: '',
      city: this.locationDetails.results[0].components?.city, 
      landmark: '',
      postal_code: this.locationDetails.results[0].components.postcode,
      contact: userProfile.mobile,
      is_default: true,
      is_active: true,
      address_type: null,
      country: this.locationDetails.results[0].components.country,
      state: this.locationDetails.results[0].components.state
    }
    console.table(addressDataAdd);
    // create address api
    // this.auth.createAddress(addressDataAdd)
  }

  

  logOut(){
    localStorage.clear()
    this.toaster.success('Logout success')
    setTimeout( () => { 
      window.location.reload()
    }, 3000);
  }

  addNew(){
    // this.editAddress=!this.editAddress
    this.editAddress!=this.editAddress
    this.contactMobile=""   
  }

  addNewMy(){
    this.editAddressId = "",
    this.newAddress = false
    this.editAddress = false
    this.addressList.street_name = ""
    this.addressList.building_name = ""
    this.addressList.full_name = ""
    this.addressList.building_no =""
    this.addressList.city = ""
    this.addressList.landmark = ""
    this.contactMobile = ""
    // this.addressList.country = ""
    this.addressList.state = ""
    this.editAddress!=this.editAddress

  }


  mouseleave(i:any){
   let demo:any=[]
      demo=this.allCategory.filter((d:any)=>d.name==i)
      console.log(demo,"///////////////");
      
  }

  delay(elem:any, callback:any){
    let timeout:any=null
    elem.onmouseover = function() {
      // Set timeout to be a timer which will invoke callback after 1s
     timeout = setTimeout(callback, 1000);
    };
    elem.onmouseout = function() {
      // Clear any timers set to timeout
      clearTimeout(timeout);
    }
  }
  
// subcategoryDivision:any=[]
//   demoCate(data:any){
//    this.subcategoryDivision=this.divisionCateArray.filter((d:any)=>d.name==data)
//   }


routeLink(name:any,group:any,id:any,code:any){
  this.router.navigate(['/division/' + name+'_$_'+ code+'_$_'+group+'_$_'+id+'_$_'+'category'])

}

OnclickOutside(e:any){
  this.isClose=false
}


scrollUp(){
  window.scrollTo(0, 0);
}
  
}
