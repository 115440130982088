<div class="container px-md-0 px-lg-5 py-5">
  <div class="row m-0">
    <!-- <form action=""> -->
      <h2 class="text-center mb-1"><b>Forgot Password</b></h2>
      <p class="text-center w-75 mx-auto fz-14 mb-4">
        Enter your registered Email or Phone number below
      </p>
      <div class="mx-auto col-md-6 col-sm-12 login ps-md-0 px-lg-5  mt-0">
        <form class="row m-0" [formGroup]="forgotForm" (ngSubmit)="forgotSubmit()">
          <!-- Email input -->
          <div class="form-outline mb-4 w-100">
            <input type="text" 
              class="form-control"
              name='email' 
              placeholder="Email or mobile phone number" 
              formControlName="email"
            />
            <span class="mt-2 fz-13 text-danger d-block" *ngIf="!is_valid">please fill a valid email</span>
          </div>
          <div class=" mb-4 pb-1">
            <button class="btn btn-primary w-100 d-flex justify-content-center"  type="submit">
              SEND OTP
            </button>
          </div>
        </form>
      </div>
    <!-- </form> -->
      <a [routerLink]="['/auth']" routerLinkActive="router-link-active"  class="text-center mb-4 fz-14" style="color: black;">Back to <i style="color: #086db5;">LOGIN</i></a>
  </div>
</div>
