
<!-- product slider Section -->
<div class="container-fluid d-flex flex-wrap mt-lg-3 p-lg-3 p-1 pt-lg-3 w-100 justify-content-center">
  <!-- <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-md-3 px-1 fz-22">{{popular_category?.Display_name}}</h4> -->
  <div class="col-12 row  pe-3 multi-slider d-flex justify-content-center">
      <div class="uk-position-relative uk-visible-toggle uk-dark px-10px " tabindex="-1" uk-slider="sets: true">
          <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-6@l uk-child-width-1-6@xl d-flex justify-content-center">
              <li class=" border-0 d-flex slider-item" *ngFor="let category of popular_category?.products">
                <a class="m-0 p-0" [href]="routeLink(category?.name,group,category?.id,category?.code)" (mouseover)="demoTest(category.code)">
                  <div class="d-flex flex-column align-items-start justify-content-start w-100" >
                    <div class="pcard_round"  [ngClass]="{'life-style-bg': group ==='smart', 'essential-bg' : group === 'groceries', 'bulk-bg': group ==='bulk', 'around-world-bg': group === 'home_living'}">
                      <img [src]="category.image" alt="" />
                    </div>
                    <div class="pcard_title fw-bold">
                      {{category?.name |titlecase}}
                    </div>
                  </div>
                </a>
               
              </li>
          </ul>
          <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
          <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
      </div>
  </div>
</div>
<!-- End product slider Section -->