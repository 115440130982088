<!-- Popular Category -->
<app-popular-category
parentLink="/division/"
[group]="specialGroups"
[count]="count"
[popular_category]="popular_category"
></app-popular-category>
<!-- Ends of Popular Category -->

<app-deals 
[group]="specialGroups"
[parent]="deals"
></app-deals>

<app-product-slider-two 
    [sectionTitle]="mainSlot_3?.Display_name"
    [products]="mainSlot_3?.products"
></app-product-slider-two>

<div class="container-fluid d-flex flex-wrap mt-md-3 p-md-3 p-1 w-100">
    <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22 text-capitalize">{{mainSlot_4?.Display_name}}</h4>
    <div class="col-12 row px-3 gx-3 pe-0">
        <div class="col-lg-3 col-md-4 col-6 mb-3" *ngFor="let item of mainSlot_4?.banner">
            <a class="m-0 p-0" [href]="routeTo(item.redirect_type,item.redirecturl,item.redirect_id,item.section_type,item.redirect_code)">
                <img class="w-100 rounded pointer" [src]="item.image" alt="">
            </a>
        </div>
    </div>
</div>

<app-product-slider-two 
    [sectionTitle]="mainSlot_5?.Display_name"
    [products]="mainSlot_5?.products"
></app-product-slider-two>

<div class="container-fluid d-flex flex-wrap mt-md-3 p-md-3 p-1 w-100">
    <div class="col-12 row px-3 gx-3 pe-0">
        <div class="col-md-6 mb-3" *ngFor="let item of center_slot.banner">
            <a class="m-0 p-0" [href]="routeTo(item.redirect_type,item.redirecturl,item.redirect_id,item.section_type,item.redirect_code)">
                <img class="w-100 rounded pointer" [src]="item.image" alt="" >
            </a>
        </div>
    </div>
</div>

<app-product-slider-two 
[sectionTitle]="mainSlot_6?.Display_name"
[products]="mainSlot_6?.products"
></app-product-slider-two>

<app-product-slider-two 
[sectionTitle]="mainSlot_7?.Display_name"
[products]="mainSlot_7?.products"
></app-product-slider-two>

<div class="container-fluid d-flex flex-wrap mt-md-3 p-md-3 p-1 w-100">
    <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-3 fz-22 text-capitalize">{{mainSlot_8?.Display_name}}</h4>
    <div class="col-12 row px-3 gx-3 pe-0">
        <div class="col-lg-3 col-md-4 col-6 mb-3" *ngFor="let item of mainSlot_8.banner">
            <a class="m-0 p-0" [href]="routeTo(item.redirect_type,item.redirecturl,item.redirect_id,item.section_type,item.redirect_code)">
                <img class="w-100 rounded pointer" [src]="item.image" alt="">
            </a>
        </div>
    </div>
</div>


<div class="container-fluid d-flex flex-wrap mt-md-3 mb-3 mb-md-0 p-md-3 p-1 w-100 slot-9-banners">
    <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 px-3 fz-22 text-capitalize">{{mainSlot_9_title}}</h4>
    <div class="uk-position-relative uk-visible-toggle uk-light px-3 w-100 multi-slider" tabindex="-1" uk-slider="center: false">
        <ul class="uk-slider-items uk-grid-small px-0">
            <li class="uk-width-4-5 category-slide-item px-0 pe-2" *ngFor="let item of mainSlot_9">
                <div class="uk-panel">
                    <div class="container-fluid row gx-3 mx-0 px-0 w-100" >
                        <div class="col-6 ps-0">
                            <a  [href]="routeTo(item.redirect_type,item.redirecturl,item.redirect_id,item.section_type,item.redirect_code)" class="m-0 p-0">
                                <img  class="w-100 rounded pointer" [src]="item.image" alt="" >
                            </a>
                        </div>
                        <div class="col-6 row gx-2">
                            <div class="col-3" *ngFor="let child of item.child | slice:0:4;let i = index">
                                <div class="w-100">
                                    <a  [href]="routeTo(child.redirect_type,child.redirecturl,child.redirect_id,child.section_type,child.redirect_code)" class="m-0 p-0">
                                        <img class="w-100 rounded pointer" [src]="child.image" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 ps-0 d-flex flex-wrap mt-2">
                            <div class="col-6 row gx-2 secondlast-slide-row">
                                <div class="col-3" *ngFor="let child of item.child | slice:4:8;let i = index">
                                    <div class="">
                                        <a  [href]="routeTo(child.redirect_type,child.redirecturl,child.redirect_id,child.section_type,child.redirect_code)" class="m-0 p-0">
                                            <img class="w-100 rounded pointer" [src]="child.image" [alt]="i">
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 row gx-2 ps-2 last-category-slide-row">
                                <div class="col-3" *ngFor="let child of item.child | slice:8:12;let i = index">
                                    <div class="">
                                        <a  [href]="routeTo(child.redirect_type,child.redirecturl,child.redirect_id,child.section_type,child.redirect_code)" class="m-0 p-0">
                                            <img class="w-100 rounded pointer" [src]="child.image" [alt]="i" >
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <a class="uk-position-center-left uk-position-small dark-sidenav" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
        <a class="uk-position-center-right uk-position-small dark-sidenav" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>
</div>


<app-product-slider-two 
    [sectionTitle]="mainSlot_10?.Display_name"
    [products]="mainSlot_10?.products"
></app-product-slider-two>

<div class="py-3 w-100"></div>