import { MessengerService } from './../../services/messenger.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-successorder',
  templateUrl: './successorder.component.html',
  styleUrls: ['./successorder.component.scss']
})
export class SuccessorderComponent implements OnInit {
  order:any
  weburl = environment.footer_credit_link
  webdomain = environment.footer_credit_text
  statusMessage:any
  designChage=false
  designChageOne=false
  constructor(private msg:MessengerService,
              private orderService:OrderService) {
    this.msg.getOrderResponse().subscribe((data:any)=>{
      this.order = data.data
      console.log(this.order);
    })
    let payment:any = localStorage.getItem("payment_reference")
    let payment_ref = JSON.parse(payment)
    // console.log(payment_ref,"=======");
    if(payment_ref!=null){
      let status={
        reference_id:payment_ref
      }
      this.orderService.paymentStatus(status)
      console.log(payment_ref,">>>");
    }
    
    this.msg.getPaymentStatus().subscribe((data:any)=>{
      this.statusMessage=data.data?.payment_status
      console.log(this.statusMessage,"hjkhjkjk");
      if(data.data?.payment_status=="Success"){
        this.designChage=true
        localStorage.removeItem("payment_reference");
      }else{
        this.designChageOne=true
        localStorage.removeItem("payment_reference");
      }
    })

    this.msg.getStatus().subscribe((data:any)=>{
      console.log(data,"[[[[[[[[[");
      
      if(data=="success"){
        this.designChage=true
      }else{
        this.designChageOne=true
      }
    })
   
   }

  ngOnInit(): void {
   
    
  }

}
