import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { MessengerService } from 'src/app/services/messenger.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment'; 
let headers:any
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  api_user = environment.api_user
  api_cart_checkout = environment.api_cart_checkout
  api_partner = environment.api_partner
  forgotConfirmOtp = "/user-account_userforgotpasswordvarify?email="
  forgotPasswordApi = "/user-account_userforgotpasswordrequest?email="
  cartBulkUp = "/order/create-bulk-cart"
  userSignupData:any = []
  signUp = "/user-customer_customerusersignup"
  login = "/user-account_login"
  otp="/user-customer_customerusersignupvarify"
  profileData="/user-customer_customeruserupdate/"
  postProfileUpdate = "/user-customer_customeruserupdate/"
  addressGet = "/user-general_address"
  userAddressApi = "/user-general_getuseraddress?customer_usercode="
  addressCreate = "/user-general_addresscreate"
  updateAddress = "/user-general_addressedit/"
  createNewPassword = "/user-account_usernewpswd?email="
  changePassword = "/user-account_userchangepassword"
  acceptConditionApi = "/contract/mutualcontractcreate"
  setDefualt = "/user-general_address_default"
  addresSendOtp="/user-account_phonenumber_otp/request"
  AddressOtpVarify="/user-account_varifyphonenumber"
  userProfileInfo:any 
  addressData:any = []
  getOtpDetails(){
    return this.userSignupData
  }
  getLocation(lat:any,log:any){
    return this.http.get<any>(`https://api.opencagedata.com/geocode/v1/json?key=3ab3f02b7201458cb637f8a010254ff6&q=${lat}%2C+${log}&pretty=1&no_annotations=1`)
  }

  constructor(private http: HttpClient,private messager:MessengerService,private toaster: ToastrService,private router:Router) { 
    
  }

  getCountry(){
    return this.http.get<any>(this.api_user+'/country-list')
  }

  loginPost(auth:any){
    this.http.post(this.api_user+this.login,auth).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        localStorage.setItem("marketplaceUser", JSON.stringify(data.data))
        let user:any = localStorage.getItem('marketplaceUser')
        let userDetails = JSON.parse(user) 
        this.toaster.success(data.message)
        let CartData = localStorage.getItem('CartData')
        if(CartData != null){
          let cart = JSON.parse(CartData)
          cart.user_id = userDetails.customer_usercode
          this.http.post(this.api_cart_checkout+this.cartBulkUp,cart).toPromise().then((data:any)=>{
            localStorage.removeItem('CartData')
          }).catch((d)=>{
            console.log(d,'error');
            // this.toaster.error(d.status);
          })
        }
        setTimeout( () => { 
          window.location.reload()
        }, 3000);
      }else{
        this.toaster.warning(data.message)
      }
    }).catch((d)=>{
      console.log('error');
      // this.toastr.error(d.error.data.validation_details[0].message[0], ' ', { timeOut: 3000, });
    })
  }

  signupPost(signup:any){
    this.http.post(this.api_user+this.signUp,signup).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        this.toaster.success(data.message)
        this.userSignupData = data.data
        this.router.navigate(['/auth/otp/signup'])
      }else{
        this.toaster.warning(data.message)
      }
    }).catch((d)=>{
      console.log('error');
      
      this.toaster.error(d.status);
    })
  }

  signupPostResend(signup:any){
    this.http.post(this.api_user+this.signUp,signup).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        console.log(data.message);
        this.messager.sendOtpValidate(data?.status)
      }else{
        this.toaster.warning(data.message)
      }
    })
  }

  postOtp(otpData:any){
    this.http.post(this.api_user+this.otp,otpData).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        localStorage.removeItem('signupData')
        this.toaster.success(data.message)

        this.userSignupData = data.data
        let newContract = {
          contract_data:[
            {
              renewel_period: "One time",
              is_active: true,
              status: "A",
              contract_note: "I agree to Ahlancart Terms and Conditions",
              contract_type_id_id: 1
            },
            {
              renewel_period: "One time",
              is_active: true,
              status: "A",
              contract_note: "I have read, I understand and acknowledge Ahlancart Privacy policy",
              contract_type_id_id: 1
            },
            {
              renewel_period: "One time",
              is_active: true,
              status: "A",
              contract_note: "I want to receive the latest offers from Ahlancart and its trusted partners via SMS and all other channels, ",
              contract_type_id_id: 1
            }
          ],
          party_data: [
            {
              fname: "Ansar",
              lname: "Abdulla",
              mobile_no: "+971 0565947777",
              email: "ansarykp@gmail.com",
              is_active: true,
              meta: null,
              party_type_id_id: 2
            },
            {
              fname: otpData.fname, 
              lname: otpData.lname, 
              mobile_no: otpData.mobile, 
              email: otpData.email, 
              is_active: true,
              meta: null,
              party_type_id_id: 1
            }
          ]
        }
        
        setTimeout(() => {
          this.acceptCondition(newContract)
          
        }, 1000);
        
      }else{
        this.toaster.warning(data.message)
      }
    }).catch((d)=>{
      console.log('error');
      this.toaster.error(d.status);
    })
  }

  acceptCondition(data:any){
    this.http.post(this.api_partner+this.acceptConditionApi,data).toPromise().then((data:any)=>{
      console.log(data);
      if(data?.status === 'success'){
        // this.toaster.success(data.message)
        this.router.navigate(['/auth'])
      }else{
        this.toaster.warning(data.message)
      }
    }).catch((d)=>{
      console.log(d,'error');
      // this.toaster.error(d.status);
    })
  }

  getUserProfile(){
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    return this.http.get<any>(this.api_user+this.profileData+userData.customer_id)
  }

  getAddress(){
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    if(userData !=null){
      let userCode = {
        customer_usercode: userData.customer_usercode,
      }
      this.http.post(this.api_user+this.addressGet,userCode).toPromise().then((data:any)=>{
        this.userProfileInfo = data.data
        this.messager.sendAddress(data.data)
        this.addressData = data.data
      }).catch((d)=>{
        console.log(d,'error');
        // this.toaster.error(d.status);
      })
    }
  }

  getUserAddress(){
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    return this.http.get<any>(this.api_user+this.userAddressApi+userData.customer_usercode)
  }

  createAddress(address:any){
    this.http.post(this.api_user+this.addressCreate,address).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        this.toaster.success(data.message)
        this.userSignupData = data.data
        this.messager.dontRefresh()
        this.messager.sendOtpVaidate(data.status) 
      }else{
        this.toaster.warning(data.message)
      }
    }).catch((d)=>{
      console.log('error');
      this.toaster.error(d.status);
    })
  }
  addresOtpSend(otpData:any){
    this.http.post(this.api_user+this.addresSendOtp,otpData).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        this.toaster.success(data.message)
        this.messager.sendOtpVaidate(data.status)
      }else{
        this.toaster.warning(data.message)
      }
    })
  }
  addresOtpVarify(otpData:any){
    this.http.post(this.api_user+this.AddressOtpVarify,otpData).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        this.toaster.success(data.message)
        this.messager.sendOtpSuccsess(data.status)
      }else{
        this.toaster.warning(data.message)
      }
    })
  }

  setDefualtAddress(defaultData:any){
    this.http.patch(this.api_user+this.setDefualt,defaultData).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        // this.toaster.success(data.message)
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }else{
        // this.toaster.warning(data.message)
        // this.toaster.warning(data.message)
      }
    })
  }

  updateUserAddress(address:any,id:any){
    this.http.patch(this.api_user+this.updateAddress+id,address).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        this.toaster.success(data.message)
        this.messager.dontRefresh()
        this.messager.sendOtpVaidate(data.status) 
      }else{
        this.toaster.warning(data.message)
      }
    }).catch((d)=>{
      console.log(d,'error');
      // this.toaster.error(d.status);
    })
  }

  deleteUserAddress(id:any){
    this.http.delete(this.api_user+this.updateAddress+id).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        this.toaster.success(data.message)
        this.messager.dontRefresh()
      }else{
        this.toaster.warning(data.message)
      }
    }).catch((d)=>{
      console.log(d,'error');
      // this.toaster.error(d.status);
    })
  }

  getUserProfileInfo(){
    this.getUserProfile()
    
    if(this.userProfileInfo === undefined){
      this.getUserProfile()
      return this.userProfileInfo
    }else{
      return this.userProfileInfo
    } 
  }


  profileUpdate(profile:any,id:number){
    this.http.patch(this.api_user+this.postProfileUpdate+id,profile).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        this.toaster.success(data.message)
        let user:any = localStorage.getItem("marketplaceUser")
        let userData = JSON.parse(user)
        userData.username = data.data.username
        localStorage.setItem("marketplaceUser", JSON.stringify(userData))
        // setTimeout(() => {
        //   window.location.reload()
        // }, 500);
      }else{
        this.toaster.warning(data.message)
      }
    })
  }

  profilePicUpdate(){

  }

  getProperAddress(){
    this.getAddress()
    setTimeout( () => { 
      return this.addressData
    }, 2000);
  }

  forgotPassword(email:any){
    let user:any = localStorage.getItem("marketplaceUser")
        let userData = JSON.parse(user)
    return this.http.get<any>(this.api_user+this.forgotPasswordApi+email)
  } 

  postForgotOtp(otpdata:any){
    return this.http.get<any>(this.api_user+this.forgotConfirmOtp+otpdata.email+'&key='+otpdata.otp)
  }

  createNewPasswordGet(forgotData:any){
    return this.http.get<any>(this.api_user+this.createNewPassword+forgotData.email+'&pwd='+forgotData.newPassword)
  }
  
  changeCurrentPassword(data:any){
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let tokens=userDetails.token
    headers=new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `token ${tokens}`
    }),
    this.http.post(this.api_user+this.changePassword,data,{headers}).toPromise().then((data:any)=>{
      let user:any = localStorage.getItem('marketplaceUser')
      let userDetails = JSON.parse(user)
      console.log(userDetails);
      if(data.status === 'success'){
        this.toaster.success(data.message)
        localStorage.removeItem('marketplaceUser')
        setTimeout(() => {
          window.location.reload()
        }, 500);
        this.router.navigate(['/auth'])
      }else{
        this.toaster.warning(data.message)
      }
    }).catch((d)=>{
      console.log('error');
      this.toaster.error(d.status);
    })
  }

  Options7() {
    let user:any = localStorage.getItem('marketplaceUser')
    let userDetails = JSON.parse(user)
    let tokens=userDetails.token
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `${tokens}`,
        language: 'en',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    };
    return httpOptions;
  }

  
}
