import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Product } from 'src/app/models/product';
import { MessengerService } from 'src/app/services/messenger.service';

@Component({
  selector: 'app-product-card-one',
  templateUrl: './product-card-one.component.html',
  styleUrls: ['./product-card-one.component.scss']
})
export class ProductCardOneComponent  {
  @Input() wishlist = false
  @Input () control= true
  @Input() products!: Product;
  @Input() wishListed = false
  @Output() cartEvent = new EventEmitter()
  @Output () wishEvent = new EventEmitter()
  @Output () isWishListEvent = new EventEmitter()
  constructor(
    private messageService:MessengerService
  ) { }
  changeWishlist(product:any) {
    this.wishEvent.emit(product)
  }
  addCart(prod: any) {
    this.cartEvent.emit(prod)
  }
  buyNow() {
  }
  
}
