import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class MessengerService {

  constructor() { }
  subject = new Subject()
  removeCart = new Subject()
  wishList = new Subject()
  loginAuth = new Subject()
  userProfile = new Subject()
  address = new Subject()
  segments = new Subject()
  orderResponse = new Subject()
  searchValue = new Subject()
  forgotPass = new Subject()
  processProgress = new Subject()
  newPasswordFromReset = new Subject()
  addressMessage = new Subject()
  paymentFailed = new Subject()
  allFilter = new Subject()
  paymentStatus=new Subject()

  searchDataCategoryDivison = new Subject()
  reference_id=new Subject()
  cashOndelivery=new Subject()
  dontRefersh:any=new Subject()
  count=new Subject()
  price=new Subject()
  search=new Subject()
  mainId=new Subject()
  varientId=new Subject()
  ratingId=new Subject()
  btnAvailable=new Subject()
  coupons=new Subject()
  OtpValidate=new Subject()
  OtpVarifyMessage=new Subject()
  otpSuccsess=new Subject()
  addresUpdateOtp=new Subject()

  sendUpdateAddress(data:any) {
     this.addresUpdateOtp.next() 
  }

  getUpdateAddress(){
    return this.addresUpdateOtp.asObservable()
  }
   
  sendOtpSuccsess(data:any){
    this.otpSuccsess.next(data)
  }

  getOtpSuccsess(){
    return this.otpSuccsess.asObservable()
  }

  sendOtpVaidate(data:any){
    this.OtpVarifyMessage.next(data)
  }

  getOtpValidateMessage(){
    return this.OtpVarifyMessage.asObservable()   
  }

  sendOtpValidate(data:any){
    this.OtpValidate.next(data)
  }
  
  getOtpValidate(){
    return this.OtpValidate.asObservable()
  }

  sendCoupons(data:any){
    this.coupons.next(data)
  }

  getCoupons(){
   return this.coupons.asObservable()
  }

  sendBtnAvailable(data:any){
    this.btnAvailable.next(data)
  }

  getBtnAvailable(){
    return this.btnAvailable.asObservable()
  }

  idPassing(id:any){
    this.mainId.next(id)
  }

  getId(){
    return this.mainId.asObservable()
  }

  ratingID(id:any){
    this.ratingId.next(id)
  }

  getRatingId(){
    return this.ratingId.asObservable()
  }

  variantIdPassing(id:any){
    this.varientId.next(id)
  }

  getVaientId(){
    return this.varientId.asObservable()
  }
  
  sendSearchWord(data:any){
    this.search.next(data)
  }
  getSearchWord(){
    return this.search.asObservable()
  }
  sendSearchData(data:any){
    this.searchDataCategoryDivison.next(data)
  }
  getSearchData(){
    return this.searchDataCategoryDivison.asObservable();
  }

  sendFailedData(data:any){
    this.paymentFailed.next(data)
  }
  getFailedData(){
    return this.paymentFailed.asObservable();
  }

  sendAllFilter(data:any){
    this.allFilter.next(data)
  }

  getAllFilter(){
    return this.allFilter.asObservable();
  }


  sendForgotOtpConfirm(email:any){
    this.newPasswordFromReset.next(email)
  }
  getForgotOtpConfirm(){
    return this.newPasswordFromReset.asObservable();
  }

  sendForgot(email:any){
    this.forgotPass.next(email)
  }
  
  getForgot(){
    return this.forgotPass.asObservable();
  }

  processStart(prod:any){
    this.processProgress.next(prod)
  }
  processComplete(){
    return this.processProgress.asObservable();
  }

  sendsearchValue(prod:any){
    this.searchValue.next(prod)
  }
  getsearchValue(){
    return this.searchValue.asObservable();
  }

  sendOrderResponse(prod:any){
    this.orderResponse.next(prod)
  }
  getOrderResponse(){
    return this.orderResponse.asObservable();
  }

  sendSegments(prod:any){
    this.subject.next(prod)
  }
  getSegments(){
    return this.subject.asObservable();
  }

  sendMessage(prod:any){
    this.subject.next(prod)
  }
  getMessage(){
    return this.subject.asObservable();
  }

  sendRemoveMessage(){
    this.removeCart.next()
  }
  getRemoveMessage() {
    return this.removeCart.asObservable();
  }

  sendAddress(data:any){
    this.addressMessage.next(data)
  }
  getAddress() {
    return this.addressMessage.asObservable();
  }

  sendUserProfileData(data:any){
    this.userProfile.next(data)
  }
  getUserProfileData() {
    return this.userProfile.asObservable();
  }

  /*
  Wishlist
*/
  
  RequestAddToWishList(product: any) {
    this.wishList.next(product)
  }
  getAddToWishList() {
    return this.wishList.asObservable()
  }
  /*
    wishlist end
  */


    // payment status

    sendPaymentStatus(data:any){
      this.paymentStatus.next(data)
    }
    
    getPaymentStatus(){
      return this.paymentStatus.asObservable()
    }

    keepReferenceId(data:any){
        this.reference_id.next(data)
    }

    getReference_id(){
      return this.reference_id.asObservable()
    }
    
    sendStatus(data:any){
      this.cashOndelivery.next(data)
    }

    getStatus(){
      return this.cashOndelivery.asObservable()
    }


    dontRefresh(){
      this.dontRefersh.next()   
    }

    getRefreshData(){
      return this.dontRefersh.asObservable()
    }


    cartCount(data:any){
       this.count.next(data)
    }

    getCartCount(){
      return this.count.asObservable()
    }

    cartPrice(data:any){
       this.price.next(data)
    }

    getCartprice(){
      return this.price.asObservable()
    }



}
