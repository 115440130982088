import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  specialGroups = environment.specialGroups
  mainCategory = [
    {
      displayName:"Hyper Market",
      title: "home_living",
      detail: `enhance your home as unique as always`,
      icon: "../../../assets/icons/hyperD.png",
      icon2: "../../../assets/icons/hw.png",
      shortN:"hm"
    },
    {
      displayName:"groceries",
      title: "groceries",
      detail: `All your daily favorites within reach`,
      icon: "../../../assets/icons/grocD.png",
      icon2: "../../../assets/icons/gw.png",
      shortN:"gr"
    },
    {
      displayName:"bulk",
      title: "bulk",
      detail: `Get everything your kitchen needs`,
      icon: "../../../assets/icons/bulkD.png",
      icon2: "../../../assets/icons/bw.png",
      shortN:"b"
    },
    {
      displayName:"smart",
      title: "smart",
      detail: `Make your life smart!`,
      icon: "../../../assets/icons/smartD.png",
      icon2: "../../../assets/icons/sw.png",
      shortN:"s"
    },
  ]

  

  bannerList:any = []
 
  constructor(private _productService: ProductService, private _messageService:MessengerService) {
    this._messageService.getSegments().subscribe((data:any)=>{
      this.specialGroups = data
    })
   }

  ngOnInit(): void {
    let special = localStorage.getItem('specialGroup')
    if(special !=null){
      this.specialGroups = special
    }else{
      this.specialGroups = environment.specialGroups
    }
    this._productService.getHomeBanner().subscribe((data:any)=>{
      this.bannerList = data?.data
      this.bannerList.products = this.bannerList?.products?.sort((a:any, b:any) => parseFloat(a.Priorty) - parseFloat(b.Priorty))
    })
  }
  specialGroup(specialGroup:any,name:any){
    this._messageService.sendSegments(specialGroup)
    this.specialGroups = specialGroup
    localStorage.setItem('specialGroup',this.specialGroups)
   
  }

}
