
<div class="container-fluid my-5">
    <div class="col-12 row">
        <div class="col-10 d-flex flex-row">
             <div class="w-50 d-flex flex-row">
                 <div class="group_Img d-flex flex-column my-3">
                     <div class="img_box">
                         <img src="../../../assets/test/Group 1561.png" alt="">
                     </div>
                     <div class="img_box mt-2">
                        <img src="../../../assets/test/Group 1561.png" alt="">
                    </div>
                    <div class="img_box mt-2">
                        <img src="../../../assets/test/Group 1561.png" alt="">
                    </div>
                    <div class="img_box mt-2">
                        <img src="../../../assets/test/Group 1561.png" alt="">
                    </div>
                 </div>
                 <div class="big_image d-flex align-items-center mx-2">
                    <ng-img-magnifier 
                        [thumbImage]='img' [fullImage]='img2'
                        [top]='top' [right]='right'
                        [lensWidth]='lensewidth' [lensHeight]='lensheight'
                        [resultWidth]='resultWidth' [resultHeight]='resultheight'
                        [imgWidth]='imgWidth' [imgHeight]='imgheight'>
                    </ng-img-magnifier>
                     <!-- <img src="../../../assets/test/fr 1.png" alt=""  > -->
                 </div>
             </div>
             <div class="w-50">
                 <div>
                    <span class="fz-18 fw-bold cb">Samsung</span>
                 </div>
                 <div>
                    <span class="fz-14 mt-2">
                        Samsung 6.5 kg Fully-Automatic Top Loading Washing 
                        Machine (WA65A4002VS/TL, Imperial Silver, Center Jet Technology)
                    </span>
                 </div>
                 <hr class="my-2">
                 <div>
                     <span class="fz-12 fw-bold mt-2">Model number - WA65A4002VS</span>
                 </div>
                 <div>
                     <span class="fz-12 fw-bold mt-2"> Package Size : 1 kg</span>
                 </div>
                 <div class="d-flex flex-row align-items-center mt-2">
                     <button class="offer_box"> 15% off</button>
                     <span class="fz-10 ms-2">You save INR 1215</span>
                 </div>
                 <div class="mt-3 col-12 row">
                     <div class="col-5 border-end d-flex flex-column">
                        <span class="fz-16 fw-bold text-price">INR 1,41991.00</span>
                        <span class="fz-10 cb">*Inclusive VAT</span>
                     </div>
                     <div class="col-5 d-flex flex-column">
                        <span class="fz-16 fw-bold text-danger">AED <s>4429.00</s></span>
                        <span class="fz-10 cb">*Inclusive VAT</span>
                     </div>
                 </div>
                 <div class="mt-2">
                     <span class="fw-bold fz-14 text-success">In Stock</span>
                 </div>
                 <div class="mt-2">
                    <i class="bi bi-geo-alt fz-14"></i>
                    <span class="fz-14 ms-2">Origin:India</span>
                 </div>
                 <div class="mt-2 ">
                     <span class="fz-12 cb">Color name : Silver</span>
                     <div class="d-flex flex-row mt-2">
                        <div class="related_box">
                            <img src="../../../assets/test/Group 1561.png" alt="">
                        </div>
                        <div class="related_box ms-1">
                           <img src="../../../assets/test/Group 1561.png" alt="">
                       </div>
                     </div>
                 </div>
                 <div class="mt-2 ">
                    <span class="fz-12 cb">Style : 468 L</span>
                    <div class="d-flex flex-row mt-2">
                       <div class="size_box d-flex align-items-center justify-content-center">
                           <span class="fz-13">520 px</span>
                       </div>
                       <div class="size_box ms-1 d-flex align-items-center justify-content-center">
                           <span class="fz-13">520 px</span>
                      </div>
                    </div>
                </div>
             </div>
        </div>
        <div class="col-2">
            
        </div>
    </div>
    <div class="p-field p-col-12 p-md-4">
        <label for="dateformat">DateFormat</label>
        <p-calendar [(ngModel)]="date2" dateFormat="mm-dd-yy" inputId="dateformat"></p-calendar>
    </div>
    <div class="col-12 mt-5">
        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider>
            <ul class="uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-6@m">
                <li style="color: black;">
                    fresh food
                </li>
                <li style="color: black;">
                    fresh food
                </li>
                <li style="color: black;">
                    fresh food
                </li>
                <li style="color: black;">
                    fresh food
                </li>
                <li style="color: black;">
                    fresh food
                </li>
                <li style="color: black;">
                    fresh food
                </li>
                <li style="color: black;">
                    fresh food
                </li>
                <li style="color: black;">
                    fresh food
                </li>
                <li style="color: black;">
                    fresh food
                </li>
            </ul>
            <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
        
        </div>
    </div>
</div>


<!--  
<p-dialog header="Title" [(visible)]="display" style="width: 500px;">
    hi
</p-dialog>

<button type="button" (click)="showDialog()" icon="pi pi-info-circle" label="Show"></button> -->
