import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Ng9PasswordStrengthBarComponent } from 'ng9-password-strength-bar';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  
  public barLabel: string = "Password strength:";
  public myColors = ['#DD2C00', '#FF6D00', '#FFD600', '#AEEA00', '#00C853'];
  public thresholds = [90, 75, 45, 25];
  passwordFeild1= "password"
  passwordFeild2= "password"
  passwordStrength=""
  signUpForm!: FormGroup;
  confirmPasswordField = ""
  selectedCountry = ""
  passwordMatch:any
  acceptPolicy = false
  acceptPrivacyPolicy = false
  acceptSMS = false
  country:any = []
  conditionOne=false
  conditionTwo=false
  conditionThree=false
  checkNum=""
  is_number=false
  poor=false
  medium=false
  strong=false
  numberCount=9
  ahlanCartPass=""
  constructor(
    private messageService:MessengerService, private auth:AuthService) { }

  ngOnInit(): void {
    this.signUpForm = new FormGroup({
      fName: new FormControl('',[Validators.required]),
      lName: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required, Validators.email]),
      gender: new FormControl(''),
      phone: new FormControl('',[Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10)]),
      password: new FormControl('', [Validators.required]),
      confirmPassword : new FormControl('', [Validators.required])
    })
    this.auth.getCountry().subscribe((data:any)=>{
      
      this.country= Object.values(data.data)
    })
  }
  currentCountry(){
    console.log(this.selectedCountry);
  }
  onSignUp(){
    if(this.signUpForm.value.gender){
      if(this.selectedCountry === ''){
        this.selectedCountry = 'UAE'
      }
      let reg ={
        fname: this.signUpForm.value.fName,
        lname: this.signUpForm.value.lName,
        password: this.signUpForm.value.password,
        mobile: '+971' + this.signUpForm.value.phone,
        country: this.selectedCountry,
        email: this.signUpForm.value.email,
        gender: this.signUpForm.value.gender
      }
      this.auth.signupPost(reg)
      let sdata = JSON.stringify(reg)
      localStorage.setItem('signupData',sdata)
    }
  }
  emailCheck(event:any){
    console.log(event);
    
  }
  countryChanged(e:any){
    console.log(e);
    
  }
  acceptCondition(isAccept:any){
    
  }
  showPassword1(){
    this.passwordFeild1 = 'text'
    setTimeout(() => {
      this.passwordFeild1 = 'password'
    }, 1100);
  }
  showPassword2(){
    this.passwordFeild2 = 'text'
    setTimeout(() => {
      this.passwordFeild2 = 'password'
    }, 1100);
  }
  confirmPassword(e:any){
    let password:any=e.target.value
    if(this.signUpForm.value.password == this.signUpForm.value.confirmPassword){
      this.passwordMatch = true
      console.log(this.signUpForm.value.password == this.confirmPasswordField);
    }else{
      this.passwordMatch = false
    }
  }
  confirmPasswordWorks(){
    if(this.signUpForm.value.confirmPassword =='')return
    if(this.signUpForm.value.password == this.signUpForm.value.confirmPassword){
      this.passwordMatch = true
      console.log(this.signUpForm.value.password == this.confirmPasswordField);
    }else{
      this.passwordMatch = false
    }
  }
  conditionOneFun(e:any){
    console.log(e.currentTarget.checked);
    if(e.currentTarget.checked==true){
      this.conditionOne=true
    }else{
      this.conditionOne=false
    }
  
  }
  conditionTwoFun(e:any){
    if(e.currentTarget.checked==true){
      this.conditionTwo=true
    }else{
      this.conditionTwo=false
    }
   
  }
  conditionThreeFun(e:any){
    if(e.currentTarget.checked==true){
      this.conditionThree=true
    }else{
      this.conditionThree=false
    }
  }

 
  numberValidate(num:any){
    console.log(num);
    let number=String(num)
      if(number == "" || number.length < this.numberCount || number.length >this.numberCount){
        this.is_number=true
      }else if(number.length==this.numberCount){
        this.is_number=false
      }
  }


  get phone() {
    return this.signUpForm.get('phone');
  }


}
