import { MessengerService } from 'src/app/services/messenger.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  api_design_layout = environment.api_design_layout
  api_inventory = environment.api_inventory
  api_checkout=environment.api_cart_checkout
  slot= "/design/list-slotblock/"
  searchProduct = "/elastic_inventory/product-search?q="
  searchSuggession = "/elastic_inventory/product-sujection?q="
  categoryProducts = "/display/filter-variant/"
  categoryFilter = "/display/get-side-filter-in-categorypage/"
  divisionFilter = "/display/get-side-filter-in-divisionpage/"
  allCategory = "/display/list-all-categories-and-subcategories"
  brandList = "/display/get-brand-list-by-type/"
  productUnderBrand = "/display/list-variants-by-brandlist"
  starFilter = "/display/list-variants-by-type-rating"
  allFilter = "/display/side-filter-in-one"
  categorySearch = "/inventory-product/subcategory-group-search-by-parent?seg_name="
  divisionCategory="/display/list-all-division-categories-and-subcategories"
  verifyTypeCode="/display/verify-type-of-code/"
  brandBasedProductList:any = []
  starBasedProductList:any = []
  filteredProducts:any=[]
  searchresultCategory:any = []
  constructor(private http: HttpClient,private toaster: ToastrService,private message:MessengerService) { }
  

  allDataFilter(data:any){
    this.http.post(this.api_inventory+this.allFilter,data).toPromise().then((data:any)=>{
      if(data?.status === 'sucess'){
        console.log(data.message,"success"); 
        this.filteredProducts = data.data
        this.message.sendFailedData(this.filteredProducts)
      }else{
        this.toaster.warning(data.message)
        console.log(data.message,"failed");
      }
    }).catch((d)=>{
      console.log('error');
      this.toaster.error(d.status);
    })
  }

  getSearchSuggesion(name:any){
    return this.http.get<any>(this.api_inventory+this.searchSuggession+name)
  }
  getSearchProduct(name:any){
    return this.http.get<any>(this.api_inventory+this.searchProduct+name)
  }
  getBrandList(id:any,type:any){
    return this.http.get<any>(this.api_inventory+this.brandList+id+'?type='+type)
  }
  getProductUnderBrand(data:any){
    this.http.post(this.api_inventory+this.productUnderBrand,data).toPromise().then((data:any)=>{
      if(data?.status === 'sucess'){
        console.log(data.message,"success"); 
        this.brandBasedProductList = data
      }else{
        this.toaster.warning(data.message)
        console.log(data.message,"failed");
      }
    }).catch((d)=>{
      console.log('error');
      this.toaster.error(d.status);
    })
  }
  getSearchResult(){
    return this.searchresultCategory
  }
  getCategoryDivisionSearch(data:any,type:any){
    this.http.post(this.api_inventory+this.categorySearch+type,data).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        console.log(data.message,"success"); 
        this.searchresultCategory = data.data
        this.message.sendSearchData(this.searchresultCategory)
        console.log(this.searchresultCategory,'skjdjksdbcjksdvc');
        
      }else{
        this.toaster.warning(data.message)
        console.log(data.message,"failed");
      }
    }).catch((d)=>{
      console.log('error');
      this.toaster.error(d.status);
    })
  }
  getProductStarBased(data:any){
    this.http.post(this.api_inventory+this.starFilter,data).toPromise().then((data:any)=>{
      if(data?.status === 'success'){
        console.log(data.message,"success"); 
        this.starBasedProductList = data
      }else{
        this.toaster.warning(data.message)
        console.log(data.message,"failed");
      }
    }).catch((d)=>{
      console.log('error');
      this.toaster.error(d.status);
    })
  }
  getCategoryProducts(CODE:any){
    return this.http.get<any>(this.api_inventory+this.categoryProducts+CODE)
  }
  getAllCategory(type:any){
    return this.http.get<any>(this.api_inventory+this.allCategory)
  }
  getCategoryFilter(id:any){
    return this.http.get<any>(this.api_inventory+this.categoryFilter+id)
  }
  getDivisionFilter(id:any){
    return this.http.get<any>(this.api_inventory+this.divisionFilter+id)
  }
  getCategorySlotOne(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot1/'+type)
  }
  getCategorySlotTwo(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot2/'+type)
  }
  getCategorySlotThree(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot3/'+type)
  }
  getCategorySlotFour(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot4/'+type)
  }
  getCategorySlotFive(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot5/'+type)
  }
  getCategorySlotSix(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot6/'+type)
  }
  getCategorySlotSeven(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot7/'+type)
  }
  getCategorySlotEight(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot8/'+type)
  }
  getCategorySlotNine(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot9/'+type)
  }
  getCategorySlotTen(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot10/'+type)
  }
  getCategorySlotEleven(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot11/'+type)
  }
  getCategorySlotTwelve(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot12/'+type)
  }
  getCategorySlotThirteen(name:any,type:any){
    return this.http.get<any>(this.api_design_layout+this.slot+name+'/Main_slot13/'+type)
  }
  getBrandBasedProduct(){
    return this.brandBasedProductList
  }
  getStarBasedProduct(){
    return this.starBasedProductList
  }
  getDivisionCategory(){
    return this.http.get<any>(this.api_inventory+this.divisionCategory)
  }
  getVerifytheCode(code:any){
    return this.http.get<any>(this.api_inventory+this.verifyTypeCode+code)
  }

}
