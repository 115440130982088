import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { ProductdetailsService } from 'src/app/services/productdetails.service';
import { CartService } from 'src/app/services/cart.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { RatingreviewService } from 'src/app/services/ratingreview.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/services/category.service';

// declare var $: any;
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  value!:Date
  img:any="../../../assets/test/fr 1.png"
  img2:any="../../../assets/test/fr 1.png"
  top:any='20'
  right:any='30'
  lensewidth:any='120'
  lensheight:any='120'
  resultWidth='35'
  resultheight='60'
  imgWidth='300'
  imgheight='300'
  public myThumbnail="../../../assets/assets/product-1.png";
  public myFullresImage="../../../assets/assets/product-1.png";
 
  addNew = false
  isProduct=true
  hover=false
  itemCart=false
  isEditAddress=false
  changeBillingAddress = false
  shippingAddressIdPlaceholder = "shippingAddress"
  billingAddressIdPlaceholder = "billingAddress"

  edit_address:any = {}

  street:any 
  buildingname:any 
  city:any 
  addressValue:any 
  contact:any 
  mobile:any 
  pincode:any 
  country:any 
  state:any 
  landmark:any 
  location:any 
  area:any 
  time:any 
  saveUserNameCheckBox:any
  date2!:Date

  shippingAddress:any
  billingAddress:any
  addAdress!: FormGroup;
  imageLocX= "-100"
  imageLocY= "-300"
  zoomedImage=""
  similarProducts:any
  enlargedImage:any = {
    img:"",
    index:0
  }
  review:any = []
  wishListed = false
  loader = false
  buttondissable = false
  isLogin = false
  rating = 0
  maxQty = 10
  isAddress = false
  userDetailAdress:any 
  address:any = []
  cartData:any = []
  currentQty = 1
  currentPic = 0
  productDetails:any ={
    name: "Product Name",
    offer_percentage_price:"20",
    offer_price:"00",
    selling_price:"00"
  }
  productRoute= { id:Number};
  brand = "";
  productName = "name";
  productModelNumber = "WA65A4002VS";
  discussionCount= 36;
  percentageOff="34.52";
  savedPrice=770.00;
  currency="AED";
  currentPrice= 1460;
  previousPrice= 2230;
  stock=true;
  isGift:any=false
  userAddress="RICHARD GILLWOS, State, District, Pincode:658622"
  imageThumb=[
    
  ];
  images=[
    
  ];
  checkoutList:any=[]
  catelogArray:any=[]
  linkedItem:any=[]
  relatedProducts:any=[]
  linkedName="Linked Products"
  recomanded:any=[]
  relatedName:any="Related Products"
  cart_Btn_Active=true
  findArray:any
  searchSuggesion:any = []
  search:any
  onSearchFocus=false
  showModal=false
  constructor(private toaster: ToastrService,
    private _wishlistService:WishlistService,
    private _reviewService:RatingreviewService,
    private router:Router,
    private messenger:MessengerService,
    private auth:AuthService, 
    private _cartService:CartService,
    private _productService: ProductService,
    private _productDetails:ProductdetailsService,
    private route: ActivatedRoute,private _order:OrderService , private categoryService:CategoryService) { 
    this.cartData = this._cartService.getCartValue()
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    this.productRoute ={
      id: this.route.snapshot.params['id'],
    }
    this._productDetails.getProductDetails(this.productRoute.id).subscribe((data:any)=>{
      if(data.status == 'fail' || data.status == 'error'){
        this.isProduct = false
        return
      }else{
        this.isProduct = true
        this.productDetails = data.data
        let code = Object.keys(this.productDetails?.variant_meta?.catelog?.key_values)
        let name = Object.values(this.productDetails?.variant_meta?.catelog?.key_values)
        for(let i=0; i<code.length;i++){
          let obj = {
            key: code[i],
            value: name[i]
          }
          this.catelogArray.push(obj)
        }
        console.log(this.catelogArray,"````````````");
        console.log(this.productDetails,"productdetails");
        
      }
      
    })
    let cartListArray:any=[]
    let findArray:any=[]
    this._cartService.getCart().subscribe((cartList:any)=>{
      cartListArray = cartList.data
      console.log(cartListArray,"cartListArray");
      this.findArray=cartListArray.filter((d:any)=>d.variant_id==this.productDetails.id)
      console.log(findArray,"findArray......");
      if(this.findArray.length!=0){
            this.cart_Btn_Active=false
      }
    })
    if(userData != null){
      let wishData:[]
      this._wishlistService.getWishList().subscribe((data:any)=>{
        wishData = data.data.results[0].wishlists
        if(wishData?.length != 0){
          wishData?.filter((data:any)=>{
            if(data.variant_id == this.productDetails?.id){
              this.wishListed = true
            }
          })
        }
      })
      this.auth.getUserAddress().subscribe((data:any)=>{
        let is_default=data.data.customer_user_data.filter((data:any)=>data.is_default==true)
        if(data.data.customer_user_data.length == 0){
          this.isAddress = false
        }else{
          this.isAddress = true
          if(data.data.customer_user_data.length <= 1){
            this.billingAddress = data.data.customer_user_data[0]
            this.shippingAddress = data.data.customer_user_data[0]
          }else{
            if(is_default.length >0){
              this.billingAddress = is_default[0]
              this.shippingAddress = is_default[0]
            }else{
              this.billingAddress = data.data.customer_user_data[0]
              this.shippingAddress = data.data.customer_user_data[0]
            }
          }
          this.userDetailAdress = data.data.customer_user_data
        }
      })
      this._cartService.getCart().subscribe((data:any)=>{
        this.cartData = data.data
      })
      this.cartData.filter((data:any)=>{
        if(data.variant_id == this.productRoute?.id){
          this.currentQty = data.quantity
        }
      })
    }else{
      let CartData = localStorage.getItem('CartData')
      if(CartData != null){
        let cart = JSON.parse(CartData)
        this.cartData = cart.cart_products
      }
      this.cartData.filter((data:any)=>{
        if(data.variant_id == this.productRoute?.id){
          this.currentQty = data.quantity
          this.itemCart =true
        }
      })
    }
   
    
  }
  testFun(){
    this.showModal=true
  }
  editAddress(address:any){
    this.isEditAddress = true
    this.edit_address = address
    
  }

  display: boolean = false;

  showDialog() {
      this.display = true;
  }

  addCart(prod:any){
   
    if(!this.productDetails.is_stock){
      this.toaster.warning('Out of stock','',{timeOut:3000});
      return
    }
    localStorage.removeItem('CartData')
    if(!this.isLogin){
      let product = {
        id: prod.id,
        inventory_id: prod.inventory_id,
        image1: prod.images[0],
        name:prod.name,
        price:prod.selling_price,
      } 
      this._cartService.addToLocalCart(product,this.currentQty)
      // this.cart_Btn_Active=false
      // setTimeout(() => {
      //   window.location.reload()
      // }, 2000);
      // this.toastr.warning('Please Login','',{timeOut:3000});
      return
    }else{
      let product = {
        id: prod.id,
        inventory_id: prod.inventory_id,
        image1: prod.images[0],
        name:prod.name
      }   
      // setTimeout(() => {
      //   window.location.reload()
      // }, 2000);
      this._cartService.addToCart(product,this.currentQty) 
      // this.cart_Btn_Active=false
    }
  }
  checkout(){
    if(!this.productDetails.is_stock){ 
      this.toaster.warning('Out of stock','',{timeOut:3000});
      return
    }
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    if(userData ==null) {
      this.router.navigate(['/auth'])
    }
    this.loader = true
    let orderLineData:any = []
    let cartItem:any = {
      variant_id: this.productDetails.id,
      inventory_id: this.productDetails.inventory_id,
      image: this.productDetails.images[0],
      name: this.productDetails.name,
      quantity: this.currentQty,
      price:this.productDetails.selling_price,
      total_price: this.productDetails.selling_price * this.currentQty
    }
    this.checkoutList[0] = cartItem
    this.checkoutList.filter((data:any)=>{
      this.checkoutList[0] = cartItem
      let orderline:any = {
        cart_id: null,  
        variant_id: data.variant_id,  
        total_quantity: data.quantity, 
        branch_id: null,  
        amount: data.total_price,  
        is_active: true, 
        inventory_id:data.inventory_id,
        name:data.name,
        delivery_time:null,
        seller_id:null,
        branch_code:null,
        image:data.image,
        buy_direct: true
      }
      orderLineData.push(orderline)
      orderline = {}
    })
    let order ={
      orderlines: orderLineData,
      user_id: userData.customer_usercode,
      delivery_mode: "Instant",
      is_single_delivery: true,
      delivery_date: null,
      delivery_address_id: this.shippingAddress.id,
      billing_address_id: this.billingAddress.id
    }
    setTimeout(() => {
      this._order.orderSubmit(order)
      this.loader = false
      this._cartService.addCheckout(this.checkoutList)
    }, 1000);
    
  }
  buyNow(){
    alert("buy now home");
  }
  wishListAdd(){
    alert("add")
  }
  changeWishlist(id:any) {
    this._wishlistService.addToWishList(id)
    this.wishListed = !this.wishListed
  }
  ngAfterViewInit(){
    window.scroll(0,0)
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    if(userData != null){
      let wishData:[]
      this._wishlistService.getWishList().subscribe((data:any)=>{
        wishData = data.data.results[0].wishlists
        
        if(wishData?.length != 0){
          wishData?.filter((data:any)=>{
            if(data.variant_id == this.productDetails?.id){
              this.wishListed = true
            }
          })
        }
      })
    }else{
      let CartData = localStorage.getItem('CartData')
      if(CartData != null){
        let cart = JSON.parse(CartData)
        this.cartData = cart.cart_products
        // cart_products
      }
      this.cartData.filter((data:any)=>{
        
        if(data.variant_id == this.productDetails?.id){
          this.currentQty = data.quantity
        }
      })
    }
    
  }
  ngOnInit(): void {
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    this.productRoute ={
      id: this.route.snapshot.params['id'],
    }
    this.addAdress = new FormGroup({
      buildingname:new FormControl('',[Validators.required]),
      street: new FormControl('',[Validators.required]),
      city: new FormControl('',[Validators.required]),
      address: new FormControl('',[Validators.required]),
      contact: new FormControl('',[Validators.required, Validators.email]),
      mobile: new FormControl('',[Validators.required]),
      pincode: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      landmark: new FormControl('', [Validators.required]),
      location: new FormControl('', [Validators.required]),
      area: new FormControl('', [Validators.required]),
      time: new FormControl('', [Validators.required]),
      saveUserNameCheckBox:new FormControl('',[Validators.required])
    });
    if(userData != null){
      this._reviewService.getRatingOnVarient(this.productRoute.id).subscribe((data:any)=>{
        this.rating = data.data.rating_of_variant
        if(this.rating == null){
          this.rating = 0
        }
      })
      this._reviewService.getComments(this.productRoute.id).subscribe((data:any)=>{
        this.review = data?.data?.review_data 
      })
      let wishData:any = this._wishlistService.getWithoutData()
      
      if(wishData?.length != 0){
        wishData?.filter((data:any)=>{
          if(data.variant_id == this.productDetails.id){
            this.wishListed = true
          }
        })
      }
      let cartListArray:any=[]
      let findArray:any=[]
      this._cartService.getCart().subscribe((cartList:any)=>{
        cartListArray = cartList.data
        console.log(cartListArray,"cartListArray");
        this.findArray=cartListArray.filter((d:any)=>d.variant_id==this.productDetails.id)
        console.log(findArray,"findArray......");
        if(this.findArray.length==0){
              this.cart_Btn_Active=false
        }
      })
    }
   
    this._productDetails.getProductDetails(this.productRoute.id).subscribe((data:any)=>{
      if(data.status == 'fail' || data.status == 'error'){
        this.isProduct = false
        return
      }else{
        this.isProduct = true
        this.productDetails = data.data
        let code = Object.keys(this.productDetails?.variant_meta?.catelog?.key_values)
        let name = Object.values(this.productDetails?.variant_meta?.catelog?.key_values)
        for(let i=0; i<code.length;i++){
          let obj = {
            key: code[i],
            value: name[i]
          }
          this.catelogArray.push(obj)
        }
        console.log(this.catelogArray,"````````````");
        console.log(this.productDetails,"productdetails");
        
      }
    })
   
    setTimeout(() => {
      if(this.productDetails.id == undefined){
        this.isProduct = false
      }
    }, 3000);
    
    
    this._productDetails.getSimilarProduct(this.productRoute.id).subscribe((data:any)=>{
      this.similarProducts = data.data
    })
    
    if(userData != null){
      this.address = this.auth.getProperAddress()
      this.isLogin = true
      this._productService.getRecomandedOne(userData.customer_usercode).subscribe((data:any)=>{
        this.recomanded = data.data
      })
    }else{
      this.isLogin = false
    }
    
    if(userData != null){
      this._productService.getLinkedItem(this.productRoute.id).subscribe((data:any)=>{
        this.linkedItem=data.data
        console.log(this.linkedItem,"99999999999999999999999");
        
   })
    }else{
      this.isLogin = false
    }
    if(userData != null){
      this._productService.getRelatedItem(this.productRoute.id).subscribe((data:any)=>{
        this.relatedProducts=data.data
        console.log(this.relatedProducts,"99999999999999999999999");
        
   })
    }else{
      this.isLogin = false
    }
    this._productService.getRelatedItem(this.productRoute.id).subscribe((data:any)=>{
      this.relatedProducts=data.data
      console.log(this.relatedProducts,"99999999999999999999999");
      
 })
 this._productService.getLinkedItem(this.productRoute.id).subscribe((data:any)=>{
  this.linkedItem=data.data
  console.log(this.linkedItem,"99999999999999999999999");
  
})
  }
  selectImage(index:any){
    this.hover = true
    this.zoomedImage = this.productDetails.images[index]
    this.zoomImage(index)
  }
  zoomImage(index:any){
    this.enlargedImage.img = this.productDetails.images[index]
    this.enlargedImage.index = index
  }
  removeImage(){
    this.hover = false
    this.zoomedImage =''
  }

  reduce(){
    if(this.currentQty > 1){
      this.currentQty = this.currentQty - 1
    }
  }
  adder(){
    if(this.currentQty < this.maxQty){
      this.currentQty = this.currentQty + 1
    }
  }
  Imagelocation(e:any,index:any){
    this.zoomedImage = this.productDetails.images[index]
    this.zoomImage(index)
    this.imageLocX = '-' + e.screenX
    this.imageLocY = '-' + e.screenY
  }
  routeTo(id:any){
    this.router.navigate(['/product/'+id])
    setTimeout(() => {
      window.location.reload()
    }, 1000);
  }
  showAvailableAddress(){
    this.addNew = false
    this.isEditAddress=false
  }
  UpdateAddress(id:any){
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    let code = "+971"
    let addresss = {}
    if(this.edit_address.contact.includes('+971')){
      addresss = {
        id : id,
        user_code : userProfile.customer_usercode,
        street_name: this.edit_address.street_name,
        building_name: this.edit_address.building_name,
        full_name : this.edit_address.full_name,
        location : this.edit_address.location,
        country : this.edit_address.country,
        state : this.edit_address.state,
        city : this.edit_address.city,
        landmark : this.edit_address.landmark,
        contact : this.edit_address.contact,
        is_active : true,
        address_type : null,
      }
    }else{
      addresss = {
        id : id,
        user_code : userProfile.customer_usercode,
        street_name: this.edit_address.street_name,
        building_name: this.edit_address.building_name,
        full_name : this.edit_address.full_name,
        location : this.edit_address.location,
        country : this.edit_address.country,
        state : this.edit_address.state,
        city : this.edit_address.city,
        landmark : this.edit_address.landmark,
        contact : this.edit_address.contact,
        is_active : true,
        address_type : null,
      }
    }
    this.auth.updateUserAddress(addresss,id)
    setTimeout(() => {
      window.location.reload()
    }, 500);
  }
  createAddress(){
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    let addressDataAdd = {
      street_name: this.street,
      building_name: this.buildingname,
      user_code: userProfile.customer_usercode, 
      full_name: this.addressValue,
      location: this.location,
      city: this.city, 
      landmark: this.landmark,
      contact: '+971'+this.contact,
      is_default: true,
      is_active: true,
      address_type: null,
      country: this.country,
      state: this.state
    }
    this.auth.createAddress(addressDataAdd)
  }
  chooseCurrentLocationNew(){
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    if(userProfile !=null){
      this.auth.getUserProfile().subscribe((data:any)=>{
        this.addressValue  = data.data.fname + ' ' + data.data.lname
        this.contact = data.data.alternative_mobile_no
      })
    }
   
    let current_address:any = localStorage.getItem('current-location')
    if( current_address !=null){
      current_address = JSON.parse(current_address)
      this.city = current_address.results[0].components.city
    }
    
  }

  chooseCurrentLocationEdit(){
    let current_address:any = localStorage.getItem('current-location')
    current_address = JSON.parse(current_address)
    let user: any = localStorage.getItem('marketplaceUser');
    let userProfile = JSON.parse(user)
    if(userProfile !=null){
      this.auth.getUserProfile().subscribe((data:any)=>{
        this.edit_address.full_name  = data.data.fname + ' ' + data.data.lname
        this.edit_address.contact = data.data.alternative_mobile_no
      })
    }
    if( current_address !=null){
      this.edit_address.city = current_address.results[0].components.city
    }
    
  }

  selectShippingAddress(address:any){
    this.shippingAddress = address
  }
  selectBillingAddress(address:any){
    this.shippingAddress = address
  }

  selectAddress(is_default:any,i:any){
    if(is_default){
      return i
    }else{
      return 0
    }
  }
  onSeachRoute(e:any){
    this.categoryService.getSearchSuggesion(e).subscribe((data:any)=>{
      this.searchSuggesion = data.data
    })
  }
  onSearch(e:any){   
    this.onSearchFocus = true
    // this.messageService.sendsearchValue(e)
    this.router.navigate(['/search/' + e])
    // this.onFocusOut()
    // this.messageService.sendSearchWord(e)
  }
}
