

<div class="d-flex flex-wrap align-items-start w-100 p-4 bg-sidebar cart-body">
    <div class="col d-flex flex-wrap pe-md-4">
        <h4 class="fw-500 d-flex fz-18 w-100 col-12 mb-3 ms-2 justify-content-between align-items-center ms-md-0 border-bottom pb-3">
            <div class="d-flex align-items-center">
                Checkout
            </div>
        </h4>
        <div class="w-100 d-flex flex-wrap flex-column">
            <div class="w-100 p-4 bg-white d-flex flex-wrap flex-column align-items-start mb-3">
                <label for="" class="fw-bold mb-2">Shipping Address</label>
                <div class="ps-3">
                    <div class="fz-14 my-1">
                        <span>{{order?.order_meta?.address_data?.full_name}},</span>
                        <span class="ms-2">{{order?.order_meta?.address_data?.building_name}}</span>
                     </div>
                     <div class="fz-14 my-1">
                        <span>{{order?.order_meta?.address_data?.street_name}},</span>
                        <span class="ms-2">{{order?.order_meta?.address_data?.landmark}}</span>
    
                     </div>
                     <div class="fz-14 my-1">
                        <span>{{order?.order_meta?.address_data?.country}},</span>
                        <span class="ms-2">{{order?.order_meta?.address_data?.contact}},</span>
                     </div>
                </div>
            </div>
            <div class="w-100 p-4 bg-white d-flex flex-wrap flex-column align-items-start mb-3">
                <label for="" class="fw-bold mb-2">Billing Address</label>
                <div class="ps-3">
                    <div class="fz-14 my-1">
                        <span>{{order?.order_meta?.billing_data?.full_name}},</span>
                        <span class="ms-2">{{order?.order_meta?.billing_data?.building_name}}</span>
                     </div>
                     <div class="fz-14 my-1">
                        <span>{{order?.order_meta?.billing_data?.street_name}},</span>
                        <span class="ms-2">{{order?.order_meta?.billing_data?.landmark}}</span>
    
                     </div>
                     <div class="fz-14 my-1">
                        <span>{{order?.order_meta?.billing_data?.country}},</span>
                        <span class="ms-2">{{order?.order_meta?.billing_data?.contact}},</span>
                     </div>
                </div>
            </div>
            <div class="w-100 p-4 bg-white d-flex flex-wrap align-items-start">
                <label for="" class="fw-bold mb-4">Payment Method</label>
                <!-- <div class="d-flex align-items-center justify-content-between w-100 border-bottom pb-3 mb-3">
                    <div class="d-flex">
                        <input class="form-check-input" type="radio" name="radioNoLabel" id="radioNoLabel1" value="" aria-label="..." disabled>
                        <div class="ms-3 fz-13">
                            <img src="../../../assets/assets/visa.png" class="card-type me-2">
                            **** **** **21 3452
                        </div>
                    </div>
                    <button class="btn text-primary fz-14">+ Add new card</button>
                </div> -->
                <div class="d-flex flex-column align-items-center justify-content-between w-100 pb-2 mb-3">
                    <div class="d-flex flex-wrap align-items-start w-100">
                        <input class="form-check-input" type="radio" name="radioNoLabel" id="radioNoLabel1" value="card" aria-label="..." [checked]="mode=='card'" (change)="changeMode('card')">
                        <div class="ms-3 fz-13 d-flex flex-column">
                            Credit / Debit / ATM Card
                            <!-- <span class="text-success fz-10 mt-1">You save AED 20 when apply with Kodak bank credit card is added</span> -->
                        </div>
                      
                        <!-- <div class="card-form ps-4 ms-1 mt-4 mb-3">
                            <div class="mb-3 col-md-4 pe-3">
                                <label for="exampleFormControlInput1" class="form-label fz-13">Name on the card</label>
                                <input type="email" class="form-control fz-13" [(ngModel)]="cardName">
                            </div>
                            <div class="mb-3 col-md-4 pe-3">
                                <label for="exampleFormControlInput1" class="form-label fz-13">Card number</label>
                                <input type="email" class="form-control fz-13" maxlength="23" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" id="exampleFormControlInput1" placeholder="- - - -   - - - -   - - - -   - - - -" (input)="cardNumberChange()" [(ngModel)]="cardNumber">
                                <span class="fz-12 text-danger">{{cardNumberValid}}</span>
                            </div>
                            <div class="col-md-4 d-flex flex-wrap">
                                <label for="exampleFormControlInput1" class="form-label fz-13 w-100">Expiry date </label>
                                <div class="mb-3 card-date me-3">
                                    <input type="number" class="form-control card-date fz-13" [(ngModel)]="cardExMonth" (change)="changeMonth()" placeholder="MM" min="1" max="12" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="2" placeholder="mm">
                                </div>
                                <div class="mb-3 card-date">
                                    <input type="number" class="form-control card-date fz-13" [(ngModel)]="cardExYear" placeholder="YY" (input)="onyearchange()" min="21" placeholder="yy" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="2" >
                                </div>
                                <span class="fz-12 d-block w-100 text-danger">{{cardYearExValid}}</span>
                            </div>
                            <div class="col-md-4 d-flex flex-wrap">
                                <label for="exampleFormControlInput1" class="form-label fz-13 w-100">CVV</label>
                                <div class="mb-3 card-cvv me-3">
                                    <input type="password" class="form-control card-cvv fz-13" [(ngModel)]="cardCVV" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" minlength="3" maxlength="3" placeholder="- - -">
                                    <span class="fz-12 text-danger">{{cvvValid}}</span>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-check p-0 fz-13 d-flex align-items-center">
                                    <input class="form-check-input  m-0" type="checkbox" value="" id="flexCheckDefault">
                                    <label class="form-check-label ps-2" for="flexCheckDefault">
                                      Save this Card
                                    </label>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="d-flex w-100 ms-5 mt-2">
                        <img class="ms-1" src="../../../assets/assets/payment1.png" alt="" width="40" height="22">
                        <img class="ms-1" src="../../../assets/assets/card2 (1).png" alt="" width="40" height="22">
                        <img class="ms-1" src="../../../assets/assets/card2 (2).png" alt="" width="40" height="22">
                        <img class="ms-1" src="../../../assets/assets/card2 (3).png" alt="" width="40" height="22">
                    </div>
                </div>
                <!-- <div class="d-flex align-items-center justify-content-between w-100 border-bottom pb-3 mb-3">
                    <div class="d-flex align-items-center">
                        <input class="form-check-input" type="radio" name="radioNoLabel" id="radioNoLabel1" value="" aria-label="..." disabled>
                        <div class="ms-3 fz-13 d-flex flex-column">
                            Net Banking
                        </div>
                    </div>
                </div> -->
                <!-- <div class="d-flex align-items-center justify-content-between w-100 border-bottom pb-3 mb-3">
                    <div class="d-flex align-items-center">
                        <input class="form-check-input" type="radio" name="radioNoLabel" id="radioNoLabel1" value="" aria-label="..." disabled>
                        <div class="ms-3 fz-13 d-flex flex-column">
                            Apple Pay
                        </div>
                        <apple-pay-button buttonstyle="black" type="plain" locale="en"></apple-pay-button>
                    </div>
                </div> -->
                <div class="d-flex align-items-center justify-content-between w-100 pb-2">
                    <span *ngIf="!order?.order_meta?.cod">
                        Cash On Delivery Not Applicable
                    </span>
                    <div class="d-flex align-items-center" *ngIf="order?.order_meta?.cod">
                        <input class="form-check-input" type="radio" name="radioNoLabel" id="radioNoLabel1" value="cod" aria-label="..."  [checked]="mode=='cod'" (change)="changeMode('cod')">
                        <div class="ms-3 fz-13 d-flex flex-column">
                            Cash on Delivery 
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex align-items-center justify-content-between w-100 border-bottom pb-3 mb-3">
                    <div class="d-flex align-items-center">
                        <input class="form-check-input" type="radio" name="radioNoLabel" id="radioNoLabel1" value="" aria-label="..." disabled>
                        <div class="ms-3 fz-13 d-flex flex-column">
                            EMI (Easy Installments)
                        </div>
                    </div>
                </div> -->
                
            </div>
        </div>
        
    </div>
    <div class="cart-sidebar flex-wrap ms-md-5">
        <div class="cart-total">
            <div class="cart-total__header">
                Checkout
            </div>
            <div class="cart-total__body position-relative">
                <span class="d-block w-100 fw-500 fz-14 mb-3">Items</span>
                <label for="" class="checkout-item d-flex flex-wrap" *ngFor="let item of checkoutArray|slice:0:showProductLess">
                    <img [src]="item.image" alt="">
                    <label class="text-truncate-2">{{item.name}}</label>
                    <span class="">{{item.total_price | currency:'AED'}}</span>
                    <div class="w-100 ms-5">
                        <span style="color: lightskyblue; " class="w-100 ps-3">{{deliver_notes}}</span>
                    </div>
                </label>
                <a *ngIf="showProductLess ==2 && checkoutArray.length>2" class="text-primary fz-13 border-bottom mt-3" (click)="viewAll()">View all</a>
                <a *ngIf="showProductLess == checkoutArray.length && checkoutArray.length>2" class="text-primary fz-13 border-bottom mt-3" (click)="viewLess()">View less</a>
                <div class="d-flex flex-wrap border p-3 mt-4">
                    <span class="d-block w-100 fw-500 fz-14 mb-3" *ngIf="orderSlot?.length!=0">Slots</span>
                    <div class="form-check d-flex flex-wrap pe-2 col-6" *ngFor="let item of orderSlot;let i = index">
                        <input class="form-check-input mt-0 me-1" type="radio" [id]="item.name" (change)="changeSlot(item.id,item.delivery_charge)" [checked]="orderSlotId == item.id">
                        <label class="form-check-label fz-13 w-100" [for]="item.name">
                          {{item.name}} 
                        </label>
                        <span class="pt-1 fw-bold" style="font-size: 9px; color: green; ">{{item.notes}}</span>
                    </div>
                </div>
                <div class="coupon-code my-3">
                    <label class="form-label">Apply coupon code </label>
                    <input type="text" class="form-control col" placeholder="Gift card/Coupon code" [(ngModel)]="couponCode">
                    <button class="btn btn-primary" (click)="applyCoupon()">
                        Apply
                    </button>
                </div>
                <!-- <span class="fz-12 mt-3 d-block w-100">Delivery Time : 21st October 2021</span> -->
                <div class="cart-total__control mt-1">
                    <label for="" class="cart-total__body--item p-0 mb-3">
                        <span>Price ({{checkoutArray.length}} items)</span>
                        <span>{{currency}} {{totalCart | number:'1.2-2'}}</span>
                    </label>
                    <label for="" class="cart-total__body--item p-0 mb-3">
                        <span>Discount</span>
                        <span class="text-success">{{currency}} {{discount | number:'1.2-2'}}</span>
                    </label>
                    <label for="" class="cart-total__body--item p-0 mb-3">
                        <span>Delivery Charges</span>
                        <span *ngIf="deliveryCharge != 0">{{deliveryCharge | currency:'AED'}}</span>
                        <span class="text-success" *ngIf="deliveryCharge == 0">FREE</span>
                    </label>
                    <label for="" class="cart-total__control--item">
                        <span>Total Amount</span>
                        <span>{{currency}} {{totalWithDelivery | number:'1.2-2'}} </span>
                    </label>
                    <!-- <span class="fz-12 text-success">You will save ₹00.00 on this order</span> -->
                    <button class="btn btn-primary text-uppercase" [disabled]="loader" (click)="payNow()">PLACE ORDER</button>
                </div>
            </div>
        </div>
    </div>
</div>    




