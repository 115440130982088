<div class="container-fluid py-5">
    <div class="container">
        <div class="col-12">
            <h4 class="fw-bold">
                About
            </h4>
            <p class="fz-14">
                Hilal Cart is a UAE-based E-commerce website in which we provide a wide variety of
                products of the best quality. We focus on offering the best quality products for our
                valued customers spread across the GCC at affordable prices. We make it a point to stick on to our motto, ‘More Value, More Fast.’ We are keen on providing the quality products in the fastest possible delivery method at the doorstep of our customers in order to serve them in the best possible manner. We take pride in stating that we have been able to satisfy the needs of our esteemed customers since our journey was begun.
                <br>
            </p>
            <p class="fz-14 mt-2">
                Health is wealth, and in hilalcart.com we know the importance of health. We are adamant about providing our customers with only fresh products. Our wide range of products includes fresh fruits, fresh vegetables, meat, fresh fish, and other grocery products which are essential for your daily kitchen needs. Our pace of delivery is the fastest and we make sure that the products you delivered reach you at the time of need. The prominent feature of shopping through hilalcart.com is that our delivery charges are free for those customers who choose to select the less rush hour time slots for delivery. We also provide personalized delivery options for our customers. Through hilalcart.com we intend to create a hassle-free shopping future. We also offer 24x7 customer support to aid our customers. Our distinctive AI system helps you to get suggestions and recommendations from experts about the quality and benefits of the products through our ‘Recommended Products’ section. With a few clicks from your comfort zone, hilalcart.com opens you to the realm of an international shopping experience.

            </p>
            <h5 class="fw-bold">
                Our Mission
            </h5>
            <p class="fz-14 mt-2">
                Providing products through sustainable services, we believe that we are responsible to our customers and at the same time to the environment. We are on a mission to provide the best for our customers and our surroundings. We aim to provide cheaper, fresher, and healthier products
            </p>
            <h5 class="fw-bold">
                Our Vision
            </h5>
            <p class="fz-14 mt-2">
                Our vision is to create a world where shopping is not time-consuming but convenient. Our objective is to commit ourselves to provide high-quality services in an innovative, sustainable and authentic way by creating a global footprint with our quality and integrity.            </p>
        </div>
    </div>
</div>