<div class="policy-header">
    <div class="policy-header__title text-center">
        {{termsUse.title}}
        <div class="w-100 fz-13 text-center mt-3">
            Last updated: {{termsUse.last_updated}}
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container p-font py-5 fz-14" [innerHtml]="termsUse.description">
    </div>
</div>