<app-courosel-slider
    [bannerList]="bannerList?.banner"
>
</app-courosel-slider>


<div class="container-fluid p-md-3 p-1 pb-3 bluebg">
    <div class="container-fluid mt-5">
        <div class="row g-2 g-md-3">
            <div class="col-xl-3 col-md-3 col-6" *ngFor="let category of mainCategory;let i =index" >
                <div class="d-flex flex-wrap align-items-center w-100 category-main-card pointer"   [ngClass]="{active: specialGroups === category.title, four: i==0, two: i==1, three: i==2, one: i==3 }" (click)="specialGroup(category.title,category.shortN)">
                    <!-- <div class="category-main-status" [ngClass]="{active: specialGroups === category.title}"></div> -->
                    <div class="icon">
                        <img class="icon-image-main-category"*ngIf="specialGroups !== category.title" [src]="category.icon" alt="">
                        <img class="icon-image-main-category"*ngIf="specialGroups === category.title" [src]="category.icon2" alt="">
                    </div>
                    <div class="col-xl col-md-12 col pe-3 mt-0">
                        <p class="fz-18 fw-bold mt-2 mb-2  text-capitalize">
                         {{category.displayName | translate}}
                        </p>
                        <p class="fz-12 mt-0 main-filter-detail text-capitalize">
                         {{category.detail}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>                            

<!-- <div class="container-fluid p-0" *ngIf="specialGroups == 'smart'">
    <app-lifestyle></app-lifestyle>  
</div> -->
<div class="container-fluid p-0">
    <app-essential [specialGroups]="specialGroups"></app-essential>
</div>
<!-- <div class="container-fluid p-0" *ngIf="specialGroups == 'bulk'">
    <app-bulk></app-bulk>
</div> -->
<!-- <div class="container-fluid p-0" *ngIf="specialGroups == 'premium'">
    <app-aroundworld></app-aroundworld>
</div> -->

