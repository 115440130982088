<div class="container-fluid">
    <div class="container">
        <div class="col-12 row py-5">
            <h4 class="fw-bold mb-2">
                Contact Us
            </h4>
            <div class="col-7 mt-4">
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label fz-13">Name</label>
                    <input type="email" class="form-control fz-13" id="exampleFormControlInput1" placeholder="name">
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label fz-13">Phone Number</label>
                    <input type="email" class="form-control fz-13" id="exampleFormControlInput1" placeholder="phone number">
                  </div> 
                  <div class="mb-3">
                    <label for="exampleFormControlInput1" class="form-label fz-13">Email address</label>
                    <input type="email" class="form-control fz-13" id="exampleFormControlInput1" placeholder="email address">
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label fz-13">Message</label>
                    <textarea class="form-control fz-13" id="exampleFormControlTextarea1" rows="3"></textarea>
                  </div>
            </div>
            <div class="col-md " data-aos="fade-left" data-aos-duration="3000">
                <div class="d-flex flex-warp my-5">
                    <img src="../../../assets/icons/phone.jpeg" alt="" width="50" height="50">
                    <div class="col d-flex flex-column">
                        <span class="ms-3 fs-5 fw-bold fz-14">Phone</span>
                        <span class="ms-3 fz-14">+971 565947777</span>
                    </div>
                </div>
                <div class="d-flex flex-warp align-items-start my-5">
                    <img src="../../../assets/icons/location.jpeg" alt="" width="50" height="50">
                    <div class="col d-flex flex-column">
                        <span class="ms-3 fs-5 fw-bold">Address</span>
                        <span class="ms-3 fz-14">
                            RGC DYNAMICS LLC
                            SHAMS MEDIA FREEZONE 
                            SHARJAH 
                            UAE
                            PO box 17582
                        </span>
                    </div>
                </div>
                <div class="d-flex flex-warp my-5">
                    <img src="../../../assets/icons/email-logo-1.jpg" alt="" width="50" height="50">
                    <div class="col d-flex flex-column">
                        <span class="ms-3 fs-5 fw-bold fz-14">Email Address</span>
                        <span class="ms-3 fz-14">info@rgcdynamics.com</span>
                    </div>
                </div>
                <!-- <section>
                    <div class="container-fluid bg-white">
                        <div class="container" >
                                <div class="row  mt-5 pb-3">
                                 
                                    <div class="col-md  mt-4">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3912.79504776243!2d75.77007121495677!3d11.276471052847537!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65f52b96e218d%3A0x12f8c43f06a6987e!2sRGC%20Dynamics!5e0!3m2!1sen!2sin!4v1636605711626!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section> -->
            </div>
        </div>
    </div>
</div>