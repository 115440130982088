<!-- Banner -->
<div class="container-fluid d-flex p-0 w-100 banner">
    <div class="col-4 d-md-flex d-none bg-location shop-locator">
        <div class="clip-bg">
            
        </div>
        <div class="d-flex flex-wrap banner-details">
            <h4 class="title mb-2 d-sm-block d-none">Your everyday, right away</h4>
            <!-- <h4 class="title m-2">Order from hundreds of store nearby</h4> -->
            
            <label class="sub-title mb-3 d-sm-block d-none" for="">
                Order food and grocery delivery online from hundreds 
                of restaurants and shops nearby.
            </label>
            <div class="location-search d-flex  w-100">
                <div class="position-relative form">
                    <i class="bi bi-geo-alt"></i>
                    <input class="form-control" list="brow" type="text" name="" placeholder="search location">
                    
                    <datalist id="brow">
                        <option value="Internet Explorer">
                        <option value="Firefox">
                        <option value="Chrome">
                        <option value="Opera">
                        <option value="Safari">
                    </datalist>  
                </div>
                
                <button class="btn btn-main w-85px ms-3 h-100">Let's Go</button>
            </div>  
        </div>
    </div>
    <div class="col">
        <div class="uk-position-relative uk-visible-toggle uk-light h-100" tabindex="-1" uk-slideshow=" autoplay-interval: 5000; autoplay: true">

            <ul class="uk-slideshow-items">
                <li *ngFor="let banner of bannerList">
                    <img [src]="banner.src" class="object-fit" alt="">
                </li>
            </ul>
        
            <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slideshow-item="previous"></a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slideshow-item="next"></a>
        
        </div>
        
    </div>
</div>
<!-- End Banner -->
<div class="mobile-shop-locator">
    <div class="d-flex flex-wrap justify-content-center text-center banner-details">
        <h4 class="title mb-2">Your everyday, right away</h4>
        <label class="sub-title mb-3" for="">
            Order food and grocery delivery online from hundreds 
            of restaurants and shops nearby.
        </label>
        <div class="location-search d-flex justify-content-center w-100">
            <div class="position-relative form">
                <i class="bi bi-geo-alt"></i>
                <input class="form-control" list="brow" type="text" name="" placeholder="search location">
                
                <datalist id="brow">
                <option value="Internet Explorer">
                <option value="Firefox">
                <option value="Chrome">
                <option value="Opera">
                <option value="Safari">
                </datalist>  
            </div>
            
            <button class="btn btn-main w-85px ms-3 h-100">Let's Go</button>
        </div>  
    </div>
</div>