<div class="container px-md-0 register px-lg-5 py-5">
  <div class="row m-0 p-0">
    <!-- <form action=""> -->
    <h2 class="text-center mb-2"><b>Register</b></h2>
    <p class="text-center w-75 mx-auto fz-14">
      Please fill the data to create an account
    </p>
    <form class="row m-0" [formGroup]="signUpForm" (ngSubmit)="onSignUp()" autocomplete="off">
      <div class="row d-flex justify-content-center m-0 p-0 mt-lg-3 mt-0">
        <div class="col-md-5 col-sm-12 ps-md-0 ">
          <div class="form-outline mb-4 w-100">
            <input type="text" class="form-control" name="ahlanCartFname" placeholder="First Name" formControlName="fName" autocomplete="off" />
          </div>
        </div>
        <div class="col-md-5 col-sm-12 ps-md-0 ">
          <div class="form-outline mb-4 w-100">
            <input type="text" class="form-control" name="ahlanCartLname" placeholder="Last Name" formControlName="lName" autocomplete="off" />
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center m-0 p-0">
        <div class="col-md-5 col-sm-12 ps-md-0 ">
          <div class="form-outline mb-4 w-100">
            <select class="form-select" aria-label="Default select example" formControlName="gender">
              <option selected value>Gender</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="N">I prefer not to say</option>
            </select>
          </div>
        </div>
        <div class="col-md-5 col-sm-12 ps-md-0 ">
          <div class="form-outline mb-4 w-100">
            <select class="form-select" aria-label="Default select example" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}" (change)="currentCountry()" autocomplete="off">
              <option value sslected>Nationality</option>
              <option *ngFor="let con of country" [value]="con">{{con}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center m-0 p-0">
        <div class="col-md-5 col-sm-12 ps-md-0 ">
          <div class="form-outline mb-4 w-100">
            <input type="email" class="form-control"  name="ahlanCartEmail" placeholder="Email" formControlName="email" (change)="emailCheck($event)"autocomplete="off"/>
          </div>
        </div>
        <div class="col-md-5 col-sm-12 ps-md-0 ">
          <div class="form-outline mb-4 w-100">
            <input type="number" class="form-control" name="ahlanCartNumber" placeholder="number" formControlName="phone" [(ngModel)]="checkNum" autocomplete="phone" autocomplete="off" (input)="numberValidate(checkNum)" />  
            <!-- <small *ngIf=" signUpForm.controls['phone'].invalid" class="text-danger">Phone no is Required(minimum length 10,numbers are only allowed)</small> -->
            <span *ngIf="is_number==true" class="text-danger fw-bold fz-10" >Not valid number</span>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center m-0 p-0">
        <div class="col-md-5 col-sm-12 ps-md-0 mb-4">
          <div class="form-outline  w-100 position-relative">
            <input [type]="passwordFeild1" class="form-control" name="ahlanCartPassword" placeholder="Password" formControlName="password" autocomplete="off" (input)="confirmPassword($event)" autocomplete="password" [(ngModel)]="passwordStrength">
            <i (mouseover)="passwordFeild1 = 'text'" (mouseleave)="passwordFeild1 = 'password'" (click)="showPassword1()"  class="show-password bi bi-eye-fill"></i>
          </div>
          <ng9-password-strength-bar
              [passwordToCheck]="passwordStrength"
              [barLabel]="barLabel"
              [customThresholds]="thresholds"
              [barColors]="myColors">
          </ng9-password-strength-bar>
        </div>
        <div class="col-md-5 col-sm-12 ps-md-0  mb-3">
          <div class="form-outline mb-1 w-100 position-relative">
            <input
            [type]="passwordFeild2"
              class="form-control"
              placeholder="Confirm Password"
              formControlName="confirmPassword" (input)="confirmPassword($event)" autocomplete="off"
            />
            <i (mouseover)="passwordFeild2 = 'text'" (mouseleave)="passwordFeild2 = 'password'" (click)="showPassword2()"  class="show-password bi bi-eye-fill"></i>
          </div>
          <span class="invalid fz-12 text-danger" *ngIf="passwordMatch == false">password not matching</span>
        </div>
        <div class="col-md-11 col-sm-12 ps-md-4 d-flex align-items-start justify-content-start flex-wrap">
          <div class="form-check fit-height d-flex align-items-center w-100">
            <input class="form-check-input m-0" type="checkbox" value="" id="flexCheckDefault" (change)="conditionOneFun($event)">
            <label class="ms-2 form-check-label fz-14" for="flexCheckDefault">
              I agree to Ahlancart <a [routerLink]="['/privacyPolicy']" routerLinkActive="router-link-active" style="color: #086db5;">Trems and Condition</a>
            </label>
          </div>
          <div class="form-check fit-height d-flex align-items-center w-100">
            <input class="form-check-input m-0" type="checkbox" value="" id="flexCheckDefaultone" (change)="conditionTwoFun($event)">
            <label class="ms-2 form-check-label fz-14" for="flexCheckDefaultone">
              I have read, I understand and acknowledge Ahlancart <a [routerLink]="['/privacyPolicy']" style="color: #086db5;" routerLinkActive="router-link-active">Privacy policy</a>
            </label>
          </div>
          <div class="form-check fit-height d-flex align-items-center w-100">
            <input class="form-check-input m-0" type="checkbox" value="" id="flexCheckDefaulttwo" (change)="conditionThreeFun($event)">
            <label class="ms-2 form-check-label fz-14" for="flexCheckDefaulttwo">
              I want to receive the latest offers from Ahlancart and its trusted partners via SMS and all other channels, 
            </label>
          </div>
        </div>
      </div>
      <div class="pt-3 pb-1 w-100 px-5 text-left d-flex align-items-left flex-column">
        <div class="d-flex justify-content-center">
          <button class="btn btn-primary mb-3 mt-2" type="submit" [disabled]="signUpForm.invalid ||conditionOne==false || conditionTwo==false || conditionThree==false">REGISTER</button>
        </div>
      </div>
    </form>
    <span  class="text-center mb-4 mt-2 fz-14" style="color: black;">Already have an account? <a class="ms-2 fw-500" style="color: #086db5;" [routerLink]="['/auth']" routerLinkActive="router-link-active" >LOGIN</a></span>
  </div>
</div>


