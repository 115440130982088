<div class="bg-white mt-3" *ngIf="isProduct">
    <div class="container-fluid pt-2 pb-5 d-md-flex d-none flex-wrap">
        <nav class="" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-1 text-uppercase">Home /</li>
                <li class="breadcrumb-item ps-1">{{productDetails?.category_name}} / </li>
                <li class="breadcrumb-item ps-1 text-truncate-1" aria-current="page">
                    <span *ngIf="productDetails?.display_name ==''">{{productDetails?.name}}</span>
                    <span *ngIf="productDetails?.display_name !=''">{{productDetails?.display_name}}</span>
                </li>
            </ol>
        </nav>
    </div>

    <!-- <div class="zoomed-image" *ngIf="zoomedImage !=''">
        <img [src]="zoomedImage" [ngClass]=" hover == true ? 'hover-zoom': ''"  [style.top]="imageLocY +'px'" [style.left]="imageLocX +'px'">
    </div> -->
    <div class="container-fluid">
        <div class="d-flex flex-wrap align-items-start border-bottom pb-3  p-xl-0 p-3">
            <div class="col-12 row">
                <div class="col-xl-8 col-12 d-flex flex-row clearfix">
                     <div class="d-flex flex-row w-md-100 w-100">
                         <div class="group_Img d-flex flex-column my-3 cursor">
                             <div class="img_box my-1 cursor" *ngFor="let item of productDetails?.images" (click)="imageChanges(item)">
                                 <img src="{{item}}" alt="">
                             </div>
                         </div>
                         <div class="big_image d-flex flex-column  mx-2">
                             <div class="d-flex justify-content-end">
                                <i 
                                    class="bi pointer fz-18 text-bulk" 
                                    [ngClass]="wishListed === true ? 'bi-heart-fill' : 'bi-heart'"
                                    (click)="changeWishlist(productDetails?.id)" *ngIf="isLogin"
                                ></i>
                             </div>
                            <ng-img-magnifier 
                                [thumbImage]='mainImage' [fullImage]='mainImageThumb'
                                [top]='top' [right]='right'
                                [lensWidth]='lensewidth' [lensHeight]='lensheight'
                                [resultWidth]='resultWidth' [resultHeight]='resultheight'
                                [imgWidth]='imgWidth' [imgHeight]='imgheight'>
                            </ng-img-magnifier>
                             <!-- <img src="../../../assets/test/fr 1.png" alt=""  > -->
                          </div>
                     </div>
                     <div class="ms-2 w-md-100 w-md-0 w-100">
                         <div>
                            <span class="fz-18 fw-bold cb">{{productDetails?.display_name | titlecase}}</span>
                         </div>
                         <div class="mt-2 d-flex flex-row align-items-center">
                            <span class="fz-14">
                                {{productDetails?.brand_name | titlecase}}
                            </span>
                            <button *ngIf="productDetails.rating" class="rating_icon  ms-2"><i class="bi bi-star-fill me-1"></i> {{productDetails.rating}}</button>
                         </div> 
                         <hr class="my-2"> 
                         <div>
                             <span *ngIf="productDetails?.category_name!=null" class="fz-12 fw-bold mt-2" >Category Name - {{productDetails?.category_name | titlecase}}</span>
                         </div>
                         <div>
                             <span *ngIf="productDetails?.return_days!=null" class="fz-12 fw-bold mt-2"> Stock Count - {{productDetails?.return_days}}</span>
                         </div>
                         <div class="d-flex flex-row align-items-center mt-2" *ngIf="productDetails?.offer_details?.has_offer">
                             <button class="offer_box">{{productDetails?.offer_percentage_price | number : '1.2-2'}}% off</button>
                             <span class="fz-10 ms-2">You save {{currency}} {{ productDetails?.selling_price - productDetails?.offer_price  | number : '1.2-2' }}</span>
                         </div>
                         <div class="mt-3 col-12 row">
                             <div class="col border-end d-flex flex-column">
                                <span class="fz-16 fw-bold text-price">{{currency}} {{productDetails?.selling_price}}</span>
                                <span class="fz-10 cb">*Inclusive VAT</span>
                             </div>
                             <div class="col d-flex flex-column" *ngIf="productDetails?.offer_details?.has_offer">
                                <span class="fz-16 fw-bold text-danger">{{currency}}  <s>{{ productDetails?.selling_price - productDetails?.offer_price  | number : '1.2-2' }}</s></span>
                                <span class="fz-10 cb">*Inclusive VAT</span>
                             </div>
                         </div>
                         <div class="mt-2" *ngIf="productDetails?.is_stock==true">
                             <span class="fw-bold fz-14 text-success">In Stock</span>
                         </div>
                         <div class="mt-2" *ngIf="productDetails?.is_stock==false">
                            <span class="fw-bold fz-14 text-danger">Out of Stock</span>
                        </div>
                        <label for="" class="info w-100 d-block mt-3 mb-2 fz-14" *ngIf="productDetails?.sales_uom_name !=null">Unit :  <b>{{productDetails?.sales_uom_name}}</b></label>
                        <label for="" class="info w-100 d-block mb-2 fz-14" *ngIf="productDetails?.gross_weight !=null">Gross Weight :  <b>{{productDetails?.gross_weight}}</b></label>
                         <div class="mt-2">
                            <i class="bi bi-geo-alt fz-14"></i>
                            <span class="fz-14 ms-2">Origin: {{productDetails?.produced_country}}</span>
                         </div>
                         <div class="mt-2 ">
                             <!-- <span class="fz-12 cb">Color name : Silver</span> -->
                             <div *ngIf="productDetails?.product_cateloges?.length!=0" class="d-flex flex-row mt-2" >
                                <div class="related_box" >
                                    <img src="" alt="">
                                </div>
                             </div>
                         </div>
                         <!-- <div class="mt-2 ">
                            <span class="fz-12 cb">Style : 468 L</span>
                            <div class="d-flex flex-row mt-2">
                               <div class="size_box d-flex align-items-center justify-content-center">
                                   <span class="fz-13">520 px</span>
                               </div>
                               <div class="size_box ms-1 d-flex align-items-center justify-content-center">
                                   <span class="fz-13">520 px</span>
                              </div>
                            </div>
                        </div> -->
                        <div class="my-2 py-2 border-bottom d-flex flex-column w-100">
                            <!-- <div class="fz-13 fw-300 mb-2">Colour: Elegant Inox</div> -->
                            <div class="fz-13 fw-300 mb-2">Brand: {{productDetails?.brand_name}}</div>
                            <!-- <div class="fz-13 fw-300 mb-2">Model Name: Elegant Inox</div> -->
                            <!-- <div class="fz-13 fw-300 mb-2">Form Factor: Elegant Inox</div> -->
                        </div>
                        <label class="fz-15 fw-500 w-100 mt-2" for="" *ngIf="productDetails?.variant_meta?.about_the_products?.key_values.length != 0">{{productDetails?.variant_meta?.about_the_products?.name}} </label>
                        <ul class="list-content">
                            <li class="text-capitalize" *ngFor="let highlight of productDetails?.variant_meta?.about_the_products?.key_values">
                                {{highlight}}
                            </li>
                        </ul>
                        <label class="fz-15 fw-500 w-100 mt-2" for="" *ngIf="productDetails?.variant_meta?.Ingrediants?.key_values.length != 0">Ingrediants </label>
                        <ul class="list-content">
                            <li class="text-capitalize" *ngFor="let ingrediants of productDetails?.variant_meta?.Ingrediants?.key_values">
                                {{ingrediants}}
                            </li>
                        </ul>
                        <label class="fz-15 fw-500 w-100 mt-2" for="" *ngIf="productDetails?.variant_meta?.storage?.key_values.length != 0">Storage </label>
                        <ul class="list-content">
                            <li class="text-capitalize" *ngFor="let storage of productDetails?.variant_meta?.storage?.key_values">
                                {{storage}}
                            </li>
                        </ul>
                        <label class="fz-15 fw-500 w-100 mt-2" for="" *ngIf="productDetails?.variant_meta?.usage_direction?.key_values.length != 0">Usage direction </label>
                        <ul class="list-content">
                            <li class="text-capitalize" *ngFor="let usage_direction of productDetails?.variant_meta?.usage_direction?.key_values">
                                {{usage_direction}}
                            </li>
                        </ul>
                        <label class="fz-15 fw-500 w-100 mt-2" for="" *ngIf="productDetails?.variant_meta?.product_behaviour?.key_values.length != 0">Consumer behaviour</label>
                        <ul class="list-content">
                            <li class="text-capitalize" *ngFor="let usage_direction of productDetails?.variant_meta?.product_behaviour?.key_values">
                                {{usage_direction}}
                            </li>
                        </ul>
                     </div>
                    </div>
                <div class="col-xl col-12 d-flex flex-wrap justify-content-xl-end justify-content-sm-end justify-content-end">
                    <div class="px-xl-4  mt-4 mt-lg-0 col-xl col-md-7 col-12">
                        <div class="cart-card mt-0">
                            <!-- <div class="uk-inline" *ngIf="isAddress">
                                <button class="uk-button uk-button-default drop-btn text-medium" type="button">Delivery location<span class="small" uk-icon="chevron-down"></span></button>
                                <div class="fz-13" uk-dropdown="mode: click">
                                    {{billingAddress?.full_name}}, 
                                    <br>
                                    {{billingAddress?.location}}, {{billingAddress?.building_name}}, {{billingAddress?.landmark}},
                                    <br>
                                    {{billingAddress?.street_name}}, {{billingAddress?.city}}, {{billingAddress?.country}},
                                    <br>
                                    {{billingAddress?.state}}, {{billingAddress?.contact}}</div>
                            </div> -->
                            <!-- <div class="w-100 border-bottom pb-2" *ngIf="isAddress">
                    
                            </div> -->
                            <div class="w-100 my-3 pb-2 d-none">
                                <div class="form-check me-ms-3">
                                    <input class="form-check-input" type="checkbox" [(ngModel)]="isGift" [checked]="isGift">
                                    <label class="form-check-label fz-11 ms-0" for="flexCheckChecked">
                                        <i class="bi bi-gift-fill"></i>
                                            This item is a gift(Gift Bag/Box - ₹30.00)
                                        <a href="">details</a>
                                    </label>
                                </div>
                            </div>
                            <div class="w-100 py-2"  *ngIf="sellerDetails!=null">
                                <label for="" class="info fw-500 fz-14 cb">SOLD BY :<a class="fw-500 fz-14 cb">{{sellerDetails?.name}}</a></label>
                                <!-- <label for="" class="info">
                                    Get it Friday, Dec 17, 7AM-9PM. Pick a convenient slot at checkout
                                </label> -->
                            </div>
                            <!-- <div class="w-100 d-flex align-items-center mt-3">
                                <div class="quantity-conter">
                                    <button (click)="reduce()"><i class="bi bi-dash"></i></button>
                                    <input class="fz-13" type="text" [(ngModel)]="currentQty">
                                    <button (click)="adder()"><i class="bi bi-plus"></i></button>
                                </div>
                                <span class="ms-3 fz-13">Only 2 left</span>
                            </div> -->
                            <div class="w-100 mt-4 d-flex align-items-center">
                                <span class="fw-bold fz-20">{{currency}} {{productDetails?.selling_price * currentQty  }}</span>
                            </div>
                            <!-- <select class="form-select" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select> -->
                            <!-- <span class="fz-13" *ngIf="itemCart">Item in cart( <span class="fw-500 text-primary pointer border-bottom" style="border-bottom: 1px solid #0d6efd !important;" [routerLink]="['/cart']" routerLinkActive="router-link-active" >Go to cart</span> )</span> -->
                            <div class="w-100 mt-3 d-flex justify-content-start flex-row flex-md-column">
                                <button *ngIf="is_localCart==false && isLogin==false" class="btn btn-primary mb-2" (click)="addToLocal(productDetails)" [disabled]="productDetails?.is_stock==false || productDetails?.selling_price==0">ADD TO CART</button>
                                <button *ngIf="inCart==false&&isLogin==true" class="btn btn-primary mb-2" (click)="addCart(productDetails)"[disabled]="productDetails?.is_stock==false || productDetails?.selling_price==0">ADD TO CART</button>
                                <button *ngIf="inCart==true"  class="btn btn-primary mb-2" (click)="cartRoute()">In Cart</button>
                                <button *ngIf="is_localCart==true"  class="btn btn-primary mb-2" (click)="cartRoute()">In Cart</button>
                                <button class="btn btn-primary" *ngIf="isLogin" uk-toggle="target:#exampleModal" [disabled]="productDetails?.is_stock==false || productDetails?.selling_price==0">BUY NOW</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 order-5 d-flex flex-wrap">
                <div class="col-4 d-md-block d-none d-xl-none">
                    
                </div>
                <div class="col-md col-12 d-xl-none pe-md-5 pe-3">
                    
                    
                </div>
                <div class="product-view d-xl-block d-none"></div>
                <div class="col-6 d-md-block d-none d-xl-none">
                    
                </div>
                <div class="product-purchase col-md col-12 ms-xl-3 pe-md-5 pe-3 ps-3 ps-xl-0">
                    <div class="d-xl-flex flex-wrap w-100">
                        <label for="" class="text-truncate-3 fw-400 fz-15 pb-2 mb-2 pb-2 border-bottom w-100" *ngIf="productDetails?.warranty_details?.length !=0">
                            Warranty
                            <ul class="mt-2 mb-0 fz-13 w-100">
                                <li>24 month warranty <a class="ms-2" href="">view details</a></li>
                            </ul>
                        </label>
                       
                    </div>
                    <!-- <label class="fz-15 fw-500 w-100" for="">Offers</label>
                    <div class="d-flex align-items-start mt-3 w-100">
                        <div class="fit-content fz-13">
                            <b>Bank offer -</b>
                        </div>
                        <div class="col d-flex flex-column ms-3">
                            <label for="" class="info mb-1">5% discount for HSS credit card</label>
                            <label for="" class="info mb-1">7% discount for IDBDI credit card</label>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mt-1 w-100">
                        <div class="fit-content fz-13">
                            <b>Cash back offer -</b>
                        </div>
                        <div class="col d-flex flex-column ms-3">
                            <label for="" class="info mb-1">10% for prime members</label>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mt-1 w-100">
                        <div class="fit-content fz-13">
                            <b>No cost EMI - </b>
                        </div>
                        <div class="col d-flex flex-column ms-3">
                            <label for="" class="info mb-1"> No Cost EMI available <a class="fz-12" href="">EMI options</a></label>
                        </div>
                    </div>
                    <div class="d-flex align-items-start mt-1 w-100">
                        <div class="fit-content fz-13">
                            <b>Exchange offer- </b>
                        </div>
                        <div class="col d-flex flex-column ms-3">
                            <label for="" class="info mb-1">Unavailable for this product </label>
                        </div>
                    </div> -->
                </div>
                <div class="col-6 d-md-block d-none d-xl-none">
                    
                </div>
                <div class="product-cartoffer  mt-4 mt-xl-0 order-3 ps-3 ps-xl-0 pe-4 order-md-3 d-none">
                    <div class="w-100">
                    
                        <div class="uk-inline">
                            <button class="uk-button uk-button-default drop-btn text-medium" type="button">Additional warranty<span class="small" uk-icon="chevron-down"></span></button>
                            <div uk-dropdown="mode: click">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</div>
                        </div>
                    </div>
                    
                    <div class="w-100">
                        <div class="uk-inline mb-2">
                            <button class="uk-button uk-button-default drop-btn text-medium" type="button">Your loyality benefits<span class="small" uk-icon="chevron-down"></span></button>
                            <div uk-dropdown="mode: click">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</div>
                        </div>
                    </div>
                    <div class="w-100 mt-2">
                        <label for="" class="info border-end pe-2 me-2">Order in <b>13</b> Hours</label>
                        <label for="" class="info">Delivered on  <b>Monday , March 8</b> </label>
                    </div>
                    <div class="w-100 border-bottom pb-3">
                        <label for="" class="info border-end pe-2 me-2">Delivered Cost  <b>AED 0.00</b> </label>
                        <label for="" class="info">Delivered by  <img class="ms-2" src="../../../assets/assets/express.svg" alt=""> </label>
                    </div>
                    <div class="d-lg-flex d-none flex-wrap w-100">
                        
                        <label class="fz-15 fw-500 mt-4 w-100" for="">Seller</label>
                        <div class="w-100 mt-2">
                            <label for="" class="d-flex align-items-center"> <span class="fw-bold fz-18">AV Industries</span>  
                                <span class="rating me-2 px-2 text-white small bg-info ms-2 fz-13">4.5</span>
                                <a class="fz-13 ms-2" href="">Know more</a>
                            </label>
                            <label class="info mt-2" for="">Similiar product from other sellers <a href="" class="fz-12 ms-2">View all</a></label>
                        </div>
                        <div class="col-12 multi-slider mt-3 seller-slider border-bottom pb-3">
                            <div class="uk-position-relative uk-visible-toggle uk-dark h-100" tabindex="-1" uk-slider>
            
                                <ul class=" h-100 uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-2@s uk-child-width-1-4@m">
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
            
                                    <li class="pb-3 border-0 d-flex">
                                        <div class="product-card_1 card p-0 w-90 mx-auto position-relative">
                                            
                                            <img class="mx-auto py-3 px-3 seller-product-slider" src="../../../assets/assets/fridge.png" alt="">
                                            
                                        </div>
                                    </li>
                                    
                                </ul>
                            
                                <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
                                <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" uk-slidenav-next uk-slider-item="next"></a>
                            
                            </div>
                        </div>
    
                        <label class="fz-15 fw-500 mt-4 w-100" for="">Services</label>
                        <div class="w-100 d-flex flex-wrap mt-3 border-bottom pb-3">
                            <div class="service-block">
                                <img src="../../../assets/assets/warrentty.svg" alt="">
                                <span>1 Year warranty </span>
                            </div>
                            <div class="service-block">
                                <img src="../../../assets/assets/shipping.svg" alt="">
                                <span>Zero % shipping charge</span>
                            </div>
                            <div class="service-block">
                                <img src="../../../assets/assets/support.svg" alt="">
                                <span>24 X 7 company support </span>
                            </div>
                            <div class="service-block">
                                <img src="../../../assets/assets/return.svg" alt="">
                                <span>10 Days returnable</span>
                            </div>
                        </div>
                    </div>
                    <label class="fz-15 fw-500 mt-4 w-100" for="">Share</label>
                    <div class="w-100 d-flex flex-wrap mt-3">
                        <a class="social-media-block bg-facebook">
                            <span uk-icon="facebook"></span>
                        </a>
                        <a class="social-media-block bg-twitter">
                            <span uk-icon="twitter"></span>
                        </a>
                        <a class="social-media-block bg-youtube">
                            <span uk-icon="youtube"></span>
                        </a>
                        <a class="social-media-block bg-linkedin">
                            <span uk-icon="linkedin"></span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <app-description
        [catelog]="productDetails?.variant_meta?.catelog"
        [discription]="productDetails?.description"
        [additional_discription]="productDetails?.additional_description"
        [arabic_description]="productDetails?.arabic_description"
        [additionalDetail]="productDetails?.variant_meta?.Additional_info"
        [product_details]="productDetails?.variant_meta?.product_details"
        [features]="productDetails?.variant_meta?.product_features"
        [aditionInfo]="additionalInfo"
        [featuresSatus]="featuresSatus"
        [productDetails]="technicalsSatus"
    ></app-description>
    <app-review-rating></app-review-rating>

    <div *ngIf="is_related==true">
        <app-product-slider-two 
        [sectionTitle]="relatedName"
        [products]="relatedProducts.results"
        [reload]="true"
        ></app-product-slider-two>
    </div>

    <!-- <div class="container-fluid p-3" *ngIf="is_related==true">
        <app-product-slider-two 
        [sectionTitle]="relatedName"
        [products]="relatedProducts.results"
        [reload]="true"
        ></app-product-slider-two>
    </div> -->
    
    <div class="" *ngIf="is_linkedItem==true">
        <app-product-slider-two 
        [sectionTitle]="linkedName"
        [products]="linkedItem.results"
        [reload]="true"
        ></app-product-slider-two>
    </div>

    <!-- <div class="container-fluid p-3" *ngIf="is_linkedItem==true">
        <app-product-slider-two 
        [sectionTitle]="linkedName"
        [products]="linkedItem.results"
        [reload]="true"
        ></app-product-slider-two>
    </div> -->

    <app-product-slider-two 
        [sectionTitle]="recomanded.Display_name"
        [products]="recomanded.results"
        [reload]="true"
    ></app-product-slider-two>

</div>

<div class="container-fluid p-5 d-flex flex-column no-product" *ngIf="!isProduct">
    <span>
        Sorry!
    </span>
    <label class="fz-15" for="">
        We're having some trouble finding this product.
    </label>
    <button class="mt-3" [routerLink]="['/']" routerLinkActive="router-link-active" >GO TO HOMEPAGE</button>
</div>

<div class="container-fluid p-3" *ngIf="!isProduct">
    <app-product-slider-two 
        [sectionTitle]="recomanded.Display_name"
        [products]="recomanded.results"
        [reload]="true"
    ></app-product-slider-two>
</div>



<div id="modal-productImage" uk-modal>
    <div class="uk-modal-dialog uk-modal-body zoomed-image-view">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <img [src]="enlargedImage.img" alt="">

       <div class="d-flex flex-wrap w-100">
            <div class="product-image-grid" [ngClass]="{active: e === enlargedImage.index}" *ngFor="let image of productDetails?.images;let e = index">
                <img [src]="image" alt="" (mouseover)="zoomImage(e)" >
            </div>
       </div>
    </div>
</div>




<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog address-modal modal-dialog-centered">
      <div class="modal-content">
       <div class="address-modal__body">
            <h4 class="fw-500 d-flex flex-wrap fz-18 w-100 col-12 mb-3 ms-2 justify-content-between align-items-center ms-md-0" *ngIf="!addNew">
                <div class="d-flex flex-wrap align-items-center justify-content-between border-bottom border-bottom p-3 px-5 w-100">
                    Address
                    <button class="btn btn-primary fz-13 text-uppercase" (click)="addNew = !addNew" >Add address</button> 
                </div>
            </h4>
            <h4 class="fw-500 d-flex flex-wrap fz-18 w-100 col-12 mb-3 ms-2 px-3 justify-content-between align-items-center ms-md-0" *ngIf="addNew">
                <div class="d-flex flex-wrap align-items-center justify-content-between border-bottom p-3  w-100">
                    Address
                    <button class="btn_show cb fz-13 p-0 border-bottom text-uppercase" (click)="showAvailableAddress()" >Show Available Address</button> 
                </div>
            </h4>
            <div class="col-12 d-flex flex-wrap p-3" *ngIf="!isAddress">
                <div class="col-12 row mx-0 mb-3">
                    <span>No address found please create</span>
                </div>
                <div class="container-fluid px-3">
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Full name</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="addressValue" required placeholder="" aria-label="City">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Building name </label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingname" placeholder="" aria-label="">
                            </div>
                        </div>  
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0"> Building No</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingno" placeholder="" aria-label="">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Land mark</label>
                                <input type="text" [(ngModel)]="landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Street</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="street" placeholder="" aria-label="">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">City</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="city" required placeholder="" aria-label="City">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Emarites</label>
                                <select class="form-select" [(ngModel)]="state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Country</label>
                                <input type="text" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"  disabled="true" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Contact</label>
                                <input type="number" class="form-control fz-14" [(ngModel)]="contact"  placeholder="" aria-label="Fax">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0 d-flex justify-content-between">
                            <button class="btn cw  fz-13 p-0 border-bottom  choose_btn text-uppercase" (click)="chooseCurrentLocationNew()">Choose Current Location</button> 
                            <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary px-4" (click)="addressSendotp()">Create</button>
                            </div>    
                        </div>
                </div>
            </div>
            <div class="col-12 d-flex flex-wrap p-3" *ngIf="isEditAddress && !addNew">
                <div class="container-fluid px-3 mb-2">
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Full name</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="edit_address.full_name" required placeholder="" aria-label="City">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Building name </label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.building_name" placeholder="" aria-label="">
                            </div>
                        </div>  
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0"> Building No</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.building_no" placeholder="" aria-label="">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Land mark</label>
                                <input type="text" [(ngModel)]="edit_address.landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Street</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.street_name" placeholder="" aria-label="">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">City</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.city" required placeholder="" aria-label="City">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Country</label>
                                <input type="text" [(ngModel)]="edit_address.country" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Emarites</label>
                                <select class="form-select" [(ngModel)]="edit_address.state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Contact</label>
                                <input type="number" class="form-control fz-14" [(ngModel)]="contactMobile"  placeholder="" aria-label="Fax">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class="col d-flex justify-content-start">
                                <button class="btn cw fz-13 px-3 border-bottom text-uppercase choose_btn" (click)="chooseCurrentLocationEdit()">Choose Current Location</button> 
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary px-3 me-3" (click)="showAvailableAddress()">Cancel</button>
                                <button type="button" class="btn btn-primary" (click)="UpdateAddress(edit_address.id)">Update Address</button>
                            </div>    
                        </div>
                </div>
            </div>
            <div class="col-12" *ngIf="isAddress">
                <div class="container-fluid p-4 mb-2" *ngIf="addNew">
                        <div class="col-12 row mt-3 mx-0">
                            <div class="col-4 col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Full name</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="addressValue" required placeholder="" aria-label="City">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Street</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="street" placeholder="" aria-label="">
                            </div>
                        </div>  
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">City</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="city" required placeholder="" aria-label="City">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Building name </label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingname" placeholder="" aria-label="">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Land mark</label>
                                <input type="text" [(ngModel)]="landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Building No</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingno" placeholder="" aria-label="">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Country</label>
                                <input type="text" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"  disabled="true" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Emarites</label>
                                <select class="form-select" [(ngModel)]="state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Contact</label>
                                <input type="number" class="form-control fz-14" [(ngModel)]="contact"  placeholder="" aria-label="Fax">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0 d-flex justify-content-between">
                            <div class="col d-flex justify-content-ster">
                                <button class="btn cw fz-13 border-bottom text-uppercase choose_btn cw" (click)="chooseCurrentLocationNew()">Choose Current Location</button> 
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary px-4" (click)="addressSendotp()">Create</button>
                            </div>    
                        </div>
                </div>
                <div class="w-100 p-4 pb-2 bg-white d-flex align-items-start" *ngIf="!addNew && !isEditAddress">
                    <label class="col mb-0 d-flex flex-wrap" for="">
                        <label for="" class="fw-500 w-100">
                            Shipping Address
                        </label>
                        <div class="me-2 mt-3 address-box-small position-relative" *ngFor="let address of userDetailAdress;let i=index">
                            <span class="edit-address" (click)="editAddress(address)">Edit</span>
                            <div class="form-check w-100">
                                <input class="form-check-input" type="radio" name="shipping-address" [id]="shippingAddressIdPlaceholder+i" [checked]="i == selectAddress(address.is_default,i)" (change)="selectShippingAddress(address)">
                                <label class="form-check-label scroll" [for]="shippingAddressIdPlaceholder+i">
                                    
                                    {{address?.full_name}}, 
                                    <br>
                                    {{address?.location}}, {{address?.building_name}}, {{address?.landmark}},
                                    <br>
                                    {{address?.street_name}}, {{address?.city}}, {{address?.country}},
                                    <br>
                                    {{address?.state}}, {{address?.contact}}
                                </label>
                            </div>   
                        </div> 
                    </label>
                </div>
                <div class="w-100 p-4 bg-white d-flex flex-wrap align-items-start mb-3" *ngIf="!changeBillingAddress && !addNew && !isEditAddress">
                    <label class="col-12 mb-0 d-flex flex-wrap" for="">
                        <label for="" class="fw-500 w-100">Billing Address</label>
                        <div class="me-2 mt-3 address-box-small">
                            <label class="">
                                {{billingAddress?.full_name}}, 
                                <br>
                                {{billingAddress?.location}}, {{billingAddress?.building_name}}, {{billingAddress?.landmark}},
                                <br>
                                {{billingAddress?.street_name}}, {{billingAddress?.city}}, {{billingAddress?.country}},
                                <br>
                                {{billingAddress?.state}}, {{billingAddress?.contact}}
                            </label>
                        </div>              
                    </label>
                    <a class="text-primary fz-13" (click)="changeBillingAddress = true"> Change billing address </a>
                </div>
                <div class="w-100 p-4 bg-white d-flex align-items-start mb-3" *ngIf="changeBillingAddress && !addNew && !isEditAddress">
                    <label class="col mb-0 d-flex flex-wrap" for="">
                        <label for="" class="fw-500 w-100">
                            Billing Address
                        </label>
                        <div class="me-2 mt-3 address-box-small" *ngFor="let address of userDetailAdress;let i=index">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="billing-address" [id]="billingAddressIdPlaceholder+i" [checked]="i == selectAddress(address.is_default,i)" (change)="selectBillingAddress(address)">
                                <label class="form-check-label" [for]="billingAddressIdPlaceholder+i">
                                    {{address?.full_name}}, 
                                    <br>
                                    {{address?.location}}, {{address?.building_name}}, {{address?.landmark}},
                                    <br>
                                    {{address?.street_name}}, {{address?.city}}, {{address?.country}},
                                    <br>
                                    {{address?.state}}, {{address?.contact}}
                                </label>
                            </div>   
                        </div> 
                    </label>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-end p-3 p-3 pb-4 px-5 border-top" *ngIf="isAddress && !addNew && !isEditAddress">
                <button class="btn btn-primary text-uppercase me-3"  data-bs-dismiss="modal">Cancel</button>
                <button class="btn btn-primary text-uppercase" (click)="checkout()"  data-bs-dismiss="modal">Proceed to Pay</button>
            </div>
       </div>
      </div>
    </div>
</div> -->

<div id="exampleModal" class="uk-flex-top" uk-modal="stack:true">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical px-2" style="width: 800px;">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="address-modal__body">
            <h4 class="fw-500 d-flex flex-wrap fz-18 w-100 col-12 mb-3 ms-2 justify-content-between align-items-center ms-md-0" *ngIf="!addNew">
                <div class="d-flex flex-wrap align-items-center justify-content-between border-bottom border-bottom p-3 px-5 w-100">
                    Address
                    <button class="btn btn-primary fz-13 text-uppercase" (click)="addNew = !addNew" >Add address</button> 
                </div>
            </h4>
            <h4 class="fw-500 d-flex flex-wrap fz-18 w-100 col-12 mb-3 ms-2 px-3 justify-content-between align-items-center ms-md-0" *ngIf="addNew">
                <div class="d-flex flex-wrap align-items-center justify-content-between border-bottom p-3  w-100">
                    Address
                    <button class="btn_show cb fz-13 p-0 border-bottom text-uppercase" (click)="showAvailableAddress()" >Show Available Address</button> 
                </div>
            </h4>
            <div class="col-12 d-flex flex-wrap p-3" *ngIf="!isAddress">
                <div class="col-12 row mx-0 mb-3">
                    <span>No address found please create</span>
                </div>
                <div class="container-fluid px-3">
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Full name</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="addressValue" required placeholder="" aria-label="City">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Building name </label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingname" placeholder="" aria-label="">
                            </div>
                        </div>  
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0"> Building No</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingno" placeholder="" aria-label="">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Land mark</label>
                                <input type="text" [(ngModel)]="landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Street</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="street" placeholder="" aria-label="">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">City</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="city" required placeholder="" aria-label="City">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Emarites</label>
                                <select class="form-select" [(ngModel)]="state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Country</label>
                                <input type="text" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"  disabled="true" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Contact</label>
                                <input type="number" class="form-control fz-14" [(ngModel)]="contact"  placeholder="" aria-label="Fax">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0 d-flex justify-content-between">
                            <button class="btn cw  fz-13 p-0 border-bottom  choose_btn text-uppercase" (click)="chooseCurrentLocationNew()">Choose Current Location</button> 
                            <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary px-4" (click)="addressSendotp()">Create</button>
                            </div>    
                        </div>
                </div>
            </div>
            <div class="col-12 d-flex flex-wrap p-3" *ngIf="isEditAddress && !addNew">
                <div class="container-fluid px-3 mb-2">
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Full name</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="edit_address.full_name" required placeholder="" aria-label="City">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Building name </label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.building_name" placeholder="" aria-label="">
                            </div>
                        </div>  
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0"> Building No</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.building_no" placeholder="" aria-label="">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Land mark</label>
                                <input type="text" [(ngModel)]="edit_address.landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Street</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.street_name" placeholder="" aria-label="">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">City</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="edit_address.city" required placeholder="" aria-label="City">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Country</label>
                                <input type="text" [(ngModel)]="edit_address.country" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Emarites</label>
                                <select class="form-select" [(ngModel)]="edit_address.state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Contact</label>
                                <input type="number" class="form-control fz-14" [(ngModel)]="contactMobile" (change)="changeNumberWithOtp()" placeholder="" aria-label="Fax">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class="col d-flex justify-content-start">
                                <button class="btn cw fz-13 px-3 border-bottom text-uppercase choose_btn" (click)="chooseCurrentLocationEdit()">Choose Current Location</button> 
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary px-3 me-3" (click)="showAvailableAddress()">Cancel</button>
                                <button type="button" *ngIf="changeNumber==false" class="btn btn-primary" (click)="updateAddress(edit_address.id)">Update Address</button>
                                <button type="button" *ngIf="changeNumber==true" class="btn btn-primary" (click)="updateSendotp()">Update Address</button>
                            </div>    
                        </div>
                </div>
            </div>
            <div class="col-12" *ngIf="isAddress">
                <div class="container-fluid p-4 mb-2" *ngIf="addNew">
                        <div class="col-12 row mt-3 mx-0">
                            <div class="col-4 col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Full name</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="addressValue" required placeholder="" aria-label="City">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Street</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="street" placeholder="" aria-label="">
                            </div>
                        </div>  
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">City</label>
                                <input type="text" class="form-control fz-14"   [(ngModel)]="city" required placeholder="" aria-label="City">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Building name </label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingname" placeholder="" aria-label="">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Land mark</label>
                                <input type="text" [(ngModel)]="landmark" class="form-control fz-14" placeholder="" aria-label="Last name">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Building No</label>
                                <input type="text" class="form-control fz-14" [(ngModel)]="buildingno" placeholder="" aria-label="">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Country</label>
                                <input type="text" [(ngModel)]="country" [ngModelOptions]="{standalone: true}"  disabled="true" class="form-control fz-14" placeholder="" aria-label="First name">
                            </div>
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Emarites</label>
                                <select class="form-select" [(ngModel)]="state" aria-label="Default select example">
                                    <option value="Abu Dhabi">Abu Dhabi</option>
                                    <option value="Dubai">Dubai</option>
                                    <option value="Sharjah">Sharjah</option>
                                    <option value="Ajman">Ajman</option>
                                    <option value="Umm Al Quwain">Umm Al Quwain</option>
                                    <option value="Ras Al Khaimah">Ras Al Khaimah</option>
                                    <option value="Fujairah">Fujairah</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0">
                            <div class=" col-6">
                                <label for="fname" class="form-label mb-1 fz-14 mb-0">Contact</label>
                                <input type="number" class="form-control fz-14" [(ngModel)]="contact"  placeholder="" aria-label="Fax">
                            </div>
                        </div>
                        <div class="col-12 row mt-3 mx-0 d-flex justify-content-between">
                            <div class="col d-flex justify-content-ster">
                                <button class="btn cw fz-13 border-bottom text-uppercase choose_btn cw" (click)="chooseCurrentLocationNew()">Choose Current Location</button> 
                            </div>
                            <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary px-4" (click)="addressSendotp()">Create</button>
                            </div>    
                        </div>
                </div>
                <div class="w-100 p-4 pb-2 bg-white d-flex align-items-start" *ngIf="!addNew && !isEditAddress">
                    <label class="col mb-0 d-flex flex-wrap" for="">
                        <label for="" class="fw-500 w-100">
                            Shipping Address
                        </label>
                        <div class="me-2 mt-3 address-box-small position-relative" *ngFor="let address of userDetailAdress;let i=index">
                            <span class="edit-address" (click)="editAddress(address)">Edit</span>
                            <div class="form-check w-100">
                                <input class="form-check-input" type="radio" name="shipping-address" [id]="shippingAddressIdPlaceholder+i" [checked]="i == selectAddress(address.is_default,i)" (change)="selectShippingAddress(address)">
                                <label class="form-check-label scroll" [for]="shippingAddressIdPlaceholder+i">
                                    
                                    {{address?.full_name}}, 
                                    <br>
                                    {{address?.location}}, {{address?.building_name}}, {{address?.landmark}},
                                    <br>
                                    {{address?.street_name}}, {{address?.city}}, {{address?.country}},
                                    <br>
                                    {{address?.state}}, {{address?.contact}}
                                </label>
                            </div>   
                        </div> 
                    </label>
                </div>
                <div class="w-100 p-4 bg-white d-flex flex-wrap align-items-start mb-3" *ngIf="!changeBillingAddress && !addNew && !isEditAddress">
                    <label class="col-12 mb-0 d-flex flex-wrap" for="">
                        <label for="" class="fw-500 w-100">Billing Address</label>
                        <div class="me-2 mt-3 address-box-small">
                            <label class="">
                                {{billingAddress?.full_name}}, 
                                <br>
                                {{billingAddress?.location}}, {{billingAddress?.building_name}}, {{billingAddress?.landmark}},
                                <br>
                                {{billingAddress?.street_name}}, {{billingAddress?.city}}, {{billingAddress?.country}},
                                <br>
                                {{billingAddress?.state}}, {{billingAddress?.contact}}
                            </label>
                        </div>              
                    </label>
                    <a class="text-primary fz-13" (click)="changeBillingAddress = true"> Change billing address </a>
                </div>
                <div class="w-100 p-4 bg-white d-flex align-items-start mb-3" *ngIf="changeBillingAddress && !addNew && !isEditAddress">
                    <label class="col mb-0 d-flex flex-wrap" for="">
                        <label for="" class="fw-500 w-100">
                            Billing Address
                        </label>
                        <div class="me-2 mt-3 address-box-small" *ngFor="let address of userDetailAdress;let i=index">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="billing-address" [id]="billingAddressIdPlaceholder+i" [checked]="i == selectAddress(address.is_default,i)" (change)="selectBillingAddress(address)">
                                <label class="form-check-label" [for]="billingAddressIdPlaceholder+i">
                                    {{address?.full_name}}, 
                                    <br>
                                    {{address?.location}}, {{address?.building_name}}, {{address?.landmark}},
                                    <br>
                                    {{address?.street_name}}, {{address?.city}}, {{address?.country}},
                                    <br>
                                    {{address?.state}}, {{address?.contact}}
                                </label>
                            </div>   
                        </div> 
                    </label>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-end p-3 p-3 pb-4 px-5 border-top" *ngIf="isAddress && !addNew && !isEditAddress">
                <button class="btn btn-primary text-uppercase me-3 uk-modal-close"  >Cancel</button>
                <button class="btn btn-primary text-uppercase" (click)="checkout()"  data-bs-dismiss="modal">Proceed to Pay</button>
            </div>
       </div>
    </div>
</div>



<p-dialog header="" [(visible)]="addressOtpValidate">
    <div class="row col-12">
        <div class="col-lg-12 col-12 mt-lg-0 mt-2 mt-lg-0 mt-2 form-outline w-100 position-relative">
            <input type="text" class="form-control" style="color: black;" placeholder="" [(ngModel)]="addressCreateOtp">
            <button class="resend-otp" title="Resend Otp">
              <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
              <i class="ms-2 fw-14 fw-bold" (click)="resendOtpNew()" *ngIf="subscribeTimer ==0">Resend</i>
            </button>
        </div>
        <div class="col-12 d-flex justify-content-center mt-3">
            <button type="button" class="btn btn-primary"  (click)="createAddress()">Send</button>
        </div>
    </div> 
</p-dialog>
<p-dialog header="" [(visible)]="updateAddressOtp">
    <div class="row col-12">
        <div class="col-lg-12 col-12 mt-lg-0 mt-2 mt-lg-0 mt-2 form-outline w-100 position-relative">
            <input type="text" class="form-control"name='email' placeholder="----" [(ngModel)]="addressUpdateOtp"/>
            <!-- <span class="fz-12 text-danger fw-bold" *ngIf="optWarnning==true">check the opt</span> -->
            <button class="resend-otp" title="Resend Otp" >
              <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
              <i class="ms-2 fw-14 fw-bold" (click)="resendUpdateOtpNew()" *ngIf="subscribeTimer ==0">Resend</i>
            </button>
        </div>
        <div class="col-12 d-flex justify-content-center mt-3">
            <button type="button" class="btn btn-primary" (click)="UpdateAddressWithotp(edit_address.id)" [disabled]="subscribeTimer==0">Send</button>
        </div>
    </div> 
</p-dialog>
<!-- <p-dialog header="" [(visible)]="updateAddressOtp">
    <div class="row col-12">
        <div class="col-lg-12 col-12 mt-lg-0 mt-2 mt-lg-0 mt-2 form-outline w-100 position-relative">
            <input type="text" class="form-control"name='email' placeholder="----" [(ngModel)]="addressUpdateOtp"/>
            <button class="resend-otp" title="Resend Otp" >
              <span *ngIf="subscribeTimer!=0">{{subscribeTimer}} sec</span>
              <i class="ms-2 fw-14 fw-bold" (click)="resendUpdateOtpNew()" *ngIf="subscribeTimer ==0">Resend</i>
            </button>
        </div>
        <div class="col-12 d-flex justify-content-center mt-3">
            <button type="button" class="btn btn-primary" (click)="updateAddressOtpVarify(editAddressId)">Send</button>
        </div>
    </div> 
</p-dialog> -->