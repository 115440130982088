<div class="policy-header">
    <div class="policy-header__title text-center">
        {{genaralPolicy?.title}}
        <div class="w-100 fz-13 text-center mt-3">
            Last updated: {{genaralPolicy?.last_updated | date}}
        </div>
    </div>
</div>

<div class="container-fluid">
    <div class="container py-5" [innerHtml]="genaralPolicy?.description">
    </div>
</div>
