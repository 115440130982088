<div class="card deals mx-2 position-relative">
  <div class="product-offer-bulk bulk-bg">
    x10
  </div>
  <img [src]="products.src" [alt]="products.productName" class="card-img-top mx-auto" style="width: 150px;">
  <div class="card-body">
    <span class="fw-500 product-cardtwo-name mb-2 text-dark">{{products.productName}}</span>
    <select class="form-select qty-drop bg-gray-200 border-0" aria-label="Default select example">
      <option selected>Qty 1</option>
      <option value="1">Qty 2</option>
      <option value="2">Qty 3</option>
      <option value="3">Qty 4</option>
    </select>
    <div class="mb-2 product-card-price text-center mt-2">
        <span class="card-text fw-bold fz-13 me-2 text-dak"><b>{{ products.currentPrice | currency : 'AED' }}</b>
        </span>
        <span class="fz-13">
            <s> {{products.previousPrice | currency: 'AED'}}</s><br >
        </span>
    </div>
    <div class="w-100">
      <button class="btn btn-primary mx-auto d-block">
        ADD TO CART
      </button>
    </div>
  </div>
</div>