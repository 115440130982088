
<!-- footer -->
<div class="container-fluid footer position-relative p-0 pt-5">
    <!-- <a (click)="scrollUp()" class="scoll-up">Back to Top</a> -->
    <div class="container p-0 mt-4 pb-4 d-flex flex-wrap text-white px-4 pb-5  ps-0">
        <div class="col-lg col-12 col-sm-6 mb-lg-0 mb-3 d-flex flex-wrap ps-sm-0 ps-5">
           <img src="../../../assets/test/hilal-05 1 (1).svg" alt="">
           <label class="fw-500 mb-2 mt-4 w-md-0 w-100" for="">Download App</label>
           <div class="d-flex">  
               <a class="" [href]="playstore" target="blank" [ngClass]="{'d-none': playstore==''}">
                   <img src="../../../assets/assets/google-play.png" alt="">
               </a>
               <a href="https://apps.apple.com/in/app/ahlan-cart/id1609025965" target="blank">
                   <img src="../../../assets/assets/app-store.png" alt="">
               </a> 
           </div>
        </div>
        <div class="col-lg col-12 col-sm-6 mb-lg-0 mb-3 ps-5">
            <label class="fw-500 mb-3" for="">Get to Know us</label>
            <div class="d-flex flex-column">
                <a class="footer-link mt-1" href="#" [routerLink]="['./about']">About Us</a>
                <a class="footer-link mt-1" href="#" [routerLink]="['/faq']" routerLinkActive="router-link-active" >FAQ</a>
                <a class="footer-link mt-1" href="mailto:care@sourawabi.com">Email Support</a> 
            </div>
        </div>
        <div class="col-lg col-12 col-sm-6 mb-lg-0 mb-3 mb-3 ps-5">
            <label class="fw-500 mb-3" for="">Grocery</label>
            <div class="d-flex flex-column" *ngFor="let item of groceryArray">
                <a class="footer-link mt-1" (click)="routerLink(item.name,item.code,'groceries',item.id)">{{item.name | titlecase}} </a>
            </div>
        </div>
        <div class="col-lg col-12 col-sm-6 mb-lg-0 mb-3 ps-5">
            <label class="fw-500 mb-3" for="">Bulk</label>
            <div class="d-flex flex-column" *ngFor="let item of smartArray">
                <a class="footer-link mt-1" (click)="routerLink(item.name,item.code,'bulk',item.id)">{{item.name|titlecase}}</a>
            </div>
        </div>
        <div class="col-lg col-12 col-sm-6 mb-lg-0 mb-3 ps-5">
            <label class="fw-500 mb-3" for="">Smart</label>
            <div class="d-flex flex-column" *ngFor="let item of bulkArray">
                <a class="footer-link mt-1" (click)="routerLink(item.name,item.code,'smart',item.id)">{{item.name|titlecase}}</a>
            </div>
        </div>
        <div class="col-lg col-12 col-sm-6 mb-lg-0 mb-3 ps-5">
            <label class="fw-500 mb-3" for="">Connect wtih us</label>
            <div class="d-flex flex-column">
                <a class="footer-link mt-1" [href]="social_media.facebook" target="blank" [ngClass]="{'d-none': social_media.facebook==''}">Facebook</a>
                <a class="footer-link mt-1" [href]="social_media.twiter" target="blank" [ngClass]="{'d-none': social_media.twiter==''}">Twitter</a>
                <a class="footer-link mt-1" [href]="social_media.linkedin" target="blank" [ngClass]="{'d-none': social_media.linkedin==''}">Linkedin</a>
                <a class="footer-link mt-1" [href]="social_media.instagram" target="blank" [ngClass]="{'d-none': social_media.instagram==''}">Instagram</a>
                <a class="footer-link mt-1" [href]="social_media.youtube" target="blank" [ngClass]="{'d-none': social_media.youtube==''}">Youtube</a>
                <a class="footer-link mt-1" [routerLink]="['./contact']" (click)="scrollUp()">Contact Us</a>

            </div>
            <label class="fw-500 mb-2 mt-4" for=""> Support</label>
            <div class="d-flex">
                <img class="ms-1" src="../../../assets/assets/payment1.png" alt="" width="40" height="22">
                <img class="ms-1" src="../../../assets/assets/card2 (1).png" alt="" width="40" height="22">
                <img class="ms-1" src="../../../assets/assets/card2 (2).png" alt="" width="40" height="22">
                <img class="ms-1" src="../../../assets/assets/card2 (3).png" alt="" width="40" height="22">
            </div>
        </div>
        <!-- <div class="col-lg col-12 col-sm-6 mb-lg-0 mb-3">
            <label class="fw-500 mb-3" for="">Help center</label>
            <div class="d-flex flex-column">
                <a class="footer-link mt-1" href="#">Email support</a>
            </div>
            <label class="fw-500 mb-2 mt-4" for="">Download App</label>
            <div class="d-flex">
                <a class="" [href]="playstore" target="blank" [ngClass]="{'d-none': playstore==''}">
                    <img src="../../../assets/assets/google-play.png" alt="">
                </a>
                <a [href]="appstore" target="blank">
                    <img src="../../../assets/assets/app-store.png" alt="">
                </a>
            </div>
        </div> -->
    </div>
    <div class="container-fluid d-flex flex-wrap flex-column align-items-center justify-content-center px-3 py-4 footer-credit">
        <label class="text-white fit-width mb-md-0 text-center small" for=""> ©2022 <a href="https://hilalcart.com/#/" class="text-white">{{footerCreditText}}</a> All Rights Reserved</label>
        <div class=" d-flex justify-content-center mt-2">
            <span class="fz-10 me-2 cursor" style="color:white;" [routerLink]="['/privacyPolicy']" routerLinkActive="router-link-active" (click)="scrollUp()">Privacy Policy</span>
            <span  class="fz-10 cursor" style="color:white;" [routerLink]="['/terms-use']" routerLinkActive="router-link-active" (click)="scrollUp()">Terms & Conditions</span>
        </div>
        <!-- <div class="d-flex flex-wrap justify-content-center payment-options ms-3">
            <img class="m-1" src="../../../assets/assets/payment1.png" alt="">
        </div> --> 
    </div>
</div>

<!-- end footer -->