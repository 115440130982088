

<div class="card deals mx-2">
    <div class=" flex-wrap d-flex justify-content-end m-3">
        <div class="d-flex">
            <i 
                class="bi pointer fz-18" 
                [ngClass]="wishListed === true ? 'bi-heart-fill' : 'bi-heart'"
                (click)="changeWishlist(products)"
            ></i>
        </div>    
    </div>
    
    <img [src]="products.src" [alt]="products.productName" class="card-img-top mx-auto" style="width: 150px;">
    <div class="card-body">
        <span class=" fz-16 fw-500 product-card-name mb-2 text-dark">{{products.productName}}</span>
        <span class="farmfresh fw-bold text-primary">Farm Fresh</span>
        <div class="my-3 product-card-price">
            <span class="card-text fw-bold fs-6 me-2 text-dak"><b>{{ products.currentPrice | currency : 'AED' }}</b>
            </span>
            <span>
                <s> {{products.previousPrice | currency: 'AED'}}</s><br >
                <span class="text-muted">10% Discount</span>
            </span>
        </div>
        <span class="time fs-6 text-danger">Ends in 16:20:25</span>
    </div>
    <div class="row p-3">
        <div class="col  d-flex justify-content-start">              
            <select class="form-select input" aria-label="Default select example">
                <option selected>1</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>        
        </div>  
        <div class="col  d-flex justify-content-end">
            <button type="button" class="btn fw-bold btn-primary" (click)="addCart(products)">ADD</button>
        </div>
    </div>
</div>