<!-- product slider Section -->
<div class="container-fluid d-flex flex-wrap mt-3 p-3 w-100">
  <h4 class="fw-bold min-fit-width col-12 mb-4 ms-2 ms-md-0 ps-4 fz-22">{{sectionTitle}}</h4>
  <div class="col-12 multi-slider">
    <div class="uk-position-relative uk-visible-toggle uk-dark px-10px" tabindex="-1" uk-slider>
      <ul class="uk-slider-items uk-child-width-1-1 uk-child-width-1-2@sm uk-child-width-1-3@s uk-child-width-1-4@m uk-child-width-1-4@l uk-child-width-1-5@xl">
        <li class="pb-3 border-0 d-flex" *ngFor="let product of products">
          <app-product-card-four
            [products]="product"                  
            (cartEvent)="addCart(product)"
            (wishEvent)="addWishList(product)"
            [wishListed]="isWishlisted(product.id)"
          ></app-product-card-four>
        </li>
      </ul>
      <a class="uk-position-center-left uk-position-small" href="#" uk-slidenav-previous uk-slider-item="previous"></a>
      <a class="uk-position-center-right uk-position-small" href="#" uk-slidenav-next uk-slider-item="next"></a>
    </div>
  </div>
</div>
<!-- End product slider Section -->