import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Product, ProductRealChild } from 'src/app/models/product';
import { MessengerService } from 'src/app/services/messenger.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-card-three',
  templateUrl: './product-card-three.component.html',
  styleUrls: ['./product-card-three.component.scss']
})
export class ProductCardThreeComponent {
  @Input() wishlist = false
  @Input() control = true
  @Input() products!: ProductRealChild;
  @Input() wishListed = false
  @Output() cartEvent = new EventEmitter()
  @Output() wishEvent = new EventEmitter()
  @Output() isWishListEvent = new EventEmitter()

  constructor(
    private messageService: MessengerService,
    private router: Router,
  ) { }
  changeWishlist(product: any) {
    this.wishListed = !this.wishListed
    this.wishEvent.emit(product)
  }
  addCart(prod: any) {
    this.cartEvent.emit(prod)
  }
  buyNow() {
  }
  navigateProductPage(id:any){
    this.router.navigate(['/product/' + id])
  }
}
