<div class="card p-3 border-0">
    <h4 class="fw-bold min-fit-width col-12 mb-3 fz-18 text-capitalize text-truncate-1">{{parent.display_name}}</h4>
    <div class="d-flex flex-wrap row gx-2">
        <div class="col-12" >
            <a [href]="routeTo(parent.redirect_type,parent.redirecturl,parent.redirect_id,parent.section_type,parent.redirect_code)" class="m-0 p-0">
                <img class="w-100 mb-2 deals__image" [src]="parent.image" alt="" *ngIf="parent.image != ''">
            </a>
        </div>
        <div class="col-6" *ngFor="let deal of parent.child" >
            <a *ngIf="deal" [href]="routeTo(deal.redirect_type,deal.redirecturl,deal.redirect_id,deal.section_type,deal.redirect_code)" class="m-0 p-0">
                <div>
                    <img class="w-100 pointer" [src]="deal.image" alt="">
                    <label class="fz-11 text-truncate-2 mb-2 text-capitalize mt-1 pointer" for="">{{ (deal.display_name.length>25)? (deal.display_name | slice:0:6)+'':(deal.display_name) }}</label>
                </div>
            </a>
        </div>
        <!-- <div class="col-12 d-flex justify-content-center">
            <a class="fz-13" [routerLink]="path" routerLinkActive="router-link-active" ><u>View all</u></a>
        </div> -->
    </div>
</div>