import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerCreditLink = environment.footer_credit_link
  footerCreditText = environment.footer_credit_text
  social_media = environment.social_media
  playstore = environment.apps.playstore
  appstore = environment.apps.appstore
  isLogin=false
  constructor(private _productService:ProductService,private router: Router) { }
  groceryArray:any=[]
  bulkArray:any=[]
  smartArray:any=[]
  ngOnInit(): void {
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    if(userData !=null){
      this.isLogin=true
    }
    let specialGroup="groceries"
    let smart ="smart"
    let bulk ="bulk"
    this._productService.getHomeMainSlotOne(specialGroup).subscribe((data:any)=>{
      this.groceryArray = data?.data?.products
      console.log(this.groceryArray,"this.groceryArray");
    })
    this._productService.getHomeMainSlotOne(smart).subscribe((data:any)=>{
      this.smartArray = data?.data?.products
      console.log(this.groceryArray,"this.groceryArray");
    })
    this._productService.getHomeMainSlotOne(bulk).subscribe((data:any)=>{
      this.bulkArray = data?.data?.products
      console.log(this.groceryArray,"this.groceryArray");
    })
  }
  scrollUp(){
    window.scrollTo(0, 0);
  }
  routerLink(name:any,code:any,group:any,id:any){
    this.router.navigate(['/division/' + name+'_$_'+ code+'_$_'+group+'_$_'+id+'_$_'+'category'])

  }
}
