import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';


@Injectable({
  providedIn: 'root'
})
export class RatingreviewService {
  api_cart_checkout = environment.api_rating
  api_check_reviewable = environment.api_cart_checkout
  createRating="/rating_review/create-rating"
  checkIsReviewable = "/order/list-order-by-customer/"
  listComments = "/rating_review/list-comments-by-variantid/"
  createReview = "/rating_review/craete-review"
  ratingByVarient = "/rating_review/list-ratings-by-variantid/"
  listReview="https://api-rating-review-uat.ahlancart.com/rating_review/read-review"
  // createRating="/rating_review/create-rating "
  constructor(private http: HttpClient,
              private toastr:ToastrService) { 
  }

  
  getComments(id:any){
    return this.http.get<any>(this.api_cart_checkout+this.listComments+id)
  }
  
  getRatingOnVarient(id:any){
    return this.http.get<any>(this.api_cart_checkout+this.ratingByVarient+id)
  }

  isReviewable(id:any){
    let user:any = localStorage.getItem("marketplaceUser")
    let userData = JSON.parse(user)
    if(userData == null ) return
    let code = userData.customer_usercode
    return this.http.get<any>(this.api_check_reviewable+this.checkIsReviewable+code+'?item_no='+id+'&status_delivery=true')
  }

  postReview(review:any){
    this.http.post(this.api_cart_checkout+this.createReview,review).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        this.toastr.success(data.message)
      }else{
        this.toastr.warning(data.message)
      }
    })
  }

  createRatingPost(rating:any){
    this.http.post(this.api_cart_checkout+this.createRating,rating).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        this.toastr.success(data.message)
      }else{
        this.toastr.warning(data.message)
      }
    })
  }
  

  listReviewAhlan(data:any){
    this.http.post(this.listReview,data).toPromise().then((data:any)=>{
      if(data.status === 'success'){
        console.log(data.message,"success"); 
        console.log(data.data);
      }else{
        console.log(data.message,"failed");
      }
    })
  }


}
