import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CartComponent } from './pages/cart/cart.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ProductComponent } from './pages/product/product.component';
import { CategoryComponent } from './pages/category/category.component';
import { DivisionComponent } from './pages/division/division.component';
import { GetdealsComponent } from './pages/getdeals/getdeals.component';
import { SearchComponent } from './pages/search/search.component';
import { PrivacypolicyComponent } from './pages/privacypolicy/privacypolicy.component';
import { TermsofsalesComponent } from './pages/termsofsales/termsofsales.component';
import { TermsofuseComponent } from './pages/termsofuse/termsofuse.component';
import { DatapolicyComponent } from './pages/datapolicy/datapolicy.component';
import { SecuritypolicyComponent } from './pages/securitypolicy/securitypolicy.component';
import { ReturnrefundComponent } from './pages/returnrefund/returnrefund.component';
import { CookiepolicyComponent } from './pages/cookiepolicy/cookiepolicy.component';
import { WarantypolicyComponent } from './pages/warantypolicy/warantypolicy.component';
import { GenaralpolicyComponent } from './pages/genaralpolicy/genaralpolicy.component';
import { SuccessorderComponent } from './pages/successorder/successorder.component';
import { CashondeliveryComponent } from './pages/cashondelivery/cashondelivery.component';
import { TestComponent } from './pages/test/test.component';
import { FaqComponent } from './pages/faq/faq.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactusComponent } from './pages/contactus/contactus.component';

const routes: Routes = [
  { path: 'productGroup/:name', component: CategoryComponent },
  { path: 'division/:name', component: DivisionComponent },
  { path: 'deals/:name', component: GetdealsComponent },
  { path: 'search/:name', component: SearchComponent },
  { path: 'profile', loadChildren: () => import("./pages/profile/profile.module").then(m => m.ProfileModule) },
  { path: 'auth', loadChildren: () => import("./pages/auth/auth.module").then(m => m.AuthModule) },
  { path: 'privacyPolicy', loadChildren: () => import("./policy/policy.module").then(m => m.PolicyModule) },
  { path: '', component: HomeComponent},
  { path: 'cart', component: CartComponent},
  { path: 'checkout', component: CheckoutComponent},
  { path: 'product/:id', component: ProductComponent,data: {title: 'About'} },
  { path: 'privacy-policy', component: PrivacypolicyComponent},
  { path: 'terms-sales',component:TermsofsalesComponent},
  { path: 'terms-use',component:TermsofuseComponent},
  { path: 'data-policy',component:DatapolicyComponent}, 
  { path: 'security-policy',component:SecuritypolicyComponent},
  { path: 'refund-return',component:ReturnrefundComponent},
  { path: 'cookie-policy',component:CookiepolicyComponent},
  { path: 'warranty-policy',component:WarantypolicyComponent},
  { path: 'genaral-policy',component:GenaralpolicyComponent},
  { path: 'order-success',component:SuccessorderComponent},
  { path:'succes_cashon',component:CashondeliveryComponent},
  { path:'test',component:TestComponent},
  { path:'faq',component:FaqComponent},
  { path:'about',component:AboutComponent},
  { path:'contact',component:ContactusComponent}
]; 

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
